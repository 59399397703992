/*  ################################################################

  File Name: style.css
  Template Name: Refresh
  Created By: justthemevalley 
 
 [Table of contents]

	1. Body
	2. Newsletter popup
	3. Header
	4. Navigation
	5. Features Box
	6. hot deal
	7. jtv top banner
	8. Featured Products
	9. bottom banner section 
	10. Best Sellers Slider
	11. testimonials slider
	12. special product slider
	13. Latest Blog
	14. Products Grid
	15. Products List
	16. Rating and Price
	17. Breadcrumbs
	18. Sidebar
		18a. Product price range
		18b. Block: Account
		18d. Block: Layered Navigation
		18e. Block: Cart
		18f. Block: Related
		18g. Block: Compare Products
		18h. Block: Recently Viewed
		18i. Block: Recently Compared
		18g. Block: Poll
		18k. Block: Tags
		18l. Block: Subscribe
		18m Block: Recently Viewed
		18n. Block: Reorder 
		18m. Block: Banner
	
	19. custom-slider
	21. Product View
	22. Product Collatera
	23. related slider 
	24. upsell slider 
	25. Global Messages
	26. Shopping Cart Table
	27. Wishlist Table
	28. Dashboard
	29. checkout pages
	30. Compare Table
	31. Multiple Addresses Checkout
	32. Account Login 
	33. 404 Page
	34. Delivery page
	35. Data Table
	36. Footer


################################################################# */ 

/*Google Fonts*/
@import 'https://fonts.googleapis.com/css?family=Open+Sans:700,600,800,400';
@import 'https://fonts.googleapis.com/css?family=Raleway:400,300,600,500,700,800';
@import 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700';
/*----------- 1. Body --------------*/

body {
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	color: #333;
	background: #f3f2f2;
	/*background: #f8d6d6;*/
}
a {
	color: #a0a0a0;
}
a:hover {
	color: #8ac42f;
	text-decoration: none;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}
small {
	font-size: 85%;
	font-weight: normal;
	text-transform: lowercase;
	color: #999999;
}
/******************************************
2. Newsletter Popup
******************************************/
.modal-dialog.newsletter-popup {
	background: rgba(0, 0, 0, 0) url("../images/newsletter.jpg") repeat scroll 0 0;
	width: 775px;
	height: 375px;
	margin-top: 12%;
}
.newsletter-popup .modal-content {
	background: inherit;
	box-shadow: none;
	border: none;
}
.newsletter-popup .close {
	background: #fe0100 none repeat scroll 0 0;
	cursor: pointer;
	font-weight: 300;
	line-height: 30px;
	min-width: 40px;
	position: absolute;
	right: 0;
	text-align: center;
	text-transform: uppercase;
	top: -30px;
	font-size: 24px;
	letter-spacing: 1px;
	color: #fff;
	opacity: 1;
	border-radius: 8px 8px 0px 0px;
}
.newsletter-popup .close:hover, .close:focus {
	background: #fe0100;
	color: #fff;
	outline: none;
}
.newsletter-popup h4.modal-title {
	font-size: 26px;
	line-height: 1;
	margin-bottom: 0;
	margin-top: 60px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: #fff;
}
.newsletter-popup .modal-body {
	padding-left: 35px;
}
#newsletter-form .content-subscribe {
	overflow: hidden
}
.form-subscribe-header label {
	color: #eee;
	font-size: 14px;
	font-weight: normal;
	line-height: normal;
	margin: 20px 0;
	max-width: 72%;
}
#newsletter-form .input-box, #newsletter-form .content-subscribe .actions {
	display: inline-block;
}
#newsletter-form .input-box .input-text {
	border: 1px solid #ddd;
	height: 40px;
	line-height: 40px;
	margin: 0 0 5px;
	padding-left: 15px;
	width: 380px;
	color: #333333;
	border-radius: 3px;
	margin-right: 10px;
}
.subscribe-bottom input[type=checkbox] {
	vertical-align: sub;
}
#newsletter-form .actions .button-subscribe {
	background-color: #fdae03;
	border: medium none;
	color: #fff;
	font-size: 1em;
	height: 40px;
	line-height: 40px;
	min-width: 105px;
	padding: 0 15px;
	text-transform: uppercase;
	border-radius: 3px;
}
#newsletter-form .actions .button-subscribe:hover {
	background: #000;
	color: #fff;
}
.subscribe-bottom {
	color: #eee;
	display: block;
	margin-top: 15px;
	overflow: hidden;
}
.subscribe-bottom label {
	color: #333333;
	font-size: 12px;
	margin-bottom: 0;
}
#dont_show {
	margin: 0;
	vertical-align: middle;
}
.modal-open .modal {
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0.6);
	display: none !important;
}
/* Buttons */
button {
	border: none
}
.button {
	background: #fff;
	border-color: #ddd;
	color: #333
}
.button:hover, button.button.button-clear {
	background: #fdae03;
	border-color: #fdae03;
	color: #fff
}
button.button {
	background: none repeat scroll 0 0 #fff;
	color: #666;
	display: inline-block;
	font-size: 10px;
	letter-spacing: 0.5px;
	line-height: normal;
	padding: 6px 12px;
	text-transform: uppercase;
	border: 1px #d1d1d1 solid;
	font-weight: 600;
}
button.button:hover {
	padding: 6px 12px;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
}
a.button {
	display: inline-block;
	padding: 6px 15px;
	font-size: 11px;
	border-width: 2px;
	text-align: center;
	color: #333;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	border-style: solid
}
a.button:hover {
	padding: 6px 15px;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	border-width: 2px;
}
.price, .breadcrumbs li strong, .product-details span.price, #recently-viewed-items .price-box .special-price .price, #recently-viewed-items .price-box .regular-price .price, .availability.out-of-stock span {
	color: #8ac42f;
}
.button.coupon, .button.btn-continue, .button.continue, .button.login, button.btn-share, .button.create-account {
	background-color: #8ac42f;
	color: #fff;
	border: 1px #8ac42f solid;
}
.multiple_addresses .button.btn-update:hover {
	background-color: #8ac42f;
	color: #fff;
	border: 1px #8ac42f solid;
}
.btn-remove {
	font-size: 0;
	line-height: 0;
	text-indent: -999em;
}
/* 2-Add to cart button */
.product-view .product-shop .add-to-box .btn-cart {
	background: #8ac42f;
	color: #fff;
}
.product-view .product-shop .add-to-box .btn-cart:hover {
	background: #202726;
	color: #fff;
}
/* 3-Add to cart QTY */
.custom button.items-count {
	background-color: #fff;
	border: 1px #ececec solid;
	color: #999
}
.custom button.items-count:hover {
	background-color: #8ac42f;
	border: 1px #8ac42f solid;
	color: #fff;
}
/*----------- 2. Layout --------------*/
.main-col {
	margin: auto;
	overflow: hidden;
}
.col-main {
	display: inline-block;
	font-size: 14px;
	padding: 0px;
}
.col2-right-layout .col-main {
	background: none repeat scroll 0 0 #fff;
	border: 1px solid #e5e5e5;
	display: inline-block;
	font-size: 14px;
	padding: 25px;
	width: 100%;
}
/* 1 Column Layout */
.col1-layout .header-background-default {
	margin: 20px;
	padding: 0;
}
.col1-layout .col-main {
	float: none;
	width: auto;
	padding: 0px;
	border: none;
	background: inherit;
	display: inherit;
}
/* 2 Columns Layout */
.col2-right-layout ol.opc .col-md-4 {
	margin-right: 15px;
}
/* 3 Columns Layout */
.col3-layout .col-main {
}
.col3-layout .col-wrapper {
	float: left;
	width: 780px;
}
.col3-layout .col-wrapper .col-main {
	float: right;
}
.main-container {
	min-height: 400px;
	padding: 0px;
	margin-top: 25px;
	
}
/*----------- 3. Header --------------*/

.jtv-logo-box {
	text-align: center
}
.logo {
	vertical-align: middle;
	text-align: center;
	display: inline-block;
	margin-top: 10px;
	
}
.logo h1 {
	margin: auto;
}
.header-top {
	padding: 0px 0px 8px 0px;
	background-color: #000000;
	height: 50px;
}
header .header-top-links {
	padding-top: 10px;
	padding-bottom: 10px;
	height: 65px
}
/* Toplink */
.jtv-top-links {
	line-height: normal;
	padding: 8px 0 0;
}
.jtv-top-links .links {
	text-align: right;
}
.jtv-top-links .links ul {
	padding: 0px;
	margin: auto;
}
.jtv-top-links .links ul li {
	display: inline-block;
	list-style: none;
}
.jtv-top-links .links li a {
	color: black;
	display: inline-block;
	padding: 0px;
	color: #fff;
	font-size: 12px;
	padding-left: 15px;
	font-weight: normal;
	text-decoration: none;
}
.jtv-top-links .links li a:hover {
	color: #fee301 !important;
	text-decoration: none;
}
.block-company {
	background: none;
	box-shadow: none;
	border: none;
	color: #aaaaaa;
	font-size: 14px;
}
.block-company .caret {
	margin-left: 5px;
}
.block-company-wrapper {
	display: inline-block;
}
.block-company label {
	font-weight: normal;
	padding: 5px 5px 2px 0px;
}
.block-company-wrapper .dropdown-menu {
	min-width: 0;
	box-shadow: 0 0 15px -7px #666;
	top: 22px;
}
.block-company-wrapper .dropdown-menu li {
	padding: 5px 15px 5px 0;
}
.block-company-wrapper .dropdown-menu li:hover {
	background-color: #fff;
	color: #8ac42f;
	transition: background-color 0.2s ease-in-out;
}
.block-company-wrapper .dropdown-menu a {
	padding: 3px 10px 3px 10px;
	color: #333!important;
	font-weight: normal!important;
	text-transform: none!important;
}
.block-company-wrapper .dropdown-menu a:hover {
	color: #8ac42f;
	background: none;
}
/* top search*/
.search-box {
	margin: auto;
	right: 0px;
	background: #fff;
	border-radius: 0px;
	margin: 23px 0px 0px;
	border: 1px #409f3b solid;
	border-radius: 3px 0px 0px 3px;
}
#search {
	margin: 0;
	width: 250px;
	font-size: 13px;
	height: 40px;
	color: #333;
	padding: 0 12px;
	background: none;
	outline: none;
	border: none;
}
.search-btn-bg {
	cursor: pointer;
	display: inline-block;
	background: #fff;
	position: absolute;
	outline: none;
	box-shadow: none;
	border: none;
	height: 40px;
	right: 25px;
	font-size: 18px;
	border-left: 0px #eaeaea solid;
	padding-left: 14px;
	width: 45px;
	border-radius: 0px 3px 3px 0px;
}
.search-btn-bg .glyphicon-search:before {
	font-size: 14px;
	color: #fdae03
}
.search-btn-bg:hover {
	color: #333;
}
.glyphicon-search:before {
	font-size: 12px;
}
.cart-top .dropdown-menu {
	padding: 10px;
	min-height: 100px;
	height: auto !important;
	min-width: 445px;
	-webkit-transition: all 300ms ease-in-out 0s;
	-o-transition: all 300ms ease-in-out 0s;
	transition: all 300ms ease-in-out 0s;
	top: 0;
	position: fixed;
	z-index: 999;
	display: block;
	-webkit-transform: translate3d(100%, 0, 0);
	-moz-transform: translate3d(100%, 0, 0);
	-ms-transform: translate3d(100%, 0, 0);
	-o-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0);
	-webkit-transition: all .4s cubic-bezier(.25, .46, .45, .94) 0s;
	-o-transition: all .4s cubic-bezier(.25, .46, .45, .94) 0s;
	transition: all .4s cubic-bezier(.25, .46, .45, .94) 0s
}
#search .input-group {
	border: 1px #ddd solid;
	display: inline-block;
	width: 100%;
	background: #fff
}
#search input {
	height: 45px;
	display: inline-block;
	width: 90%;
	border: none;
	background: #fff;
	box-shadow: none;
}
#search button {
	line-height: 24px;
	padding: 12px 18px 10px;
	float: right;
	display: inline-block;
	background: #8ac42f;
	border: none;
	color: #fff;
}
#search button:hover {
	background: #8ac42f;
	border: none;
	color: #fff
}
.top-search .modal-dialog {
	margin-top: 80px;
}
.top-search .fade.in {
	background: none repeat scroll 0 0 rgba(0,0,0,0.5);
	opacity: 1
}
.top-search .modal-content {
	border-radius: 3px
}
.top-search .modal-header {
	border: none;
}
.top-search .modal-body {
	padding: 10px 50px 50px;
}
#search button i {
	font-size: 12px
}
.top-search .modal-lg {
	width: 680px
}
.modal-header {
	text-align: left
}
.modal-open {
	padding: 0px !important
}
.top-search .modal-header .close {
	opacity: 1;
	background: red;
	border-radius: 100%;
	margin-top: -22px;
	margin-right: -24px;
}
.top-search .modal-header .close span {
	color: #fff;
	font-size: 22px;
	padding: 0 4px;
	text-shadow: none;
	line-height: 20px;
}
.welcome-msg {
	text-align: left;
	display: inline-block;
	padding: 0px;
	margin: 0px 14px 0px 0px;
	font-size: 13px;
	color: #fff;
	vertical-align: top;
	margin-top: 8px
}
/* language & currency */
.header-container .curr-language {
	margin-top: 10px;
}
.jtv-language-box {
	display: inline-block !important;
	margin: 8px 0 0 0;
}
.block-language {
	background: none;
	box-shadow: none;
	border: none;
	color: #fff;
}
a.block-language:hover {
	color: #fee301;
	text-decoration: none;
}
a.block-language:focus {
	color: #fee301;
	text-decoration: none;
}
.block-language img {
	font-weight: normal;
	padding: 0px 5px 0px 0px;
	margin: auto;
}
.jtv-language-box .dropdown-menu>li {
	border-bottom: 1px #ddd solid;
	padding: 4px 0px 4px 0px;
}
.jtv-language-box .dropdown-menu>li:last-child {
	border-bottom: none;
}
.jtv-language-box .dropdown-menu > li > a img {
	margin: 0px 6px 0px 0px;
}
.jtv-language-box .dropdown-menu {
	border-radius: 0px;
	min-width: 0px;
	top: 28px;
	padding: 0px;
	border: 1px solid #efefef;
	border-top: none;
}
.jtv-language-box .dropdown-menu a {
	padding: 3px 10px 3px 10px;
	font-size: 12px;
}
.jtv-language-box .dropdown-menu a:hover {
	color: #8ac42f;
	background: none;
}
.jtv-currency-box {
	display: inline-block !important;
	margin: 6px 0 0 18px;
}
.block-currency {
	background: none;
	box-shadow: none;
	border: none;
	color: #fff;
}
a.block-currency:hover {
	color: #fdae03;
	text-decoration: none;
}
a.block-currency:focus {
	color: #fdae03;
	text-decoration: none;
}
.block-currency label {
	font-weight: normal;
	padding: 0 5px;
	margin: auto;
}
.jtv-currency-box .dropdown-menu>li {
	border-bottom: 1px #ddd solid;
	padding: 4px 0;
}
.jtv-currency-box .dropdown-menu>li:last-child {
	border-bottom: none;
}
.jtv-currency-box .dropdown-menu > li > a img {
	margin: 0 6px 0 0;
}
.jtv-currency-box .dropdown-menu {
	border-radius: 0;
	min-width: 0;
	z-index: 100000;
	top: 28px;
	padding: 0px;
	border: 1px solid #efefef;
	border-top: none;
}
.jtv-currency-box .dropdown-menu a {
	padding: 3px 15px 3px 10px;
	font-size: 12px;
}
.jtv-currency-box .dropdown-menu a:hover {
	color: #8ac42f;
	background: none;
}
.lang-curr {
	float: right;
	margin: 11px 0px;
}
.form-language {
	display: inline;
	float: right;
	border-left: 1px solid #ececec;
	line-height: 17px;
	padding-left: 20px;
}
ul.lang {
	text-align: left;
	display: block;
	margin: 0;
	padding: 0x;
	list-style: none;
	float: left;
	padding: 0px;
}
ul.lang li {
	float: left;
	display: inline;
	margin: 0px 8px 0 0px
}
ul.lang li:last-child {
	margin-right: 0px
}
ul.lang li span {
	text-indent: -10000px;
	display: none
}
.form-currency {
	display: inline;
	margin-right: 12px;
	float: right;
	line-height: 17px
}
ul.currencies_list {
	float: left;
	display: inline;
	margin: 0px 8px 0 0px;
	padding: 0px;
}
ul.currencies_list li {
	float: left;
	display: inline;
	margin: 0 0px 0 10px
}
ul.currencies_list li a {
	color: #333;
	font-size: 14px;
}
ul.currencies_list li a:hover {
	color: #8ac42f;
	text-decoration: none;
}
/* End language & currency*/

/* Top Shopping Cart */
.top-cart-content {
	display: none;
}
.mini-products-list .product-image {
	float: left;
	padding: 0;
	width: 65px;
}
.mini-products-list .product-image img {
	width: 65px;
}
.jtv-top-cart-content .product-name {
	margin: 0;
	line-height: 1;
	margin-top: 0px
}
.jtv-top-cart-content .product-name a {
	font-size: 13px;
	line-height: normal;
	font-weight: normal;
	color: #333;
}
.jtv-top-cart-content .product-name a:hover {
	font-size: 13px;
	color: #8ac42f
}
.jtv-top-cart-box .price {
	color: #333;
	text-transform: uppercase;
	padding-left: 0px;
	font-size: 12px;
}
.jtv-top-cart-box .product-details .price {
	color: #333;
	width: auto;
	float: none;
	padding-left: 0px
}
.mini-cart {
	text-align: center;
	cursor: pointer;
	margin: 0px;
}
.mini-cart .basket a {
	color: #333;
	margin: 0px;
	line-height: 17px;
	font-size: 12px;
	padding: 0px;
	display: inline-block;
	background: #fff;
	border: 1px solid #409f3b;
	padding-right: 12px;
	border-radius: 3px;
	padding-left: 12px;
	float: left;
}
.mini-cart .basket a:before {
	content: '\f290';
	font-family: FontAwesome;
	font-size: 16px;
	color: #fdae03;
	background: #fff;
	height: 40px;
	width: 20px;
	display: inline-block;
	line-height: 40px;
	text-align: center;
	font-weight: normal;
	border-radius: 3px 0px 0px 3px;
}
.mini-cart .basket a span.cart_count {
	display: inline-block;
	color: #333;
	width: 22px;
	font-size: 14px;
	height: 20px;
	line-height: 20px;
	text-align: center;
	margin-right: 0px;
	padding-left: 0px;
}
.mini-cart .basket a div.price {
	display: inline-block;
}
.jtv-top-cart-content .pull-right {
	margin-right: 10px;
	padding-top: 3px
}
.jtv-top-cart-content .basket a {
	border: none;
	color: #fff
}
.mini-cart .basket a:hover {
	text-decoration: none;
}
.mini-cart .open .basket a {
	color: #000;
	background: #fff;
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.3)
}
.mini-cart .dropdown-toggle {
	font-size: 14px;
	padding: 0px;
	text-transform: none;
}
.jtv-top-cart-box {
	position: relative;
	margin-top: 25px;
	margin-bottom: 20px;
	display: inline-block;
}
.top-cart-title p {
	margin: 0;
}
.jtv-top-cart-content .empty {
	padding: 10px;
	color: #333333;
}
.jtv-top-cart-content {
	display: none;
	position: absolute;
	top: 41px;
	left: 0px;
	background: #fff;
	padding: 0;
	text-align: right;
	z-index: 100;
	border: 1px solid #f1f1f1;
}
.top-subtotal {
	color: #fff;
	float: left;
	font-size: 14px;
	padding: 8px 10px;
	text-align: left;
}
.jtv-top-cart-content .product-details .btn-remove {
	position: absolute;
	right: 10px;
	top: 4px;
}
.jtv-top-cart-content .block-subtitle {
	border-bottom: 1px solid #DDDEE1;
	color: #fff;
	overflow: hidden;
	padding: 0px 5px;
	text-align: left;
	background: #8ac42f
}
.jtv-top-cart-content .block-subtitle .price {
	color: #fdd21b
}
ul#cart-sidebar {
	padding: 15px 20px 0px;
	min-height: 200px;
	overflow: auto;
	max-height: 200px;
}
/* top cart  */
.jtv-top-cart-content li.item {
	width: 290px;
	margin: 0px 0px 12px;
	border-bottom: 1px #eee solid;
	display: inline-block;
	padding-bottom: 12px;
}
.jtv-top-cart-content li.item.last {
	margin: 0;
	border-bottom: 0px #eee solid;
	padding-bottom: 10px
}
.jtv-top-cart-content .product-details {
	color: #333333;
	position: relative;
	text-align: left;
}
.mini-products-list .product-details {
	margin-left: 80px;
	padding: 0;
}
.jtv-top-cart-content .access {
}
.jtv-top-cart-content .product-details .btn-remove {
	position: absolute;
	right: 10px;
	top: 15px;
}
.jtv-top-cart-content .product-details .btn-edit {
	position: absolute;
	right: 25px;
	top: 1px;
	color: #333;
}
.jtv-top-cart-content .product-details .btn-edit:hover {
	color: #339900;
}
.jtv-top-cart-content .actions {
	padding: 20px;
	margin: 0px 0px 15px 0px;
	overflow: hidden;
	padding-top: 0px;
	padding-bottom: 10px
}
.button.btn-checkout {
	background: #FED557;
	border: 1px solid #F5C328;
	color: #003169;
	padding: 8px 18px;
}
.button.btn-checkout:hover {
	background: #339900;
}
.button:btn-bag:hover {
background:#f6f6f6
}
.jtv-btn-remove {
	background-image: url(../images/btn_remove.png);
	margin: 0px 0 0 5px;
}
.jtv-btn-remove:hover, .jtv-btn-remove:focus {
	background-position: 0 -12px;
}
.jtv-btn-remove {
	display: block;
	width: 15px;
	height: 13px;
	font-size: 0;
	line-height: 0;
	background-position: 0 0;
	background-repeat: no-repeat;
	text-indent: -999em;
	overflow: hidden;
	position: absolute;
	left: 28px;
	margin-top: 5px;
}
.block-compare .jtv-btn-remove {
	left: auto;
	right: 0px!important;
	margin-top: 0px;
	top: 5px
}
.mini-cart .jtv-btn-remove {
	left: auto;
	right: 0px!important;
	margin-top: 0px;
	top: 2px;
}
.mini-cart .actions .btn-checkout {
	background: #202726;
	color: #fff;
	font-size: 12px;
	text-transform: uppercase;
	padding: 7px 0px;
	border: none;
	cursor: pointer;
	display: inline-block;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
	float: left;
	letter-spacing: 0.5px;
	width: 135px;
	border: 1px #202726 solid
}
.mini-cart .actions .btn-checkout:hover {
	background: #202726;
	color: #fff;
}
.mini-cart .view-cart {
	cursor: pointer;
	float: none;
	font-size: 12px;
	padding: 7px 0px;
	text-decoration: none;
	text-transform: uppercase;
	text-align: center;
	display: inline-block;
	background: #8ac42f;
	color: #fff;
	letter-spacing: 0.5px;
	width: 135px;
	border: 1px #8ac42f solid
}
/*========== 4. Navigation ==========*/

nav {
	position: relative;
	margin: auto;
	width: 100%;
	z-index: 99;
	background: #44a23d;
}
.nav {
	margin: 0px auto 0
}
#nav li:hover a {
	color: #fff;
	border-bottom: none;
	background: inherit;
}
#nav li.active a {
	color: #fff;
}
#nav li a.active {
	color: #fff;
}
#nav {
	position: relative;
	z-index: 10;
	-webkit-transform: translateZ(0);
	padding: 0;
	margin: auto;
	text-align: center;
	background-color: #fea712;
	border-radius: 5px 5px 0px 0px;
}
#nav li {
	text-align: left;
}
#nav li a {
	display: block;
	text-decoration: none;
}
#nav ul.level1 li a {
	color: #444
}
#nav ul.level1 li a:hover {
	color: #8ac42f
}
#nav li {
	list-style: none;
	border-bottom: none;
	display: inline-block;
}
#nav li.last {
	padding-right: 0;
}
#nav li a {
	display: block;
	padding: 19px 0px;
}
#nav li a span {
	display: inline-block;
	padding: 0 18px;
	border-right: 1px solid #ffc01f;
	text-shadow: 2px 0 0 rgba(0,0,0,0.15);
}
#nav li:last-child a span {
	border-right: 0px solid #ddd;
}
#nav .jtv-menu-block-wrapper {
	position: absolute;
	left: -10000px;
	background-color: #fff;
}
#nav .jtv-menu-block-wrapper2 {
	padding: 0px;
}
#nav .nav-custom-link a {
	cursor: pointer;
}
#nav .nav-custom-link {
	position: static;
}
#nav .nav-custom-link .header-nav-dropdown-wrapper {
	padding: 5px 0px 0px 0px;
	overflow: hidden
}
#nav .nav-custom-link .header-nav-dropdown-wrapper p {
	margin: 10px;
	color: #999;
	line-height: 1.5em;
	font-size: 12px;
	font-weight: normal;
	margin-bottom: 15px
}
#nav .nav-custom-link .header-nav-dropdown-wrapper .heading {
	margin: 10px 0;
}
li.level0 a span {
	position: relative;
}
.nav-block {
	margin-top: 0;
	margin-bottom: 0%;
}
.nav-block-bottom {
	float: left
}
.nav-block-center {
	margin-top: 0px;
}
.nav-block-right {
	margin-top: 0px;
	float: right;
	padding: 0px;
	text-align: center;
}
.nav-block-right img {
	max-width: 100%
}
.nav-block-level1-top {
	margin-bottom: 10px;
}
.nav-block-level1-bottom {
	margin-top: 10px;
}
.nav-block .section-line {
	border-color: #eee;
}
#nav li a {
	font-size: 12px;
	position: relative;
	letter-spacing: 1px;
	-webkit-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	-o-transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	transition: all 0.3s cubic-bezier(0.8, 0, 0, 1);
	box-shadow: inset 0 0 0 0 #fff;
	text-transform: uppercase
}
ul.level0 {
	padding: 0
}
ul.level1 {
	padding: 0px;
}
#nav .jtv-menu-block-wrapper {
	background-color: #ffffff;
	color: #333333;
}
#nav ul li a:hover {
	color: #8ac42f;
}
.cat-label-label1 {
	background-color: #D12631;
	color: #ffffff;
}
.cat-label-label2 {
	background-color: #d52462;
	color: #ffffff;
}
#nav .jtv-menu-block-wrapper {
	background: #fff;
	border-top: none;
	box-shadow: 0 5px 10px -8px;
	width: 100%;
	padding: 0px;
}
#nav .jtv-menu-block-wrapper {
	top: 55px;
}
#nav li a {
	color: #fff;
	font-family: 'Raleway', sans-serif;
	font-weight: 700;
}
#nav li.mega-menu {
	position: static;
}
.nav-inner #nav li.mega-menu .jtv-menu-block-wrapper {
	border: 0 none;
	padding: 15px 18px;
	z-index: 100;
	opacity: 0;
	visibility: hidden;
	display: block!important;
	width: 100%;
	opacity: 0;
	transition: transform 0.5s ease 0s, opacity 0.6s ease 0s, max-height 0.6s step-end 0s, max-width 0.6s step-end 0s, padding 0.6s step-end 0s;
	visibility: hidden;
	border: 1px #e5e5e5 solid;
	border-top: none;
}
.nav-inner #nav li.mega-menu:hover .jtv-menu-block-wrapper {
	opacity: 1;
	transition: transform 0.5s ease 0s, opacity 0.2s ease 0s, max-height 0s step-end 0s, max-width 0s step-end 0s, padding 0s step-end 0s;
	z-index: 999;
	visibility: visible;
}
#nav .jtv-menu-block-wrapper2 .col-1 {
	float: left;
	width: 68.5%;
}
#nav .jtv-menu-block-wrapper2 .col-2 {
	padding: 0;
	float: left;
	width: 31%;
}
#nav .jtv-menu-block-wrapper2 .col-3 {
	padding: 5px 0;
	float: left;
	width: 20%;
}
#nav .col-1 ul.level0 li {
	width: 30%;
}
.col-2 img:hover {
	opacity: 0.5;
}
.col-2 .menu_image {
	display: block;
}
.col-2 img {
	max-width: 100%
}
.col-2 .jtv-nav-image1 {
	display: block;
}
#nav li:hover a {
	position: relative;
	z-index: 1;
}
#nav ul.level0 li {
	display: inline-block;
	float: none;
	margin-right: 20px;
	padding-bottom: 0px;
	padding-top: 0;
	vertical-align: top;
	width: 14.4%;
}
#nav ul.level0 li.last {
	display: inline-block;
	float: none;
	vertical-align: top;
	padding-top: 0;
	padding-bottom: 15px;
	margin-right: 0px
}
#nav ul.level0 li a {
	color: #222;
	font-size: 13px;
	line-height: 18px;
	padding: 4px 0px;
	margin-bottom: 0px;
	text-transform: uppercase
}
#nav ul li {
	list-style: none;
	padding: 5px 0;
}
#nav li ul li ul li:last-child {
	border: none
}
#nav ul.level1 {
	position: static;
	margin-bottom: 10px;
}
#nav ul.level1 li {
	width: 100% !important;
}
#nav ul.level1 li a {
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	font-size: 12px;
	letter-spacing: normal;
}
#nav ul.level1 li a:hover {
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
	padding-left: 10px;
}
#nav ul.level2 {
	margin: 7px 0;
	padding: 0;
	position: static;
}
#nav ul.level2 a {
	font-size: 0.9166em;
	line-height: 1.2727em;
	opacity: 0.6;
}
#nav li.level2:hover ul.level2 a {
	opacity: 1;
	transition: opacity 150ms ease-out;
	-moz-transition: opacity 150ms ease-out;
	-webkit-transition: opacity 150ms ease-out;
	-o-transition: opacity 150ms ease-out;
}
#nav ul ul ul li {
	background: none;
	padding-left: 0;
}
.nav-links li, .nav-links li a {
	line-height: 3em;
}
.nav-links li a {
	padding: 0 0.5833em;
}
.nav-links li.label {
	padding-right: 0.5833em;
}
.nav-links li {
	float: left;
}
.nav-links li a {
	display: block;
}
.grid12-5 {
	display: inline;
	float: left;
	margin-left: 1%;
	margin-right: 1%;
	width: 23%;
}
.jtv-custom-box {
	display: inline;
	float: left;
	margin-right: 20px;
	width: 23.5%;
	text-align: center;
	border: 1px #e5e5e5 solid;
	background: #fff
}
.jtv-custom-box i {
	color: #fdae03;
	font-size: 32px;
	padding: 15px 20px;
	border: 1px #eee solid;
	border-radius: 3px;
	background-color: #f8f8f8;
	margin-top: 18px;
}
.jtv-custom-box:last-child {
	margin-right: 0%;
}
.jtv-custom-box img {
	width: 100%
}
.jtv-custom-box .item-title a {
	text-transform: uppercase;
	margin-top: 10px;
	color: #aaa
}
.jtv-menu-box-right {
	display: inline;
	float: left;
	margin: 8px 0px 0px 10px;
	width: 34%;
}
.jtv-menu-box-left {
	display: inline;
	float: left;
	margin-left: 0;
	margin-right: 1%;
	width: 64%;
}
#nav .jtv-menu-box-left ul.level0 li {
	width: 21%;
}
.grid13-8 {
	display: inline;
	float: left;
	margin-left: 0;
	margin-right: 1%;
	margin-bottom: 0;
	width: 80.90%;
}
.back-link {
	float: left
}
#nav .jtv-menu-block-wrapper .jtv-nav-banner {
	padding: 5px 0 0 0px;
	overflow: hidden;
}
#nav .jtv-menu-block-wrapper .jtv-nav-banner .jtv-banner-box {
	float: left;
	margin-right: 18px;
	width: 32.2%;
}
#nav .jtv-menu-block-wrapper .jtv-nav-banner .jtv-banner-box img {
	max-width: 100%;
}
#nav .jtv-menu-block-wrapper .jtv-nav-banner .jtv-banner-box_last {
	margin-right: 0px;
}
#nav .jtv-menu-block-wrapper .jtv-nav-banner .jtv-banner-box .jtv-nav-banner-img a {
	display: block;
	padding: 0px;
}
#nav .jtv-menu-block-wrapper .jtv-nav-banner .jtv-banner-box .push_text {
	color: #666;
	font-size: 12px;
	padding: 8px 0;
	text-align: center
}
.custom-menu {
	color: #999;
	padding-top: 25px!important;
	padding-bottom: 25px!important
}
#nav .nav-custom-link .header-nav-dropdown-wrapper h6.heading {
	color: #000;
	text-transform: uppercase;
	margin-top: 0px;
	margin-bottom: 10px;
}
#nav .nav-custom-link .header-nav-dropdown-wrapper h6.heading {
	color: #000;
	margin-bottom: 0px;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	padding: 20px;
	padding-bottom: 0;
	font-weight: 600;
}
#nav li.drop-menu ul {
	position: absolute;
	top: 55px;
	left: 0;
	background: #fff;
	padding: 6px 0;
	border: 1px #eee solid;
	width: 180px;
	text-align: left;
	z-index: 1000;
	opacity: 0;
	transition: transform 0.5s ease 0s, opacity 0.2s ease 0s, max-height 0s step-end 0s, max-width 0s step-end 0s, padding 0s step-end 0s;
}
#nav li.drop-menu:hover ul {
	opacity: 1;
	z-index: 999;
	visibility: visible;
	display: inline-block !important;
	transition: transform 0.5s ease 0s, opacity 0.2s ease 0s, max-height 0s step-end 0s, max-width 0s step-end 0s, padding 0s step-end 0s;
}
#nav li.drop-menu ul li ul {
	border-top: none;
}
#nav li.drop-menu {
	position: relative;
}
#nav li.drop-menu ul li {
	position: relative;
	width: 100%;
}
#nav li.drop-menu ul li a {
	color: #333;
	line-height: 20px;
	font-size: 12px;
	padding: 0 12px;
	display: block;
	transition: all 0.5s;
	font-weight: 400;
	text-transform: capitalize;
}
#nav li.drop-menu ul li a span, #nav ul.level1 a span {
	border: none;
	padding: 0px;
	font-weight: 400;
	text-transform: capitalize;
	width: 100%;
	color: #333;
	text-shadow: none;
}
#nav li.drop-menu ul li a span:hover, #nav ul.level1 a span:hover {
	color: #8ac42f;
}
#nav ul.level0 li a span {
	border: none;
	padding: 0px;
	width: 100%;
	letter-spacing: 0.5px;
	display: inherit;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-shadow: none;
}
#nav li.drop-menu ul li:hover a {
	color: #8ac42f;
	padding-left: 18px;
	transition: all 0.5s;
}
#nav li.drop-menu ul li.sub-cat ul {
	opacity: 0;
	position: absolute;
	left: 10000px;
	top: 0px;
	width: 210px;
	visibility: hidden;
}
#nav li.drop-menu ul li.sub-cat:hover ul {
	opacity: 1;
	position: absolute;
	left: 175px;
	top: 0px;
	visibility: visible;
}
#nav li.drop-menu ul {
	display: none;
}
.jtv-sticky-header {
	position: fixed;
	margin-top: 0px;
	z-index: 1000;
	display: block;
	top: 0px;
	background: #fea712;
	width: 100%;
	transition: all 0.5s;
}
.header-container {
	transition: all 0.5s;
	/*background: #ffffff;*/
	background-color: #caaec4;
}
/*----- 5. Features Box ----------- */

.our-features-box {
	overflow: hidden;
	width: 100%;
	margin: auto;
	padding: 15px 0 15px;
	background: #f2f2f2;
}
.our-features-box ul {
	list-style: none;
	margin: auto;
	padding: 0;
	border: none;
	overflow: hidden
}
.our-features-box .seprator-line {
	width: 2%!important;
	text-align: center;
	border-left: 1px #f1f1f1 solid;
	height: 50px;
	margin-left: 2%
}
.our-features-box li {
	float: left;
	margin-bottom: 0px;
	text-align: center;
	width: 30.5%;
	padding: 0;
	display: inline-block;
}
.our-features-box .feature-box {
	overflow: hidden;
	font-size: 13px;
	text-align: center;
	color: #333;
	line-height: 1.4em;
	font-weight: normal;
	text-transform: uppercase;
	padding: 0px 0px;
}
.our-features-box .feature-box h6 {
	letter-spacing: 0.5px;
	margin-bottom: 3px;
	margin-top: 5px;
	color: #f3f6f9;
}
.our-features-box .feature-box .content {
	display: inline-block;
	text-align: left;
	text-transform: none;
	color: #fff;
}
.our-features-box .feature-box .content p {
	margin: 0px
}
.our-features-box .feature-box span {
	display: inline-block;
	font-size: 16px;
	font-weight: normal;
	padding-top: 5px;
	text-transform: none;
	margin-right: 6px;
	color: #fff;
}
.our-features-box .feature-box i {
	display: inline-block;
	font-size: 16px;
	font-weight: normal;
	padding-top: 5px;
	text-transform: none;
	margin-right: 6px;
	color: #999;
	vertical-align: -2px;
}
/* 6. hot deal */
.timer-grid {
	overflow: hidden;
	display: inline-block;
}
.timer-grid .box-time-date {
	font-size: 9px;
	color: #eee;
	min-width: 55px;
	min-height: 38px;
	text-align: center;
	text-transform: uppercase;
	border-radius: 0;
	display: inline-block;
}
.timer-grid .box-time-date span {
	display: block;
	font-size: 15px;
	color: #fff;
	margin-top: 6px;
}
.jtv-box-timer {
	text-align: center;
	background: #8ac42f;
	width: 100%;
	margin-top: 14px;
	display: inline-block;
}
.hot-deal {
	padding-right: 10px;
	overflow: hidden;
}
.hot-deal .jtv-box-hover {
	top: 30%;
}
.hot-deal .item button.button {
	margin: 5px 0px;
}
.hot-deal .item {
	margin-top: auto;
	padding: 0px;
	border: 1px #e5e5e5 solid;
	background: #fff;
}
.hot-deal .item-info {
	margin-top: 6px;
}
.hot-deal .item-info .item-title a {
	font-size: 13px;
	letter-spacing: 0.5px;
	margin-bottom: 5px;
	display: inline-block;
	color: #333;
}
.hot-deal .item-info .item-title a:hover {
	color: #8ac42f;
}
.hot-deal .item .item-inner .item-img {
	margin: 0px auto 0;
}
.hot-deal .owl-item .item {
	margin: 0 2px 0 0px;
}
.hot-deal .product-flexslider {
	margin: 0px;
}
/* 7. jtv top banner */
.jtv-top-banner {
	float: left;
	width: 100%;
	text-align: center;
	padding: 0px 0px 25px;
}
.jtv-top-banner .imgbox {
	width: 33.33333%;
	float: left;
	overflow: hidden;
}
.jtv-top-banner .imgbox img {
	display: block;
}
.jtv-top-banner .jtv-cont-box {
	width: 33.33333%;
	background: #ffc229;
	color: #fff;
	padding: 44px 40px 46px 40px;
	float: left;
}
.jtv-top-banner .jtv-cont-box2 {
	float: left;
	width: 33.33333%;
	background: #bbd615;
	color: #fff;
	padding: 44px 40px 46px 40px;
	clear: left;
}
.jtv-top-banner .jtv-cont-box3 {
	float: left;
	width: 33.33333%;
	background: #66ccff;
	color: #fff;
	padding: 44px 40px 46px 40px;
}
.jtv-top-banner h3 {
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	line-height: 29px;
	letter-spacing: 1px;
}
.jtv-top-banner p {
	color: #f8f8f8;
	line-height: 25px;
}
.jtv-top-banner .jtv-line-bg {
	width: 100px;
	height: 1px;
	background-color: #eee;
	margin: 21px auto 20px auto;
}
/* 8. Featured Products */
.category-product .navbar-collapse {
	padding: 0px;
}
.category-product .nav.navbar-nav {
	width: 100%;
	text-align: center;
	margin-bottom: 5px;
}
.category-product .navbar {
	line-height: inherit;
	margin-bottom: auto;
	min-height: inherit;
	border: none;
}
.category-product .navbar-nav > li {
	float: none;
	display: inline-block;
}
.category-product .navbar-nav > li.divider {
	border-right: 1px solid #ccc;
	color: #ccc;
	display: inline;
	height: 20px;
	width: 1px;
	margin-left: 4px;
}
.category-product .navbar-nav > li.active a {
	color: #8ac42f;
}
.category-product .navbar-nav > li > a {
	color: #666;
	font-size: 12px;
	letter-spacing: 1px;
	padding: 0px 5px 5px 5px;
	margin-top: 5px;
	margin-left: 12px;
}
.category-product .nav>li>a:hover, .category-product .nav>li>a:focus {
	text-decoration: none;
	background-color: inherit;
}
.product-bestseller .product-list li {
	border-right: 1px solid #eaeaea;
	padding-bottom: 10px;
	min-height: 350px;
}
.product-bestseller .product-list .owl-stage .active:last-child li {
	border: none;
}
.tab-container {
	position: relative;
	margin: 0px -10px;
}
.tab-container .tab-panel {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 0;
	visibility: hidden;
}
.tab-container .active {
	opacity: 1;
	visibility: inherit;
	position: inherit;
	-vendor-animation-duration: 0.3s;
	-vendor-animation-delay: 1s;
	-vendor-animation-iteration-count: infinite;
}
.navbar-nav .jtv-title {
	border: none;
	padding: 0px 0px 8px;
}
/*  9. bottom banner section  */
.jtv-bottom-banner-section {
	margin-top: 30px;
}
.jtv-banner-info span {
	display: inline-block;
	margin: 80px 0px 8px 25px;
	font-size: 14px;
	letter-spacing: 0.5px;
}
.jtv-bottom-banner-section a:hover img {
	transform: scale(1.1) rotate(-1.1deg);
	-webkit-transform: scale(1.1) rotate(-1.1deg);
	-moz-transform: scale(1.1) rotate(-1.1deg);
	-ms-transform: scale(1.1) rotate(-1.1deg);
	-o-transform: scale(1.1) rotate(-1.1deg);
}
.jtv-bottom-banner-section a img {
	transition: all 0.4s ease 0s;
	-webkit-transition: all 0.4s ease 0s;
	-moz-transition: all 0.4s ease 0s;
	-o-transition: all 0.4s ease 0s;
	-ms-transition: all 0.4s ease 0s;
}
.jtv-img-box {
	margin-bottom: 30px;
	clear: both;
	overflow: hidden;
}
.jtv-img-box h3 {
	letter-spacing: 2px;
	margin-bottom: 5px;
	margin-left: 25px;
	text-align: left;
	text-transform: uppercase;
	transition: all 0.4s ease 0s;
	margin-top: 0px;
}
.jtv-img-box img {
	max-width: 100%;
}
.jtv-banner-info {
	width: 100%;
	z-index: 1;
	position: absolute;
	height: 100%;
	color: #fff;
	text-align: left;
	top: 0px;
}
/*  10. Best Sellers Slider  */
.bestsell-pro {
	margin-bottom: 5px;
}
.jtv-bestsell-section .slider-items-products {
	overflow: hidden;
}
.bestsell-block {
	margin-bottom: 10px;
	position: relative;
}
#bestsell-slider.product-flexslider {
	margin: 0px -12px;
}
.jtv-bestsell-section .block-title {
	color: #333;
	font-size: 20px;
	font-weight: 500;
	text-transform: uppercase;
	margin: 0px auto 15px;
	line-height: normal;
	letter-spacing: 0.5px;
	text-align: center;
}
.jtv-bestsell-section .block-title h3 {
	margin: auto;
}
.jtv-bestsell-section .owl-theme .owl-controls {
	margin-top: 10px;
	position: absolute;
	text-align: center;
	top: -10.8%;
	width: 100%;
}
.category-products .text-banner {
	background-color: #fff;
	border-top: 1px solid #eaeaea;
	bottom: -60px;
	left: 0;
	padding-bottom: 20px;
	padding-top: 25px;
	position: absolute;
	right: 0;
	z-index: 10;
}
.jtv-bestsell-section .slider-items-products .owl-buttons a {
	background: #fff;
	border: 1px #e5e5e5 solid;
	color: #666;
	display: block;
	height: 35px;
	margin: 0px;
	position: absolute;
	top: 50%;
	z-index: 5;
	width: 35px;
}
.jtv-bestsell-section .slider-items-products .owl-buttons a:hover {
	text-decoration: none;
	background: #8ac42f;
	border: 1px #8ac42f solid;
	color: #fff;
}
.jtv-bestsell-section .slider-items-products .owl-buttons .owl-next {
	right: 47px;
	left: inherit;
}
.jtv-bestsell-section .slider-items-products .owl-buttons .owl-prev a:before {
	line-height: 35px;
}
.jtv-bestsell-section .slider-items-products .owl-buttons .owl-next a:before {
	line-height: 32px;
}
.jtv-bestsell-section .slider-items-products .owl-buttons .owl-prev {
	left: 12px;
	right: inherit;
}
/* 11. testimonials slider */
.jtv-testimonial-block {
	margin-bottom: 5px;
}
.jtv-testimonial-block .block-title h3 {
	color: #333;
	font-weight: 500;
	text-transform: uppercase;
	margin: 5px auto 8px;
	line-height: normal;
	letter-spacing: 0.5px;
	text-align: center;
	margin-top: 0px;
}
.jtv-testimonial-block #quote-carousel {
	padding: 45px 10px 30px 10px;
	margin-top: 0px;
	background: #fff;
	border: 1px #e5e5e5 solid;
}
.jtv-testimonial-block .carousel-inner {
	margin-top: 30px;
}
.jtv-testimonial-block #quote-carousel .carousel-control {
	background: none;
	color: #CACACA;
	font-size: 2.3em;
	text-shadow: none;
	margin-top: 30px;
}
.jtv-testimonial-block #quote-carousel .carousel-control.left {
	left: -60px;
	display: none;
}
.jtv-testimonial-block #quote-carousel .carousel-control.right {
	right: -60px;
	display: none;
}
.jtv-testimonial-block #quote-carousel .carousel-indicators {
	right: inherit;
	top: auto;
	bottom: 0px;
	margin-right: 0px;
	position: relative;
	min-height: 100px;
}
.jtv-testimonial-block #quote-carousel .carousel-indicators li {
	width: 70px;
	height: 70px;
	margin: -50px 5px 5px;
	cursor: pointer;
	border: 2px solid #e5e5e5;
	border-radius: 0px;
	opacity: 0.6;
	overflow: hidden;
	transition: all 0.4s;
}
.jtv-testimonial-block #quote-carousel .carousel-indicators li.active {
	background: #eee;
	width: 110px;
	height: 110px;
	border-color: #8ac42f;
	opacity: 1;
	overflow: hidden;
	margin: -12px 4px;
}
.jtv-testimonial-block strong.name {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	display: inline-block;
	margin: 0px auto 2px;
	position: relative;
	color: #fdae03;
	letter-spacing: 0.5px;
	font-style: normal;
	font-family: 'Poppins', sans-serif;
}
.jtv-testimonial-block strong.designation {
	font-size: 11px;
	line-height: 14px;
	font-weight: 400;
	color: #999;
	display: block;
	font-style: normal;
	font-family: 'Poppins', sans-serif;
}
.jtv-testimonial-block .item blockquote {
	border-left: none;
	margin: 0;
	padding: 0px;
	background: #fff;
}
.jtv-testimonial-block .item blockquote:before {
	content: "";
}
.jtv-testimonial-block .item blockquote p:before {
	content: "\f10d";
	font-family: 'Fontawesome';
	float: left;
	margin-right: 10px;
	font-size: 18px;
	color: #ccc;
}
.jtv-testimonial-block .item blockquote p:after {
	content: "\f10e";
	font-family: 'Fontawesome';
	float: none;
	margin-left: 10px;
	font-size: 16px;
	color: #ccc;
}
.jtv-testimonial-block blockquote p {
	font-size: 14px;
	line-height: 24px;
	color: #5c5c5c;
	font-style: normal;
	font-family: 'Poppins', sans-serif;
	position: relative;
}
/* 12. special product slider */
.special-products-slider {
	margin-bottom: 30px;
}
.jtv-special-block {
	background-color: #fff;
	border: 1px solid #e5e5e5;
	margin-bottom: 0px;
	overflow: hidden;
	position: relative;
}
.jtv-special-block .jtv-block-inner {
	border-width: 0 !important;
	float: left;
	width: 24%;
	padding: 50px 25px 50px;
	background: url(../images/special-product-bg.jpg);
	background-repeat: no-repeat;
	background-position: top left;
}
.jtv-special-block .block-content {
	border-left: 1px solid #f1f1f1;
	margin-left: 24%;
	padding: 5px 10px;
	width: 76%;
	background: #fff;
}
.jtv-special-block .products-grid .item .item-inner {
	border: 0px #e5e5e5 solid;
	padding-bottom: 0px;
}
.jtv-special-block .owl-item .item {
	margin: 0 6px 15px;
}
#special-slider.product-flexslider {
	margin: 0px;
	background: url(../images/special-product-bg.jpg);
	background-repeat: no-repeat;
	background-position: top left;
}
.jtv-special-block .block-title {
	color: #fff;
	line-height: 28px;
	padding: 0px 0px 10px;
	position: relative;
	text-transform: uppercase;
	letter-spacing: 0.5px;
}
.jtv-special-block .block-title h3 {
	margin: auto;
}
.special-products a.jtv-more-bnt {
	border: 1px #e5e5e5 solid;
	background: #fff;
	text-transform: uppercase;
	color: #666;
	padding: 10px 15px;
	font-size: 11px;
	letter-spacing: 1px;
	float: left;
	margin-top: 10px;
	z-index: 1;
	position: relative;
	font-weight: 600;
}
.special-products a.jtv-more-bnt:hover {
	border: 1px #8ac42f solid;
	background: #8ac42f;
	color: #fff;
}
.jtv-special-block .block-title h2 {
	margin: auto;
}
.jtv-special-block .jtv-block-inner .jtv-decs-text {
	margin-top: 0px;
	color: #eee;
	float: right;
	font-size: 14px;
	font-weight: 300;
	line-height: 22px;
	text-transform: none;
	letter-spacing: 0.5px;
}
.special-products .owl-theme .owl-controls {
	position: absolute;
	text-align: center;
	top: 0;
	width: 100%;
}
.special-products .slider-items-products .owl-buttons a {
	background: #fff;
	display: block;
	height: 35px;
	margin: 0px 0 0 -30px;
	position: absolute;
	top: 50%;
	width: 35px;
	z-index: 5;
	color: #333;
	border: 1px #eee solid;
}
.special-products .slider-items-products .owl-buttons a:hover {
	text-decoration: none;
	background: #8ac42f;
	color: #fff;
	border: 1px #8ac42f solid;
}
.special-products .slider-items-products .owl-buttons .owl-next {
	right: 13px;
	top: -1px;
}
.special-products .slider-items-products .owl-buttons .owl-prev a:before {
	line-height: 32px;
}
.special-products .slider-items-products .owl-buttons .owl-next a:before {
	line-height: 32px;
}
.special-products .slider-items-products .owl-buttons .owl-prev {
	left: 30px;
	top: -1px;
	right: inherit;
}
/*------- 13. Latest Blog -------*/

.latest-blog img {
	width: 100%;
}
.latest-blog {
	text-align: left;
	margin: auto;
	padding: 30px 0px 30px 0px;
	overflow: hidden;
}
.blog-block {
	border: 1px #f1f1f1 solid;
	background: #fff;
	overflow: hidden;
	padding-bottom: 35px
}
.latest-blog h3 {
	margin-bottom: 50px;
}
.blog-title {
	font-size: 13px;
	text-transform: uppercase;
	margin-top: 15px;
	display: block;
	padding: 0px 0px;
	letter-spacing: .5px
}
.latest-blog .icon-calendar:before {
	margin-right: 5px
}
.latest-blog .post-date {
	width: 100px;
	z-index: 100;
	top: 10%;
	font-weight: normal;
	text-align: left;
	font-size: 12px;
	color: #999;
	margin-left: 0px;
	padding: 5px 0px;
}
.latest-blog .readmore {
	font-size: 12px;
	display: inline-block;
	padding: 5px 10px;
	border: 1px #d1d1d1 solid;
	text-transform: uppercase;
	font-size: 11px;
	letter-spacing: 1px;
	font-weight: bold
}
.latest-blog .readmore:hover {
	background: #8ac42f;
	color: #fff
}
.latest-blog .blog-title h2 {
	color: #666;
	font-size: 16px;
	margin-top: 0;
	padding: 12px 10px 2px 0px;
	position: relative;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 1px;
}
.latest-blog .blog_inner {
	float: left;
	margin-top: 20px;
	position: relative
}
.post-date {
	color: #5e5f5f;
	font-weight: normal;
	font-size: 13px;
}
.blog_post img {
	max-width: 100%;
	height: auto;
}
.latest-blog p {
	font-size: 13px;
	line-height: 20px;
	margin: 10px 0px 0px 0px;
	text-align: left;
	color: #777;
	padding: 0px 0px 0px 0px;
}
.blog_post img {
	margin-bottom: 20px;
	width: 100%;
}
.latest-blog .blog-img {
	display: block;
	overflow: hidden;
}
.latest-blog .blog-img img {
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	transform: translateZ(0);
}
.latest-blog .blog-img:hover img {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
	-moz-transform: scale(1.2);
}
.blog-img img {
	-webkit-transition: all 0.4s ease-in-out 0.5s;
	-moz-transition: all 0.4s ease-in-out 0.5s;
	-o-transition: all 0.4s ease-in-out 0.5s;
	-ms-transition: all 0.4s ease-in-out 0.5s;
	transition: all 0.4s ease-in-out 0.5s;
}
.blog-img .mask {
	background-color: #000;
	opacity: 0;
	-webkit-transition: all 0.3s ease-in 0.4s;
	-moz-transition: all 0.3s ease-in 0.4s;
	-o-transition: all 0.3s ease-in 0.4s;
	-ms-transition: all 0.3s ease-in 0.4s;
	transition: all 0.3s ease-in 0.4s;
}
.blog-img a.info {
	opacity: 0;
	-webkit-transition: all 0.3s ease-in-out 0.1s;
	-moz-transition: all 0.3s ease-in-out 0.1s;
	-o-transition: all 0.3s ease-in-out 0.1s;
	-ms-transition: all 0.3s ease-in-out 0.1s;
	transition: all 0.3s ease-in-out 0.1s;
	-webkit-transform: translateY(0px);
	-moz-transform: translateY(0px);
	-o-transform: translateY(0px);
	-ms-transform: translateY(0px);
	transform: translateY(0px);
}
.blog-img:hover .mask {
	background-color: rgba(0,0,0,0.5);
	opacity: 1;
	-webkit-transition-delay: 0s;
	-moz-transition-delay: 0s;
	-o-transition-delay: 0s;
	-ms-transition-delay: 0s;
	transition-delay: 0s;
}
.blog-img:hover a.info {
	opacity: 1;
	-webkit-transform: translateY(85px);
	-moz-transform: translateY(85px);
	-o-transform: translateY(85px);
	-ms-transform: translateY(85px);
	transform: translateY(85px);
	-webkit-transition-delay: 0.3s;
	-moz-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
	-ms-transition-delay: 0.3s;
	transition-delay: 0.3s;
}
.blog-img .mask, .blog-img .content {
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	text-align: center;
}
.blog-img a.info {
	display: inline-block;
	text-decoration: none;
	padding: 7px 14px;
	background: #8ac42f;
	color: #fff;
	font-size: 11px;
	border: #fff double;
	text-transform: uppercase
}
.blog-img a.info:hover {
	background: #202726;
}
.blog-img {
	position: relative;
}
/* Blog preview */
.blog-outer-container .jtv-title {
	padding: 18px 0px 8px;
	margin-bottom: 0px;
}
.post-meta {
	list-style: outside none none;
	padding: 0 0px 2px;
}
.post-meta li {
	display: inline-block;
	margin-right: 10px;
	color: #a7a7a7;
	text-transform: none;
	font-size: 12px;
}
.post-meta li a {
	margin-right: 5px;
	color: #a7a7a7;
}
.post-meta li i {
	padding-right: 6px;
}
.title-primary {
	font-size: 24px;
	letter-spacing: 3px;
}
.blog-preview {
	padding: 60px;
}
.blog-outer-container {
	margin-top: 0px;
	display: inline-block;
	margin-bottom: 15px;
}
.blog-preview_item {
	padding: 25px 0 25px 26px;
	width: 49.4%;
	border-left: 1px solid #e5e5e5;
}
.blog-preview_item:first-child {
	padding-left: 12px;
	padding-right: 25px;
	width: 49.4%;
	border: none;
}
.blog-preview_image {
	float: left;
	width: 100%;
	position: relative;
}
.blog-preview_image img {
	float: left;
	width: 100%;
}
.blog-preview_info {
	float: left;
	padding: 18px;
	width: 100%;
	background: #fff;
	border: 1px #e5e5e5 solid;
	border-top: none;
}
.blog-preview_info h4 {
	margin: auto auto 12px;
}
.blog-preview_info h4 a {
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 0.5px;
	line-height: 1.3em;
	margin: auto;
	padding-bottom: 6px;
	color: #000;
	text-transform: uppercase;
}
.blog-preview_info h4 a:hover {
	color: #8ac42f;
}
.blog-preview_desc {
	color: #666;
	font-size: 13px;
	line-height: 21px;
	padding-bottom: 15px;
}
.blog-preview_btn {
	font-size: 11px;
	margin: 0px;
	padding: 2px 0px;
	color: #666;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-weight: bold;
}
.blog-preview_btn:focus, .blog-preview_btn:hover {
	color: #8ac42f;
}
.blog-preview_posted {
	color: #333;
	background: rgba(255, 255, 255, 0.9);
	bottom: 10px;
	height: 60px;
	right: 10px;
	position: absolute;
	width: 60px;
	z-index: 10;
}
.blog-preview_date {
	float: left;
	font-size: 13px;
	padding: 6px 0 10px;
	position: relative;
	text-align: center;
	width: 100%;
	text-transform: uppercase;
	font-weight: 300;
	letter-spacing: 1px;
}
.blog-preview_date span {
	font-size: 18px;
}
.blog-preview_comments {
	float: right;
	font-size: 12px;
	padding-top: 3px;
	text-align: center;
}
.blog-preview_comments i {
	color: #8ac42f;
}
.blog-preview_image:hover .blog-preview_posted {
	color: #000;
}
.blog-container {
	padding-top: 25px;
	padding-bottom: 90px;
	text-align: center;
}
.blog-container .row {
	padding-top: 83px;
}
.blog-preview-small {
	float: left;
	position: relative;
	width: 100%;
}
.blog-preview-small_img {
	float: left;
	width: 100%;
}
.blog-preview-small_link {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
.blog-preview-small_link:hover {
	background-color: rgba(0,0,0,0.4);
}
.blog-preview-small_link:hover .blog-preview_posted {
	color: #fff;
}
.blog-preview-small_txt {
	bottom: 30px;
	left: 0;
	position: absolute;
	text-align: center;
	width: 100%;
}
.blog-preview-small .blog-preview_posted {
	border-style: none;
	border-width: 0;
	bottom: auto;
	left: 0;
	margin: 0 auto;
	right: 0;
	top: -25px;
}
.blog-preview-small_more {
	border-color: #ececec;
	float: left;
	font-size: 11px;
	margin: 28px 0 0;
	padding: 8px 15px;
}
.blog-inner {
	margin: auto -15px 25px
}
/* 14. Brand Logo Slider*/
.brand-logo {
	margin-top: 15px;
	padding-bottom: 10px;
}
#brand-logo-slider {
	background: none repeat scroll 0 0 #fff;
	border: 1px solid #e5e5e5;
	margin: 0px 0 20px;
	padding: 15px 0 15px;
}
.brand-logo .slider-items-products .item {
	text-align: center;
}
.brand-logo .owl-pagination {
	top: -45px;
}
.brand-logo .slider-items-products .owl-buttons .owl-next {
	position: absolute;
	right: -7px;
	top: 35px;
	display: none;
}
.brand-logo .slider-items-products .owl-buttons a {
	border: none;
	background: #f5f5f5;
	margin-top: -15px;
}
.brand-logo .slider-items-products .owl-buttons .owl-prev a {
	border: none;
}
.brand-logo .slider-items-products .owl-buttons .owl-next a:before {
	font-size: 28px;
}
.brand-logo .slider-items-products .owl-buttons .owl-prev a:before {
	font-size: 28px;
}
.brand-logo .slider-items-products .owl-buttons .owl-prev {
	left: 0px;
	position: absolute;
	top: 35px;
	background: none;
	display: none;
}
.brand-logo a.flex-next {
	background: #fff !important;
	color: #666 !important;
}
.brand-logo a.flex-next:hover {
	color: #000 !important;
	background: #fff !important;
}
.brand-logo a.flex-prev {
	background: #fff !important;
	color: #666 !important;
}
.brand-logo a.flex-prev:hover {
	color: #000 !important;
	background: #fff !important;
}
.carousel-inner {
	margin-top: 20px;
}
.slider-items-products .owl-buttons .owl-prev a {
	background-position: 0 100%;
	border: 1px #d1d1d1 solid;
	border-right: none
}
/* 6b. offer slider */
.offer-slider {
	display: block;
	text-align: center;
	margin: 20px auto 25px;
}
.offer-slider h2 {
	color: #fff;
	font-size: 42px;
	margin: 0px auto;
	display: block;
	padding: 0px;
	text-align: center;
	margin-bottom: 10px;
	letter-spacing: -1px;
}
.offer-slider h3 {
	width: 100px;
	height: 100px;
	background-color: #000;
	text-align: center;
	display: inline-block;
	font-size: 12px;
	letter-spacing: 1px;
	text-transform: uppercase;
	line-height: 20px;
	margin-bottom: 20px;
	border-radius: 50px
}
.offer-slider h3 span {
	display: block;
	padding-top: 32%;
	color: #fff
}
.offer-slider h3 span strong {
	display: block;
	color: #fff;
	font-weight: 900
}
.offer-slider li {
	position: relative
}
.offer-slider p {
	color: #fff;
	padding: 0;
	font-size: 16px;
	font-weight: normal;
	text-align: center;
	font-family: Georgia, "Times New Roman", Times, serif;
	font-style: italic
}
.offer-slider .shop-now {
	padding: 10px 25px;
	font-size: 13px;
	text-transform: uppercase;
	color: #fff;
	letter-spacing: 1px;
	display: inline-block;
	margin-top: 25px;
	background: none;
	border: 1px #fff solid;
	margin-bottom: 25px
}
.offer-slider a.shop-now:after {
	content: '\f0da';
	font-family: FontAwesome;
	display: inline-block !important;
	margin-left: 15px;
	color: #8ac42f;
	font-size: 16px;
}
.offer-slider a.shop-now:hover {
	color: #8ac42f;
	background-color: #fff;
}
.cross-sell-pro {
	margin-top: 25px;
}
#cross-sell-products .owl-pagination {
	display: none;
}
.owl-item .item {
	margin: 0 12px;
}
.slider-items-products .item {
	text-align: center;
	margin-top: 0px!important
}
.slider-items-products .item .product-image {
	overflow: hidden;
	display: inline-block;
}
.slider-items-products .item .product-image img {
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	-webkit-transition-property: transform;
	transition-property: transform;
	max-width: 100%;
}
.owl-buttons {
	opacity: 1;
}
.slider-items-products .owl-buttons .owl-prev {
	position: absolute;
	right: 18px;
	top: -20.5%;
}
.slider-items-products .owl-buttons .owl-next {
	position: absolute;
	right: -10px;
	top: -20.5%;
}
.slider-items-products .owl-buttons .owl-prev a {
	background-position: 0 100%;
}
.slider-items-products .owl-buttons .owl-next a {
	background-position: 100% 100%;
	top: 50%;
	border-left: 1px #e5e5e5 solid;
}
.slider-items-products .owl-buttons .owl-next a:before {
	font-family: 'FontAwesome';
	font-style: normal;
	font-weight: normal;
	speak: none;
	-webkit-font-smoothing: antialiased;
	content: "\f105";
	text-transform: none;
	font-size: 24px;
	line-height: 12px;
}
.slider-items-products .owl-buttons .owl-prev a:before {
	font-family: 'FontAwesome';
	font-style: normal;
	font-weight: normal;
	speak: none;
	-webkit-font-smoothing: antialiased;
	content: "\f104";
	text-transform: none;
	font-size: 24px;
	line-height: 16px;
}
.slider-items-products .owl-buttons a {
	display: block;
	height: 18px;
	margin: 0px 0 0 -30px;
	position: absolute;
	top: 50%;
	width: 30px;
	z-index: 5;
	color: #888;
	border: 1px #e5e5e5 solid;
	background: #fff;
}
#offer-slider.product-flexslider {
	margin: 10px 0px 25px;
}
/*#offer-slider{ border:1px #e5e5e5 solid; background:#fff; padding:25px 12px;}*/
.product-flexslider {
	margin: 20px 0px;
}
.jtv-bestsell-section .product-flexslider .owl-buttons {
	opacity: 1;
}
.slider-items-products .owl-buttons .owl-next {
	right: -10px
}
.product-flexslider:hover .owl-buttons {
	opacity: 1;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}
.product-flexslider .owl-buttons {
	opacity: 0;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}
.owl-theme .owl-controls .owl-buttons div {
	background: none !important;
}
.product-flexslider .owl-buttons {
	opacity: 1;
}
.slider-items-products .owl-buttons a:hover {
	text-decoration: none;
	background: #8ac42f;
	color: #8ac42f;
	border: 1px #8ac42f solid;
}
.slider-items-products .owl-buttons .owl-prev a {
	background-position: 0 100%;
	border: 1px #e5e5e5 solid;
}
.recommend {
	margin: 20px auto;
	position: relative;
}
.recommend .controls .next {
	right: 0;
}
.recommend .controls .prev {
	right: 36px;
}
.offer-info {
	margin: auto;
	padding: 0px 30px;
	text-align: center;
}
/* 6f. Other Slider   **/
.box-up-sell .flex-direction-nav .flex-prev, .box-cross-sell .flex-direction-nav .flex-prev, #brand-slider .flex-direction-nav .flex-prev, .featured-products .flex-direction-nav .flex-prev {
	right: 53px;
}
.main .box-up-sell h2.subtitle .line, .main .box-cross-sell h2.subtitle .line, .brand-slider-wrap h2.subtitle .line, .featured-products h2.subtitle .line {
	right: 130px;
}
.side-banner {
	margin-bottom: 20px
}
.side-banner:last-child {
	margin-bottom: 0px
}
.side-banner img {
	width: 100%;
}
/*========== 14. Products Grid ==========*/
.page-heading {
	border-bottom: 0px solid #e5e5e5;
	font-size: 24px;
	letter-spacing: 1px;
	margin: 0px;
	padding: 0px 0px 5px;
	position: relative;
	text-transform: uppercase;
}
.page-title {
    margin-bottom: 20px;
}
.page-title h1, .page-title h2 {
	color: #000;
	font-size: 20px;
	letter-spacing: 1px;
	margin-top: 0;
	padding-bottom: 11px;
	text-align: left;
	text-transform: uppercase;
	margin-bottom: auto;
}
.category-products .text-banner h2 {
	color: #333;
}
.category-products .text-banner h3 {
	color: #000;
}
.category-product {
	margin-top: 25px;
	margin-bottom: 10px;
	position: relative;
}
.category-product .jtv-title.center {
	margin-bottom: 0px;
}
.jtv-box-hover .add-to-links li a {
	height: 38px;
	display: block;
	font-size: 0;
	line-height: 36px;
	text-align: left;
	padding: 0;
	text-transform: uppercase;
	margin: 0;
	background: none;
	font-family: "Raleway", sans-serif;
	letter-spacing: 1px;
	color: #333;
}
.jtv-box-hover .add-to-links li i {
	width: 38px;
	height: 38px;
	float: right;
	background: #fff;
	content: "";
	text-align: center;
	font-size: 15px;
	line-height: 38px;
	font-weight: 300;
	border: 1px #999 solid;
	border-radius: 50%;
}
.jtv-box-hover .add-to-links li i:hover {
	background: #fdae03;
	border: 1px #fdae03 solid;
	color: #fff;
}
.jtv-box-hover {
	transition: all 0.3s ease-out;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
}
.content-page .category-product {
	margin-bottom: 0px;
	margin-top: 0px;
}
.content-page {
	display: inline-block;
	width: 100%;
}
/* products title */
.jtv-title h3 {
	color: #333;
	text-transform: uppercase;
	margin: auto;
	line-height: normal;
}
.jtv-title.center {
	padding: 0 0 0px 0;
	text-align: center;
}
.jtv-title {
	color: #000000;
	margin: 0px;
	text-align: center;
	text-transform: none;
	padding: 25px 0 0px;
	border-bottom: 0px solid #f1f1f1;
}
.cross-sell-pro .jtv-title h2 {
	padding: 8px 20px 8px 0px;
}
.cross-sell-pro {
	margin-top: 25px;
}
/*  category-slider  */

.category-title {
	border-bottom: 1px solid #ddd;
	margin: 0 0 10px;
}
.category-image {
	display: block;
	margin: 30px auto 25px;
	position: relative;
}
.category-products {
	padding-bottom: 5px;
	display: inline-block;
	width: 100%;
}
.category-description a.prev, .category-description a.next {
	margin: 125px 6px 0px 5px;
}
.category-description {
	margin-bottom: 10px;
}
.cat-img-title.cat-box {
	margin: 0;
	text-align: left;
	left: 20px;
	top: 5px;
	padding: 110px 45px 25px;
	position: absolute;
}
#category-desc-slider {
	margin: 0px 0px 25px;
}
.small-tag {
	color: #fff;
	display: inline-block;
	padding: 8px 0px;
	text-transform: uppercase;
	font-size: 12px;
	letter-spacing: 10px;
	font-weight: normal;
	border-bottom: 3px double #eee;
}
.cat-img-title .cat-heading {
	font-size: 36px;
	text-transform: uppercase;
	color: #fff;
	margin-top: 20px
}
.cat-img-title p {
	clear: left;
	font-size: 13px;
	line-height: 1.6em;
	margin-bottom: 1px;
	padding: 0px;
	font-weight: normal;
	color: #fff
}
.cat-img-title.cat-bg p {
	color: #fff;
}
.category-description .slider-items-products .owl-buttons .owl-prev {
	left: 17px;
	position: absolute;
	top: 49%;
	background: none;
}
.category-description .slider-items-products .owl-buttons .owl-next {
	right: 7px;
	position: absolute;
	top: 49%;
	background: none;
	padding: 0px
}
.category-description .slider-items-products .owl-buttons a {
	z-index: 1;
	position: absolute;
	left: 10px;
	top: 0px;
	padding: 5px 3px 10px 0px;
	color: #222;
	font-size: 16px;
	font-weight: 600;
	text-decoration: none;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
	font-size: 0px;
	height: 30px;
	width: 30px;
	text-align: center;
	border-radius: 0px;
	background: #fff;
	opacity: 0.5;
	border: 1px #ddd solid;
	margin-top: -15px;
}
.category-description .slider-items-products .owl-buttons a:hover {
	background: #8ac42f;
	opacity: 1;
	border: 1px #8ac42f solid;
	color: #fff;
}
.category-description .slider-items-products .owl-buttons .owl-prev a:before {
	font-size: 24px;
	padding: 0px 8px;
	line-height: 16px;
}
.category-description .slider-items-products .owl-buttons .owl-next a:before {
	font-size: 24px;
	padding: 0px 8px;
	line-height: 16px;
}
.category-description .owl-item .item {
	margin: auto;
	background: #fff;
}
.category-description .owl-item .item img {
	max-width: 100%;
}
/* Product item */

.products-grid {
	margin: 0;
	list-style: none;
}
ul.products-grid {
	padding: 0;
	margin: 0px;
}
.category-products ul.products-grid li.item {
	text-align: center;
	margin-top: 0px;
	margin-bottom: 20px;
	padding: 0 10px;
}
.category-products ul.products-grid li.item.last {
	text-align: center;
}
.category-products ul.products-grid li.item img {
	width: 100%;
}
.products-grid .item .actions {
	position: absolute;
	bottom: 1px;
	width: 100%;
	bottom: -55px;
	transition: all 300ms ease-in-out 0s;
	-moz-transition: all 300ms ease-in-out 0s;
	-webkit-transition: all 300ms ease-in-out 0s;
	-o-transition: all 300ms ease-in-out 0s;
	z-index: 9;
	overflow: visible;
	opacity: 0;
}
.products-grid .item:hover .actions {
	left: 1px;
	bottom: 21px;
	opacity: 1;
}
.products-grid .item .tooltip {
	width: 100px;
	text-transform: uppercase;
	font-size: 9px;
	letter-spacing: 1px;
}
.products-grid .item .ratings {
	width: 100px;
	transition: all 300ms ease-in-out 0s;
	-moz-transition: all 300ms ease-in-out 0s;
	-webkit-transition: all 300ms ease-in-out 0s;
	-o-transition: all 300ms ease-in-out 0s;
	z-index: 1;
	overflow: visible;
	text-align: center;
	display: inline-block
}
.products-grid .item:hover .ratings {
	bottom: 15px;
}
.products-grid .item .quick-view-btn {
	width: 50px;
	height: 50px;
	background: #fff;
	text-align: center;
	display: inline-block;
	line-height: 50px;
}
.products-grid .item .quick-view-btn a:before {
	content: "\e090";
	font-family: 'Simple-Line-Icons';
	font-size: 14px;
}
.products-grid .item .quick-view-btn span {
	display: none
}
.products-grid .item .add_cart {
	display: inline-block;
}
.products-grid .item .add_cart .btn-cart {
	border: none;
	padding: 0px;
	width: 50px;
	height: 50px;
	background: #8ac42f;
	text-align: center;
	line-height: 50px;
}
.products-grid .item .add_cart .btn-cart:before {
	content: "\e04f";
	font-family: 'Simple-Line-Icons';
	font-size: 14px;
	color: #fff
}
.products-grid .item .add_cart .btn-cart span {
	display: none
}
.products-grid .item {
	margin-top: 20px;
	margin-bottom: 5px;
}
.col-main .category-products .products-grid {
	margin-left: -10px;
	margin-right: -10px;
}
.products-grid .last {
	margin-right: 0;
}
.products-grid .item.show-addtocart {
	margin-top: 37px;
}
.products-grid .item .item-inner {
	position: relative;
	text-align: center;
	overflow: hidden;
	border: 1px #e5e5e5 solid;
	padding-bottom: 18px;
	background: #fff;
}
.products-grid .item .item-inner .action {
	margin-top: 8px;
}
.products-grid .item .item-inner:hover {
	left: 0px;
	top: 0px;
}
.products-grid .item .item-inner .item-img {
	position: relative;
}
.products-grid .item .item-inner .item-img .ico-product {
	position: absolute;
	bottom: 10px;
	left: 10px;
	z-index: 2;
	padding: 4px 20px 8px;
	color: #FFF;
	font-size: 150%;
	-webkit-border-radius: 18px;
	-moz-border-radius: 18px;
	border-radius: 18px;
}
.products-grid .item .item-inner .item-img .item-img-info {
	text-align: center;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	position: relative;
}
.products-grid .item .item-inner .item-img .item-img-info a.product-image {
	width: 100%;
	overflow: hidden;
	display: inline-block;
}
.products-grid .item .item-inner .item-img .item-img-info a.product-image img {
	width: 100%;
}
.item .item-inner .item-img .item-img-info a.product-image img {
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	transform: translateZ(0);
	max-width: 100%;
	width: 100%;
}
.item .item-inner:hover .item-img .item-img-info a.product-image img {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	-moz-transform: scale(1.1);
	opacity: 0.5;
}
.products-grid .item .item-inner .item-img .item-img-info a.product-image .img-main {
	display: inline;
}
.products-grid .item .item-inner .item-img .item-jtv-box-hover {
	z-index: 2;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition: all 0.2s ease-out;
	-o-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
	position: absolute;
	width: 100%;
	top: 25%;
	opacity: 0;
}
.products-grid .item .item-inner .item-img {
	margin: 0px auto 0;
}
.products-grid .item .item-inner .item-info .info-inner {
	border-top: none;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}
.products-grid .item .item-inner .item-info .info-inner .item-content .no-rating, .products-grid .item .item-inner .item-info .info-inner .item-content .ratings {
	margin: 0 0;
}
.products-grid .item .item-inner .item-info .info-inner .item-content .no-rating .rating-box, .products-grid .item .item-inner .item-info .info-inner .item-content .ratings .rating-box {
	float: none;
	margin: 0 0;
}
.products-grid .item .item-inner .item-info .info-inner .item-content .no-rating .rating-links, .products-grid .item .item-inner .item-info .info-inner .item-content .ratings .rating-links {
	display: none;
}
.products-grid .item .item-inner .item-info .info-inner .item-title {
	margin-bottom: 3px;
	padding-top: 10px;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

}
.products-grid .item .item-inner .item-info .info-inner .item-title h6 {
	margin: auto;
	margin-bottom: 4px
}
.products-grid .item .item-inner .item-info .info-inner .item-title h6 a {
	text-decoration: none;
	font-weight: normal;
	letter-spacing: 0.5px;
	color: rgb(255, 255, 255);
}
.products-grid .item .item-inner:hover .item-info .info-inner .item-title h6 a {
	color: #8ac42f;
	cursor: pointer;
}
.products-grid .item:hover .item-inner .item-img .item-img-info {
	border-color: #fff;
	
}
.products-grid .item .item-inner .item-info .info-inner .item-title a:hover {
	color: #8ac42f;
}
.products-grid .item:hover .item-inner .item-img .item-jtv-box-hover {
	visibility: visible;
	opacity: 1;
	left: 0px;
	bottom: 0px;
	
}
.products-grid .item:hover .item-inner .item-info .info-inner .item-title a {
	color: #8ac42f;
}
.products-grid .item:hover .item-inner .item-info .info-inner .action button.button {
	background: #fdae03;
	color: #fff;
	border: 1px #fdae03 solid;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}
/* New & Sale Label */

.new-box, .sale-box {
	height: 40px;
	overflow: hidden;
	position: absolute;
	text-align: center;
	top: -1px;
	width: 55px;
	z-index: 10;
}
.new-box {
	left: -1px;
}
.sale-box {
	right: -1px;
}
.new-top-left {
	left: 0px;
	top: 0;
	background: #fff;
	letter-spacing: 1px;
}
.new-top-right {
	background: none repeat scroll 0 0 #FFFFFF;
	right: 0px;
	top: 0;
}
.sale-top-right {
	top: 0;
	background: #fff;
	letter-spacing: 1px;
	right: 0px;
}
.sale-top-left {
	font-size: 11px;
	color: #fff;
	background: #dd4b39;
	text-transform: uppercase;
	padding: 3px 0 4px;
	width: 102px;
	text-align: center;
	display: block;
	position: absolute;
	right: -33px;
	top: 9px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.ie8 .new-label {
	left: 0px;
	top: 0px;
	width: auto;
	padding: 5px 15px;
}
.ie8 .new-label:after {
	display: none;
}
.ie8 .new-label:before {
	display: none;
}
.ie8 .sale-label {
	right: 0px;
	top: 0px;
	width: auto;
	padding: 5px 15px;
}
.ie8 .sale-label:after {
	display: none;
}
.ie8 .sale-label:before {
	display: none;
}
.ie8 .new-box {
	top: -1px;
	left: -1px;
}
.ie8 .sale-box {
	right: -1px;
	top: -1px;
}
.new-box, .sale-box {
	position: fixed;
	text-align: center;
	top: -1px;
	width: 55px;
	z-index: 10;
}
.new-box {
	left: 0;
}
.sale-box {
	right: 0;
	top: 0px;
}
.new-label {
	font-size: 10px;
	color: #fff;
	background: #ffc000;
	text-transform: uppercase;
	padding: 3px 10px;
	text-align: center;
	display: block;
	position: absolute;
}
.sale-label {
	font-size: 10px;
	color: #fff;
	background: #8ac42f;
	text-transform: uppercase;
	padding: 3px 10px;
	text-align: center;
	display: block;
	position: absolute;
	top: 0px;
}
.hot-label {
	font-size: 10px;
	color: #fff;
	background: #ff4318;
	text-transform: uppercase;
	padding: 3px 10px;
	text-align: center;
	display: block;
	position: absolute;
	top: 0px;
}
.hot-label.hot-top-right {
	right: 0;
	left: auto;
}
/*========== 15. Products List ==========*/
/* Products Item */

.products-list .item {
	padding: 15px;
	overflow: hidden;
	margin: 0px 0px 25px;
	border: 1px solid #e5e5e5;
	background: #fff;
}
.products-list .item a:hover {
	color: #8ac42f;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
}
.products-list .product-name {
	text-align: left;
	margin: 0px 0 10px
}
.products-list .item h4 a {
	text-decoration: none;
	font-size: 16px;
	font-weight: 600;
	color: #333;
}
.products-list .item h4 {
	font-weight: normal;
	color: #353535;
	padding-bottom: 2px;
}
ol.products-list {
	padding: 0;
	margin: 0;
	float: left;
}
li .list {
	border-bottom: none;
}
.products-list .rating-links, .product-view .product-shop .rating-links {
	display: inline-block;
	margin-left: 5px;
	padding: 0px;
	margin-bottom: 0px;
}
.products-list .rating-links a, .product-view .product-shop .rating-links a {
	color: #999;
	font-size: 13px;
}
.products-list .old-price .price {
	font-size: 12px
}
.separator {
	color: #999
}
.products-list .product-image {
	float: left;
	width: 28%;
}
.products-list .product-image img {
	max-width: 100%;
}
.products-list .product-shop {
	float: right;
	width: 69%;
	text-align: left;
	margin-left: 20px;
}
.products-list .product-shop .desc {
	font-size: 13px;
	margin-bottom: 15px;
	margin-top: 5px;
	color: #222;
	letter-spacing: 0.5px;
}
.products-list .product-shop p {
	font-size: 13px;
	color: #222;
	line-height: 1.6em;
	letter-spacing: 0.5px;
}
.products-list .product-shop p.old-price {
	margin-right: 4px;
}
.products-list .product-shop .price-box {
	margin: 0 0 10px;
	padding-top: 0;
}
.products-list .desc .link-learn {
	font-weight: normal;
	margin-top: 5px;
}
.products-list .desc .link-learn:hover {
	text-decoration: underline;
}
.products-list .add-to-links {
	padding-left: 2px;
}
.add-to-links span {
	font-size: 12px;
	margin-left: 8px;
	text-transform: uppercase;
}
.products-list .product-shop button.button {
	font-size: 13px;
	text-transform: uppercase;
	margin-right: 8px;
}
.products-list .product-shop button.button span {
	font-size: 10px;
	padding: 0px;
	text-transform: uppercase;
	letter-spacing: 0.5px
}
.products-list .product-shop .btn-cart {
	border: 1px #d1d1d1 solid;
	background: #fff;
	color: #666;
}
.products-list .product-shop .btn-cart:hover {
	background: #8ac42f;
	color: #fff;
	border: 1px solid #8ac42f;
}
.products-list .product-shop .btn-cart span {
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
}
.link-wishlist, .link-compare {
	color: #666;
	text-shadow: none;
	border: none;
	box-shadow: none;
}
.products-list .actions .add-to-links a.link-wishlist {
	font-size: 10px;
	margin: 0;
	padding: 9px 10px;
	text-decoration: none;
	display: inline-block;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	margin-right: 8px;
	border: 1px #d1d1d1 solid;
	background: #fff;
	color: #666;
	font-weight: 600;
}
.products-list .actions .add-to-links a.link-wishlist span {
	font-size: 10px;
	padding: 0px;
}
.products-list .actions .add-to-links a.link-compare {
	font-size: 10px;
	margin: 0;
	padding: 9px 10px;
	text-decoration: none;
	display: inline-block;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	margin-right: 8px;
	border: 1px #d1d1d1 solid;
	background: #fff;
	color: #666;
	font-weight: 600;
}
.products-list .actions .add-to-links a.link-compare span {
	font-size: 10px;
}
.products-list .actions .add-to-links a.link-wishlist:hover, .products-list .actions .add-to-links a.link-compare:hover {
	background-color: #8ac42f;
	color: #FFFFFF;
	border: 1px #8ac42f solid
}
ul.products-grid {
	padding: 0;
	margin: 0
}
a.button.detail-bnt span {
	font-size: 12px;
	text-transform: uppercase;
	padding: 0 2px;
	margin-left: 6px;
	display: none
}
/*  16. Rating and Price  */
.rating {
	color: rgb(250, 153, 7);
	font-size: 12px;
	height: 10px;
	margin-top: -10px;
	margin-bottom: 5px;
}
.rating .fa-star {
	color: #FC0
}
/* Price */
.price {
	font-size: 14px;
	color: #333;
	white-space: nowrap !important;
}
.price-box {
	margin: 0;
	padding: 8px 0px 0px 0px;
	min-height: 28px;
}
/* Regular price */
.regular-price {
	display: inline
}
.regular-price .price {
	font-size: 16px;
	color: rgb(223, 221, 221);
	font-weight: 600;
}
.block .regular-price, .block .regular-price .price {
	color: #000;
}
.price-box .price-from .price {
	font-size: 13px;
	color: #333;
}
.price-box .price-to .price {
	font-size: 13px;
	color: #333;
}
.price-box .minimal-price .price {
	font-size: 13px;
	color: #333;
}
/* Old price */
.old-price .price-label {
	white-space: nowrap;
	color: #999;
	display: none;
}
.old-price {
	display: inline;
	margin-right: 15px;
}
/* Old price */
.old-price .price-label {
	color: #777777;
	display: none;
	white-space: nowrap;
}
.old-price .price {
	color: #999!important;
	font-size: 13px;
	font-weight: normal;
	text-decoration: line-through;
	margin-left: 6px;
}
.latest-product .old-price .price-label {
	display: none;
}
/* Special price */
.special-price {
	margin: 0;
	padding: 3px 0;
	display: inline;
}
.special-price .price-label {
	color: #666;
	display: none;
	font-size: 13px;
	font-weight: 400;
	white-space: nowrap;
}
.special-price .price {
	font-size: 13px;
	color: #000;
	font-weight: 600;
}
.special-price .price-label {
	font-size: 13px;
	white-space: nowrap;
	color: #666;
	display: none;
}
.latest-product .special-price .price-label {
	display: none;
}
/* Minimal price (as low as) */
.minimal-price {
	margin: 0;
}
.minimal-price .price-label {
	white-space: nowrap;
}
.minimal-price-link {
	display: inline;
}
.minimal-price-link .label {
}
.jtv-box-hover .add-to-links {
	margin: 0;
	list-style: none;
	padding: 0;
}
.jtv-box-hover .add-to-links li {
	margin: 2px;
	display: inline-block;
}
.jtv-box-hover > a {
	background: #fff;
	border-radius: 50%;
	color: #333333;
	display: inline-block;
	font-size: 18px;
	height: 35px;
	line-height: 35px;
	margin: 0 4px;
	width: 35px;
}
.jtv-box-hover {
	left: 50%;
	margin-left: -63px;
	margin-top: -17.5px;
	position: absolute;
	top: 50%;
	opacity: 0;
	transform: scale(2);
	transition: all 0.4s ease 0s;
}
.jtv-box-hover > a:hover {
	background: #fe5858 none repeat scroll 0 0;
	color: #fff;
}
.item .item-inner:hover .jtv-box-hover {
	opacity: 1;
	transform: scale(1);
}
.image-hover2 a {
	position: relative;
	display: table;
}
.image-hover2 a:after {
	overflow: hidden;
	position: absolute;
	top: 0;
	content: "";
	z-index: 100;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	pointer-events: none;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	background-color: rgba(0, 0, 0, 0.3);
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
	z-index: 1;
}
.image-hover2 a:before {
	font: normal normal normal 18px/1 FontAwesome;
	content: "\f002";
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;
	color: #fff;
	ms-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	background: #8ac42f;
	padding: 8px 12px;
	ms-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	opacity: 0;
	-webkit-transition: opacity 0.3s ease 0s;
	-o-transition: opacity 0.3s ease 0s;
	transition: opacity 0.3s ease 0s;
}
.image-hover2 a:hover:after {
	visibility: visible;
	opacity: 0.8;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}
.image-hover2 a:hover:before {
	opacity: 1;
}
.blog-outer-container .entry-thumb img {
	width: 100%;
}
.blog-outer-container .entry-thumb {
	position: relative;
}
/*---------	17. Breadcrumbs --------- */
.breadcrumbs {
	padding: 0px;
	margin: 15px auto 5px;
	font-size: 12px;
	color: #fff;
	text-align: left;
	z-index: 10;
	position: relative
}
.breadcrumbs a {
	color: #666;
	font-size: 12px;
	font-weight: normal;
}
.breadcrumbs span {
	display: inline-block;
	margin: 0 5px 0 5px;
	color: #ccc;
	font-size: 11px;
}
.breadcrumbs ul {
	margin: 0px;
	padding: 0px;
}
.breadcrumbs ul li {
	list-style-type: none;
	display: inline;
}
.breadcrumbs strong {
	font-weight: normal;
	color: #8ac42f;
}
figure.effect img {
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;
}
figure.effect:hover img {
	opacity: 0.9;
}
figure.effect figcaption::before {
	-webkit-transform: scale(0, 1);
	transform: scale(0, 1);
}
figure.effect figcaption::after {
	-webkit-transform: scale(1, 0);
	transform: scale(1, 0);
}
figure.effect h2 {
	padding-top: 30%;
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: translate3d(0, -20px, 0);
	transform: translate3d(0, -20px, 0);
}
figure.effect p {
	padding: 20px 2.5em;
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(0, 20px, 0);
	transform: translate3d(0, 20px, 0);
}
.grid figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}
.grid figure figcaption, .grid figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.shop_header_image_background {
	width: 100%;
	height: 100%;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	position: absolute;
	top: 0;
	left: 0;
	background-image: url(../images/header-shop-men.jpg);
	background-position-x: 50%;
}
.shop_header.has_big_img {
	padding-top: 80px;
	padding-bottom: 80px;
	overflow: hidden;
}
.shop_header {
	text-align: left;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	border-bottom: 1px solid #f0f0f0;
}
.shop_header .page-title {
	z-index: 10;
	position: relative
}
.cms-bg-overlay {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0,0,0,0.3) url(../images/category-img-overlay.png) repeat top left;
}
/*------- 18. Sidebar ---------*/

.block {
	margin: 0 0 25px;
	border: 1px solid #e5e5e5;
	background: #fff;
}
.block .block-title {
	border-bottom: 1px solid #e5e5e5;
	color: #000;
	letter-spacing: 1px;
	line-height: normal;
	margin: 0;
	padding: 15px 15px 12px;
	font-weight: 600;
	text-transform: uppercase;
}
.block .block-title h3 {
	margin: auto;
	font-size: 20px
}
.block .block-title strong {
}
.block .block-title a {
	text-decoration: none;
}
.block .block-content .item {
	padding: 5px 0;
}
.block .btn-remove, .block .btn-edit {
	float: right;
	margin-top: 6px
}
.sidebar .block .access .btn-remove {
	float: right;
	margin-top: -38px
}
.block .actions {
	text-align: left;
	padding: 8px 0;
}
.block .actions a {
	float: right;
	margin-left: 10px;
	margin-bottom: 10px;
}
.block .empty {
	color: #666666;
}
/*18.a product price range*/

.block.product-price-range .block-content {
	padding: 28px 15px 10px
}
.product-price-range .amount-range-price {
	padding: 15px 0
}
.product-price-range .slider-range-price {
	height: 7px;
	background: #666;
	border: none;
	border-radius: 2px
}
.product-price-range .slider-range-price .ui-widget-header {
	background: #666;
	height: 7px
}
.product-price-range .slider-range-price .ui-slider-handle {
	border: 1px #8ac42f solid;
	border-radius: 3px;
	background: #8ac42f;
	cursor: pointer
}
.check-box-list {
	overflow: hidden
}
.check-box-list li {
	line-height: 24px
}
.check-box-list label {
	display: inline-block;
	cursor: pointer;
	line-height: 12px;
	font-weight: 600;
}
.check-box-list label:hover {
	color: #00b9f5
}
.check-box-list input[type="checkbox"] {
	display: none
}
.check-box-list input[type="checkbox"]+label span.button {
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-right: 6px;
	background: url("../images/checkbox.png") no-repeat;
	padding: 0;
	border: none
}
.check-box-list input[type="checkbox"]:checked+label span.button {
	background: #00b9f5 url("../images/checked.png") no-repeat center center
}
.check-box-list input[type="checkbox"]:checked+label {
	color: #00b9f5
}
.check-box-list label span.count {
	color: #a4a4a4;
	margin-left: 2px
}
.sidebar-left {
	margin-left: 0 !important;
}
.bv3 .sidebar-right, .bv3 .sidebar-left {
	padding-left: 15px;
}
.sidebar ol, .sidebar ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.sidebar .block-venedor-ads .block-content li {
	padding: 0;
}
.sidebar .block dt {
	font-size: 12px;
	font-weight: 600;
	color: #333;
	padding: 10px 0;
	padding-bottom: 0px!important
}
.sidebar .block dd {
	border-bottom: 0 solid #CCCCCC;
	margin: 0;
	padding: 0 5px;
	margin-bottom: 15px
}
.block-layered-nav dd.last {
	margin-bottom: 0px;
}
.sidebar address {
	margin: 0;
	padding: 0;
	color: #333;
	text-align: left;
	border-bottom: none;
	line-height: 24px;
}
.sidebar {
	font-size: 14px;
}
.sidebar .block-layered-nav .price {
	font-size: 13px;
	font-weight: normal;
}
.block-layered-nav .block-subtitle, .block-layered-nav dt {
	color: #333;
	font-weight: 600;
	font-size: 12px;
}
.block-layered-nav .currently ol {
	border: 1px solid #ececec;
	background-color: #f4f4f4;
	border-radius: 3px;
}
.sidebar a, .sidebar .block-layered-nav .price .sub {
	color: #222;
	font-size: 13px;
}
.sidebar .block-layered-nav .price {
	color: #666;
}
.sidebar .link-cart, .sidebar .link-reorder, .block .actions a {
	color: #CA0000;
	font-size: 13px;
}
.sidebar .link-compare, .sidebar .link-wishlist {
	color: #aaa;
	font-size: 13px;
}
.block-tags .actions a.view-all {
	text-transform: uppercase;
	color: #333;
	margin-bottom: auto;
	font-size: 12px;
}
.block-tags .actions a.view-all:hover {
	color: #333;
}
.sidebar .link-cart:hover, .sidebar .link-wishlist:hover, .sidebar .link-reorder:hover, .sidebar .link-compare:hover, .sidebar .link-cart:focus, .sidebar .link-wishlist:focus, .sidebar .link-reorder:focus, .sidebar .link-compare:focus, .block .actions a:hover, .block .actions a:focus {
	color: #333;
}
.sidebar .block dl {
	border-bottom: 0px solid #ccc;
	margin: 0;
}
.block .block-content .price {
	font-size: 12px;
	color: #000;
}
/* 18b. Block: Account */
.block-account .block-content {
	padding-top: 0 !important;
}
.block-account .block-content ul {
	margin-top: 5px;
	margin-bottom: 5px;
}
.block-account .block-content li {
	padding: 10px 0px;
	border-top: 1px #fff solid;
	border-bottom: 1px #ddd solid;
}
.block-account .block-content li:first-child {
	border-top: none;
}
.block-account .block-content li:before {
	content: "\f105";
	font-family: FontAwesome;
	font-size: 10px;
	display: inline-block!important;
	position: absolute;
	cursor: pointer;
	line-height: 16px;
	color: #333;
}
.sidebar .block-content li.last {
	border-bottom: none;
}
.block-account .block-content li a {
	cursor: pointer;
	padding: 0 12px;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}
.block-account .block-content li a:hover {
	cursor: pointer;
	padding: 0 12px;
	color: #000;
}
.sidebar .block-content li.current {
	color: #333;
}
/* Block: Currency Switcher */

.block-currency select {
	width: 100%;
	margin-right: 8px;
}
/*18d.  Block: Layered Navigation */
.block-layered-nav {
	background-color: #FFFFFF;
}
.block.block-layered-nav .block-content {
	padding: 15px 15px 0px
}
.block-layered-nav .block-content {
	padding: 0;
}
.block-layered-nav dt {
	overflow: hidden;
	padding: 0px 0 10px !important;
	position: relative;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.block-layered-nav dd {
	margin: 0;
	padding: 0;
}
.block-layered-nav dd ol {
	position: relative;
}
.block-layered-nav dd ol li:before {
	content: '\f105';
	font-family: FontAwesome;
	font-size: 14px;
	padding-right: 6px;
	color: #444;
}
.block-layered-nav dd ol.bag-material li:before {
	content: '';
	padding-right: 0px;
}
.block-layered-nav dd ol.bag-material input[type=checkbox] {
	margin: 5px 5px 0 -10px;
	margin-top: 1px\9;
	line-height: normal;
}
.block-layered-nav .btn-remove {
	float: right;
}
.block-list .price {
	color: inherit;
	font-size: 14px;
}
/*18e. Block: Cart */
.block-cart {
	padding: 0px;
	overflow: hidden;
}
.block-cart .amount {
	margin-bottom: 5px
}
.block-cart .actions .paypal-logo {
	float: left;
	width: 100%;
	margin: 3px 0 0;
	text-align: right;
}
.block-cart .actions .paypal-logo .paypal-or {
	clear: both;
	display: block;
	padding: 0 55px 5px 0;
}
.block-cart .mini-products-list .product-details .product-name, .block-cart .mini-products-list .product-details .nobr small {
	word-wrap: break-word;
}
.block-cart .mini-products-list .product-details .nobr {
	white-space: normal !important;
}
.block-cart .summary {
	margin: 0;
	padding: 0;
	position: relative;
	z-index: 1;
}
.block-cart .subtotal {
	margin: 0;
	padding: 0;
	text-align: left;
	margin-bottom: 8px
}
.block-cart .block-content .summary .subtotal .label {
	background: none repeat scroll 0 0 transparent;
	border-radius: 0 0 0 0;
	box-shadow: none;
	font-weight: normal;
	padding: 2px 0;
	text-shadow: none;
	color: #000;
	font-size: 100%;
}
.block-cart .subtotal .price {
	color: #000;
	font-size: 13px;
}
.ajax-checkout {
	border: 0 none;
	margin: 0;
	padding: 5px 0 0px;
	text-align: left;
	margin-bottom: 0px;
}
.ajax-checkout a {
	border: 0px #dc436b solid;
	background: #323C46;
	text-shadow: 0 1px 0 #333;
	border-radius: 2px;
	color: #FFFFFF;
	font-size: 11px;
	padding: 8px 12px;
	text-transform: uppercase;
}
.ajax-checkout a:hover {
	border: 0px #111 solid;
	background: #222;
	padding: 6px 10px;
	text-shadow: 0 1px 0 #000;
	border-radius: 2px;
	color: #FFFFFF;
	font-size: 11px;
	cursor: pointer;
}
.sidebar .block-cart .block-subtitle {
	border-bottom: 1px solid #eee;
	padding-bottom: 6px;
	margin: 8px 0 8px;
	font-size: 12px;
	color: #333;
}
.block-cart .block-content ul li .product-details {
	float: right;
	width: 61%;
}
.block-cart .block-content ul li .product-details .access {
	float: right
}
.block-cart .block-content ul li .product-details .access .jtv-btn-remove {
	position: inherit;
}
.block.block-cart ul li.item {
	border-bottom: 1px solid #ECECEC;
	margin-bottom: 10px;
	padding-bottom: 12px;
}
.block.block-cart ul li:last-child.item {
	border-bottom: none;
	margin-bottom: 5px;
	padding-bottom: 0px;
}
.block-cart ul li.item img {
	width: 80px;
}
.block-cart .block-content ul li a.product-image {
	display: inline;
}
.block-cart .block-content ul li a.product-image img {
	border: 1px solid #ECECEC;
}
.block-cart .block-content ul li .product-name {
	margin: 0 0 2px;
	font-size: 12px;
	line-height: 17px;
	margin-top: 5px;
}
.block-cart .block-content ul li .product-name a {
	font-size: 13px;
	letter-spacing: 0.5px;
}
.col-right .block-cart .block-content ul li .product-details {
	float: right;
	width: 172px;
}
.col-right .block-cart ul li.item {
	border-bottom: 1px solid #ECECEC;
	margin-bottom: 5px;
	padding-bottom: 8px;
}
.col-right .block-cart ul li.item.last {
	border-bottom: none;
	margin-bottom: 5px;
	padding-bottom: 0px;
}
.col-right .block-cart .block-content ul li a.product-image {
	display: inline;
}
.col-right .block-cart .block-content ul li a.product-image img {
	border: 1px #ddd solid;
}
.col-right .block-cart .block-content ul li .product-name {
	margin: 0 0 2px;
	font-size: 14px;
}
.breadcrumbs li strong {
	color: #000;
}
/*18f. Block: Related */
.related-slider {
	float: right;
	margin: auto;
	margin-bottom: 15px;
}
.related-block1 .block-title {
	background: none repeat scroll 0 0 #fff;
	border-bottom: 1px solid #e5e5e5;
	color: #000000;
	font-size: 14px;
	margin: 0;
	padding: 10px;
	text-align: left;
	text-transform: uppercase;
}
.related-block1 {
	border: 1px solid #e5e5e5;
	margin-bottom: 20px;
	margin-top: 25px;
}
.related-slider .slider-items-products .owl-buttons a {
	height: 41px;
	margin: -32px 0 0;
	top: 50%;
	width: 40px;
}
.related-slider .slider-items-products .owl-buttons .owl-next a:before {
	font-size: 24px;
	line-height: 38px;
}
.related-slider .slider-items-products .owl-buttons .owl-prev a:before {
	font-size: 24px;
	line-height: 38px;
}
.related-slider .slider-items-products .owl-buttons .owl-prev a {
	left: -8px;
}
.related-slider .slider-items-products .owl-buttons .owl-next a {
	left: -1px
}
.related-slider button.button.btn-cart:before {
	content: "\f07a";
	font-family: 'FontAwesome';
}
.related-slider .products-grid .item .item-inner .item-img .item-img-info {
	border: none;

}
.related-slider h2 {
	color: #333;
	font-size: 20px;
	margin: 0px 0 20px;
	text-align: left;
	text-shadow: 0 1px 0 #fff;
}
.block-related .block-content {
	padding: 0px 0 10px 0;
	border: 1px solid #ECECEC;
	border-top: none;
	overflow: hidden;
}
.block-related ul {
	margin: 0;
	padding: 0;
}
.block-related li {
	padding: 5px 0;
}
.block-related input.checkbox {
	position: absolute;
	left: 85px;
	top: 12px;
	z-index: 10;
}
.block-related .product {
	margin-left: 20px;
}
.block-related .product .product-image {
	float: left;
	margin-right: -65px;
}
.block-related .product .product-details {
	margin-left: 65px;
}
.block-related .mini-products-list .product-details {
	margin-left: 125px;
	text-align: left;
}
.block-related .mini-products-list .product-details .product-name {
	text-align: left;
}
.block-related .mini-products-list .product-image {
	width: 100px;
	margin: 0;
	padding: 0 10px;
}
.block-related .block-subtitle {
	padding: 10px 10px 0 10px;
	font-size: 13px;
	margin: 0;
}
.block-related .price-box .old-price .price {
	display: inline;
	font-size: 15px;
}
.block-related a.link-cart {
	text-transform: uppercase;
	font-size: 14px;
	color: #23AEAC;
}
.block-related a.link-cart:hover {
	text-transform: uppercase;
	font-size: 14px;
	color: #CA0000;
}
/*18g. Block: Compare Products */
.block-compare {
	background-color: #fff;
	padding: 0px;
}
.block-compare li {
	padding: 5px 0;
}
.block-compare .block-content ol, .block-compare .block-content ul {
	margin: 0;
}
ol#compare-items li a.product-name {
	font-size: 13px;
	padding: 0px;
	margin: 0;
	font-weight: normal;
	color: #222;
	line-height: 20px;
	text-transform: none;
	display: inline-block;
	width: 200px;
	letter-spacing: 0.5px;
}
ol#compare-items li a.product-name span {
	margin-right: 3px;
	font-size: 14px;
	font-weight: 400;
	color: #777;
	line-height: 15px;
	margin-bottom: 6px;
}
ol#compare-items li {
	margin: 0;
	padding: 0 0 6px;
	position: relative;
	margin-bottom: 6px;
	background: none;
}
ol#compare-items li a i {
	font-size: 14px;
	padding-right: 6px;
	color: #444;
	display: inline-block;
	vertical-align: baseline;
}
ol#compare-items li.last {
	border-bottom: none;
	margin-bottom: 0;
}
/*18h. Block: Recently Viewed */
.block-viewed .block-content {
	background-color: #fff;
	padding: 0px;
}
.block-viewed li {
	padding: 3px 0px !important;
	list-style: none;
}
.block-viewed li.last {
	padding: 0px;
	border-bottom: 0px #eaeaea solid;
	list-style: none;
}
.block-viewed .block-content li a {
	padding: 0 15px;
	color: #666;
}
.block-viewed .block-content li a:hover {
	font-size: 13px;
	color: #000;
}
.block-viewed li span {
	color: #777777;
	float: left;
	font-size: 20px;
	font-weight: normal;
	line-height: 15px;
	margin-bottom: 8px;
	margin-right: 3px;
}
.block-viewed li a i {
	font-size: 13px;
	padding-right: 6px;
	color: #444;
	vertical-align: top;
}
.block-compared li a i {
	font-size: 13px;
	padding-right: 6px;
	color: #444;
	vertical-align: top;
}
.sidebar ol, .sidebar ul {
	list-style: none;
	padding: 0;
	margin: 0;
	padding: 0
}
#poll-answers {
	margin-bottom: 10px
}
#recently-viewed-items .product-name {
	text-align: left;
	margin: 0;
	text-transform: none;
	width: 212px;
	display: inline-block
}
#recently-viewed-items .product-name a {
	color: #222;
	font-size: 13px;
	font-weight: normal;
	text-decoration: none;
	padding: 0 0px;
	letter-spacing: 0.5px;
}
#recently-viewed-items .product-name a:hover {
	color: #000;
	font-size: 13px;
	font-weight: normal;
	text-decoration: none;
}
/*18i. Block: Recently Compared */
.block-compared .block-content {
	background-color: #fff;
	padding: 5px 12px 12px;
}
.block-compared li {
	padding: 3px 0px !important;
	list-style: none;
}
.block-compared li.last {
	padding: 0px;
	border-bottom: 0px #eaeaea solid;
	list-style: none;
}
.block-compared .block-content li a {
	padding: 0 15px;
	color: #666;
}
.block-compared .block-content li a:hover {
	font-size: 13px;
	color: #000;
}
.block-compared li span {
	color: #777777;
	float: left;
	font-size: 20px;
	font-weight: normal;
	line-height: 15px;
	margin-bottom: 8px;
	margin-right: 3px;
}
.block-compared li:before {
	content: '\f105';
	font-family: FontAwesome;
	font-size: 14px;
	padding-right: 6px;
	color: #444;
	vertical-align: top;
}
#recently-compared-items .product-name {
	text-align: left;
	margin: 0;
	text-transform: none;
	width: 225px;
	display: inline-block
}
#recently-compared-items .product-name a {
	color: #333;
	font-size: 14px;
	font-weight: normal;
	text-decoration: none;
	padding: 0 0px;
}
#recently-compared-items .product-name a:hover {
	color: #000;
	font-size: 14px;
	font-weight: normal;
	text-decoration: none;
}
/*18g. Block: Poll */
.block-poll {
	background-color: #fff;
	padding: 0px;
}
.block-poll label {
	margin-bottom: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 13px;
	color: #222;
	font-weight: normal;
	letter-spacing: 0.5px;
}
.block-poll .block-subtitle {
	color: #000;
	font-size: 13px;
}
.block-poll input.radio {
	float: left;
	margin: 1px -18px 0 0;
}
.block-poll .label {
	display: block;
	margin-left: 18px;
	text-align: left;
	padding: 0.1em 0.3em 0.1em;
	line-height: 14px;
}
.block-poll li {
	padding: 3px 0px !important;
	background: none !important;
}
.block-poll .actions {
	margin: 5px 0 0 0px;
	padding: 0;
}
.block-poll .answer {
}
.block-poll .votes {
	float: right;
	margin-left: 10px;
}
/*18k. Block: Tags */
.block-tags {
	background-color: #fff;
	padding: 0px;
}
.block-tags .block-content {
	overflow: hidden;
}
.tags-list a {
	background-color: #f0f0f0;
	padding: 2px 6px;
	margin-bottom: 4px;
	display: inline-block;
	font-size: 12px;
}
.tags-list a:hover {
	background-color: #8ac42f;
	color: #fff;
}
.block-tags .actions {
	border: 0 none;
	float: right !important;
	margin-top: 8px;
	padding: 0px;
	text-align: left;
}
/*18l. Block: Subscribe */
.block-subscribe {
	background-color: #fff;
	padding: 0px;
}
.block-subscribe .input-text {
	width: 90%;
	background: #f7f7f7;
	border: 1px solid #f7f7f7;
	color: #AAAAAA;
	padding: 10px 7px;
	font-size: 13px;
}
.block-subscribe .input-text:focus {
	background: none repeat scroll 0 0 #FFFFFF;
	border: 1px solid #DDDDDD;
	color: #333333;
	font-size: 13px;
	outline: medium none;
	padding: 10px 7px;
	vertical-align: middle;
}
.form-subscribe-header {
	font-size: 14px;
	margin-bottom: 5px;
	color: #222;
}
.static-contain {
	margin-top: 10px;
}
.static-contain .required {
	color: #F00;
}
.static-contain ul {
	padding: 0px;
	list-style: none;
}
.group-select li .input-box {
	float: left;
	width: 45%;
}
.group-select li input.input-text, .group-select li textarea {
	background: none repeat scroll 0 0 #f7f7f7;
	border: 1px solid #f0f0f0;
	outline: medium none;
	padding: 8px 10px;
	width: 88%;
}
.group-select li {
	list-style: outside none none;
	margin: 0 0 5px;
	overflow: hidden;
}
/*18m Block: Recently Viewed */

.block-company .block-content {
	padding-top: 2px !important;
}
.block-company ol {
	margin: 5px 0 0;
}
.block-company li {
	padding: 10px 0px !important;
	list-style: none;
	border-bottom: 1px #ddd solid;
	border-top: 1px #fff solid;
}
.block-company li:first-child {
	border-top: none
}
.block-company li a {
	padding: 0 5px;
	text-decoration: none;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}
.block-company li a:hover {
	padding: 0 5px;
	color: #000;
}
.block-company li:before {
	content: '\f105';
	font-family: FontAwesome;
	font-size: 14px;
	padding-right: 6px;
	color: #444;
	display: inline-block;
	vertical-align: top;
}
.block-company li.last {
	padding: 10px 0px !important;
	border-bottom: 0px #eaeaea solid;
	list-style: none;
}
.block-company li span {
	float: left;
	margin-right: 5px;
	font-size: 16px;
	color: #aaa;
	line-height: 16px;
}
.block-company li strong {
	color: #333;
	padding: 0 5px;
}
/*18n. Block: Reorder */
.block-reorder li {
	padding: 5px 0;
}
.block-reorder input.checkbox {
	float: left;
	margin: 3px -20px 0 0;
}
.block-reorder .product-name {
	margin-left: 20px;
}
/*18m. Block: Banner */

.block-banner .block-content {
	text-align: center;
}
.block-banner a img {
	transition: all 0.4s ease-out 0.2s;
}
.block-banner a img:hover {
	opacity: 0.5;
	-webkit-transition: all 0.4s ease-out 0.2s;
	-moz-transition: all 0.4s ease-out 0.2s;
	-o-transition: all 0.4s ease-out 0.2s;
	transition: all 0.4s ease-out 0.2s;
	background-color: #eee;
}
#carousel-example-generic .carousel-caption h3 a {
	font-size: 32px;
	color: #fff;
	text-shadow: none;
}
.jtv-add-banner {
	margin-bottom: 35px;
}
.jtv-add-banner img {
	max-width: 100%;
}
/*19.  custom-slider  */
.custom-slider {
	margin-bottom: 35px;
	border: 1px #e5e5e5 solid;
	background: #fff;
	padding: 5px
}
.custom-slider h3 a {
	text-shadow: none;
	color: #fff;
	font-size: 36px;
	text-transform: uppercase
}
.custom-slider .carousel-caption {
	text-shadow: none;
	padding: 0px 15px!important
}
#carousel-example-generic {
	background: #fff!important;
}
#carousel-example-generic .carousel-control.left {
	background: none
}
#carousel-example-generic .carousel-control.right {
	background: none
}
#carousel-example-generic .carousel-inner {
	margin: 0px
}
#carousel-example-generic .carousel-caption {
	width: 100%;
	left: 0;
	right: 0;
	padding: 0;
	top: 20%
}
.custom-slider .carousel-indicators {
	width: auto;
	margin-left: -20px
}
.custom-slider .carousel-control {
	display: none
}
.custom-slider img {
	border-radius: 0px
}
.custom-slider .link {
	border: 1px #fff solid;
	display: inline-block;
	margin-top: 20px;
	color: #fff;
	font-size: 12px;
	text-transform: uppercase;
	padding: 5px 10px
}
/*  20.  Product Sorting Toolbar  */
.toolbar {
	padding: 8px 0px 20px;
	line-height: 25px;
}
.toolbar .form-control {
	padding: 2px 12px;
	height: auto;
	margin: auto;
	border-radius: 0px;
}
#list-view {
	padding: 4px 10px 2px;
	background-color: #fff;
	border: 1px #e5e5e5 solid;
	font-size: 16px;
	border-radius: 0px;
	line-height: initial;
	margin-left: 6px;
}
#grid-view {
	padding: 4px 10px 2px;
	background-color: #fff;
	border: 1px #e5e5e5 solid;
	font-size: 16px;
	border-radius: 0px;
	line-height: initial;
}
#grid-view a, #list-view a {
	color: #666;
}
#grid-view.active a, #list-view.active a {
	color: #8ac42f;
}
#grid-view.active, #list-view.active {
	box-shadow: none;
}
#list-view:hover, #grid-view:hover {
	color: #000;
}
a#compare-total {
	margin-left: 10px;
	color: #333;
}
a#compare-total:hover {
	color: #8ac42f;
}
.product-list.row, .products-category.row {
	margin: 0;
}
.sort-by, .show-limit {
	padding-right: 0px;
}
.sort-by label, .show-limit label {
	vertical-align: -2px;
}
.pagination>li:first-child>a, .pagination>li:first-child>span, .pagination>li:last-child>a, .pagination>li:last-child>span {
	margin-left: 0;
	border-radius: 0px;
}
.pagination li a, .pagination li span {
	display: inline-block;
	font-size: 12px;
	line-height: initial;
	padding: 5px 15px;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
	z-index: 3;
	color: #fff;
	cursor: default;
	background-color: #8ac42f;
	border-color: #8ac42f;
}
.pagination li a:hover {
	background-color: #666;
	border-color: #666;
	color: #fff;
	text-decoration: none;
}
/*  21. Product View  */
/* 21a. Product Images */
.product-view .new-label {
	display: none;
}
.product-view .sale-label {
	display: none;
}
.product-view {
	margin: 0 0px 20px;
	overflow: hidden;
	padding: 0px 0px;
	position: relative;
	z-index: 0;
}
.product-view .flexslider-thumb {
	margin: 20px auto 0;
	padding: 0 42px;
	position: relative
}
.product-view-compact .flexslider-thumb {
	margin: 17px auto 0
}
.product-view .flexslider-thumb .flex-viewport {
	z-index: 10
}
.product-view .flexslider-thumb .flex-direction-nav {
	top: 30%;
	left: 0;
	width: 100%;
}
.product-view .flexslider-thumb .flex-prev {
	position: absolute;
	left: 10px;
	top: -5px;
}
.product-view .flexslider-thumb .flex-next {
	position: absolute;
	right: 10px;
	top: -5px;
	color: #666;
}
.product-view .flexslider-thumb li img {
	cursor: pointer;
	-moz-user-select: none;
	border: 1px #ddd solid;
}
.product-view .previews-list {
	padding: 0;
	list-style-type: none;
	text-align: center
}
.product-view .previews-list li {
	width: 85px;
	margin-right: 15px;
	padding: 0;
	float: none;
	display: inline-block;
}
.product-view-compact .rating {
	padding: 18px 0 21px
}
.product-view-compact .btn-toolbar {
	margin: 0;
	padding: 7px 0 22px
}
.product-view-compact .price {
	display: block;
	padding: 6px 0 20px
}
.product-view .product-img-box .product-image .product-flexslider {
	margin: 20px 2px 0px 0px;
}
.product-view .product-img-box .product-image .product-full img {
	width: 100%;
}
.product-view .product-img-box .product-image .owl-next {
	right: -26px;
	top: 45%;
}
.product-view .product-img-box .product-image .owl-next a, .product-view .product-img-box .product-image .owl-prev a {
	border: none;
	background: none;
}
.more-views .slider-items-products .owl-buttons .owl-next a:before, .more-views .slider-items-products .owl-buttons .owl-prev a:before {
	font-size: 24px;
}
.product-view .product-img-box .product-image .owl-prev {
	left: 26px;
	right: auto;
	top: 45%;
}
.product-view .product-img-box .product-image .more-views-items {
	margin: 0px 10px;
}
.product-view .flexslider-thumb-vertical-outer {
	margin: 0 10px 15px 0;
	width: 76px;
	float: left;
	position: relative;
	z-index: 1
}
.product-view .flexslider-thumb-vertical .flex-viewport {
	height: 300px!important
}
.product-view .flexslider-thumb-vertical .slides {
	margin: -4px 0 0;
	padding: 0;
	list-style-type: none
}
.product-view .flexslider-thumb-vertical .slides li {
	margin: 0;
	padding: 0;
	overflow: hidden
}
.product-view .flexslider-thumb-vertical .slides li img {
	width: 100%;
	padding: 4px 0;
	cursor: pointer;
	-moz-user-select: none
}
.flex-direction-nav, .slider-controls {
	-webkit-transform: translate3d(0, 0, 0)
}
.product-essential {
	background-color: #FFFFFF;
}
.product-collateral .box-collateral {
	margin: 0 0 15px;
}
.product-essential .product-image.no-gallery {
	margin-left: auto;
}
.product-essential .product-image img {
	height: auto;
	width: 100%;
	border: 1px solid #e5e5e5;
	padding: 1px;
}
.product-essential .button-viewall {
	position: absolute;
	left: 5px;
	bottom: 5px;
	text-indent: -999em;
	z-index: 9999;
}
/* 21b. Product Shop */
.product-view .product-shop .add-to-box button.button.btn-cart span {
	background: none;
	margin-left: 5px;
	font-size: 14px;
}
.product-view .product-options-bottom button.button.btn-cart span {
	background: none;
	margin-left: 5px;
}
.product-view .product-shop {
	margin-top: 0;
}
.product-additional {
	display: inline-block;
}
.product-additional .block-product-additional {
	margin: 0px;
	background: #fff;
	border: 1px #d1d1d1 solid;
	text-align: center;
	padding-bottom: 15px;
}
.product-additional .block-product-additional p {
	margin: 12px 0;
	line-height: 20px;
	text-align: center;
	padding: 0 10px;
	font-size: 13px;
}
.product-additional .block .block-title {
	border-bottom: 2px solid #333;
	color: #000000;
	font-size: 14px;
	margin: 0;
	padding: 13px;
	text-transform: uppercase;
	text-align: center;
	margin: 0px;
	background: #f7f7f7;
}
.product-additional .block-product-additional .block-content {
	padding: 18px 12px 10px;
	text-align: center;
	line-height: 20px;
}
.block .block-content {
	border-top: medium none;
	font-size: 13px;
	overflow: hidden;
	padding: 15px;
}
.block-progress dt.complete {
	margin: 0;
}
.product-view .product-name {
	border-bottom: 1px solid #e5e5e5;
	margin-bottom: 15px;
}
.product-view .product-name h1 {
	font-size: 32px;
	margin: 0 0 2px;
	text-align: left;
	line-height: 1;
	padding-bottom: 12px;
	text-transform: uppercase;
	padding-right: 55px;
	letter-spacing: 1px;
}
.product-view .short-description {
	font-size: 13px;
	padding: 15px 0px 15px 0px;
	border-top: 1px solid #eaeaea;
	border-bottom: 1px solid #eaeaea;
	margin-bottom: 12px;
}
.product-view .rating .separator {
	color: #999;
	margin: 0px 5px;
}
.product-view .rating .fa-star {
	color: #ff9900;
	font-size: 15px;
}
.product-view .rating .fa-star-o {
	font-size: 15px;
}
.product-view .rating .rating i {
	font-size: 14px;
}
.product-view .form-option .form-option-title {
	font-weight: bold;
	line-height: 20px;
}
.product-view .form-option .attributes .attribute-label {
	width: 50px;
	float: left;
}
.product-view .form-option .list-color {
	overflow: hidden;
	padding-top: 5px;
	padding: 0px;
}
.product-view .form-option .list-color li {
	width: 20px;
	height: 20px;
	border: 1px solid #eaeaea;
	float: left;
	margin-right: 10px;
	list-style: none;
}
.product-view .form-option .list-color li a {
	width: 20px;
	height: 20px;
	float: left;
	text-indent: -9999px;
}
.product-view .form-option .list-color li:hover, .product-view .form-option .list-color li.active {
	border: 1px solid #8ac42f;
}
.product-view .form-share {
	margin-top: 15px;
}
.product-view .form-share .sendtofriend-print a {
	margin-right: 15px;
	margin-bottom: 15px;
	color: #666;
}
.product-view .form-share a:hover {
	color: #8ac42f;
}
.product-view .short-description h2 {
	font-size: 14px;
	color: #333;
	margin: 0px;
	margin-bottom: 8px;
	letter-spacing: 0.5px;
	font-weight: 600;
	display: none
}
.product-view .short-description p {
	font-size: 13px;
	margin: 0px;
	color: #222;
	letter-spacing: 0.5px;
	line-height: 1.5
}
.product-view .product-shop .add-to-box {
	padding: 10px 0px 15px;
	overflow: hidden;
	text-align: left;
	margin: 1px 0 0 0;
	border-bottom: 1px dotted #ddd;
}
.product-view .product-shop .add-to-box .btn-cart {
	font-size: 16px;
	text-shadow: none;
	padding: 7px 20px;
	float: left;
	margin-top: 0px;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
	margin-left: 12px;
	border: none;
	height: 40px;
	text-transform: uppercase;
}
.product-view .product-shop .add-to-box .btn-cart:hover {
	text-shadow: none;
}
.product-view .product-shop .add-to-box .icon-basket {
	margin-right: 5px;
}
.email-addto-box {
	display: inline-block;
	margin-left: 5px;
}
.product-view .email-friend a span {
	font-size: 12px;
	display: none
}
.product-view .promo-banner {
	margin-top: 20px
}
.product-view .product-shop ul.add-to-links {
	margin: 0;
	padding: 0;
	margin-top: 0px;
	display: inline-block;
}
.product-view .product-shop .add-to-links li {
	display: inline-block;
	padding: 0px 0px 0px 6px;
	list-style-type: none;
}
.product-view .add-to-links span {
	font-size: 12px;
	margin-left: 5px;
	font-weight: normal;
	text-transform: none;
	display: none
}
.product-view .product-shop .add-to-links li a:hover {
	color: #fff;
	background: #8ac42f;
	border: 1px #8ac42f solid;
}
.product-view .email-friend {
	font-size: 12px;
	display: inline-block;
	margin-bottom: 0px;
	vertical-align: top;
	margin-left: 8px;
}
.product-view .email-friend a:before {
	content: "\f0e0";
	font-family: FontAwesome;
	font-size: 16px;
}
.product-view .email-friend a {
	color: #999;
	line-height: normal;
	font-weight: normal;
	vertical-align: top;
	display: block;
	height: 40px;
	width: 45px;
	border: 1px #ececec solid;
	color: #999;
	line-height: 36px;
	vertical-align: top;
	text-align: center;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}
.product-view .email-friend a:hover {
	color: #fff;
	background: #8ac42f;
	border: 1px #8ac42f solid;
}
.product-view .product-shop .add-to-links li .separator {
	display: none;
}
.product-view .product-shop .add-to-links .link-wishlist:before {
	content: "\f004";
	font-family: FontAwesome;
	font-size: 14px;
}
.product-view .product-shop .add-to-links .link-wishlist {
	color: #999;
	line-height: normal;
	font-weight: normal;
	vertical-align: top;
	display: block;
	height: 40px;
	width: 45px;
	border: 1px #ececec solid;
	color: #999;
	line-height: 36px;
	vertical-align: top;
	text-align: center;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}
.product-view .product-shop .add-to-links .link-compare:before {
	content: "\f012";
	font-family: FontAwesome;
	font-size: 14px;
}
.product-view .product-shop .add-to-links .link-compare {
	color: #999;
	line-height: normal;
	font-weight: normal;
	vertical-align: top;
	display: block;
	height: 40px;
	width: 45px;
	border: 1px #ececec solid;
	color: #999;
	line-height: 36px;
	vertical-align: top;
	text-align: center;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}
.product-view .product-shop ul.add-to-links a {
	padding: 0;
	margin: 0;
	display: block
}
.product-view .product-shop .ratings {
	padding: 18px 0;
	margin: auto;
	border-bottom: 1px #ddd dotted;
	position: relative;
	bottom: auto;
	border-radius: 0;
	left: 0;
	margin-left: 0px
}
.product-view .product-shop .ratings .rating-links {
	margin-bottom: 0px;
	display: inline-block;
}
.product-view .product-shop .no-rating {
	padding: 12px 0;
	margin: 15px 0 0;
}
.availability.in-stock {
	padding: 3px 0px;
	position: absolute;
	top: 18px;
	right: 0px;
}
.availability.in-stock span {
	color: #fff;
	font-size: 11px;
	line-height: 16px;
	display: block;
	background: #6dbe14;
	text-transform: uppercase;
	padding: 3px 6px;
}
.availability.in-stock span:before {
	content: "\e080";
	font-family: 'Simple-Line-Icons';
	display: inline-block;
	margin-right: 5px;
	font-size: 11px;
	vertical-align: middle
}
.availability.out-of-stock {
	padding: 3px 10px;
	position: absolute;
	top: 18px;
	right: 0px;
}
.availability.out-of-stock span {
	color: red;
	font-size: 12px;
	line-height: 16px;
	display: block;
	text-transform: uppercase;
}
.availability.out-of-stock span:before {
	content: '\f00d';
	font-family: FontAwesome;
	display: inline-block;
	margin-right: 5px;
	font-size: 12px;
	vertical-align: middle
}
.products-grid .item .actions .availability.out-of-stock {
	background: none repeat scroll 0 0 #fff;
	border: medium none;
	display: inline-block;
	height: 50px;
	line-height: 50px;
	padding: 0;
	text-align: center;
	width: 50px;
	position: relative;
	top: 0px;
}
.products-grid .item .actions .availability.out-of-stock span.ava-out-of-stock {
	display: none;
}
.products-grid .item .actions .availability.out-of-stock span:before {
	content: inherit;
}
.products-grid .item .actions .availability.out-of-stock:before {
	content: "\e04f";
	font-family: 'Simple-Line-Icons';
	font-size: 14px;
	color: #999
}
.products-list .product-shop p.availability.out-of-stock {
	border: 2px solid red;
	color: #666;
	display: inline-block;
	font-size: 13px;
	line-height: 1.5em;
	padding: 6px 10px;
	position: relative;
	text-transform: uppercase;
	top: 0;
}
.product-view .product-shop .price-block {
	position: relative;
}
.product-view .product-shop .price-box {
	display: block;
	margin: 0px;
	padding: 12px 0px;
	position: relative
}
.product-view .product-shop .grouped-items-table .price-box {
	border-bottom: 0px solid #ddd;
	margin: 0;
	padding: 0;
}
.product-view .product-shop .price-box .old-price {
	margin: 0px;
	margin-top: 0;
	display: inline-block;
}
.product-view .product-shop .special-price {
	display: inline-block;
	margin: 0;
	padding: 0;
}
.product-view .product-shop .old-price .price {
	font-size: 20px;
	font-weight: normal;
	vertical-align: 4px;
}
.product-view .product-shop .price {
	font-size: 24px;
	color: #000;
}
.add-to-cart .qty, .qty-holder .qty {
	background-color: #FFFFFF;
	border: 1px solid #E0E0E0;
	border-radius: 0px;
	font-size: 15px;
}
.add-to-cart .qty {
	height: 40px;
	text-align: center;
	width: 60px;
	vertical-align: top;
	margin: 0px;
}
.qty-input-text {
	float: left;
	margin-left: 10px;
}
.custom button.items-count {
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
	font-size: 12px;
	line-height: 0px;
	padding: 13px 12px 9px 12px;
	height: 40px;
	width: 40px;
}
.product-view .product-shop .add-to-box .add-to-cart label {
	display: inline-block;
	float: left;
	margin-top: 8px;
	text-align: left;
	text-transform: capitalize;
	font-size: 15px;
	font-weight: normal;
	margin-right: 15px;
}
.product-view .product-shop .product-options-bottom .add-to-cart label {
	display: block;
	float: none;
	margin-bottom: 5px;
	text-align: left;
	text-transform: uppercase;
}
/* 22.  Product Collatera */
.product-collateral {
	display: inline-block;
	margin-bottom: 20px;
}
#product-tabs {
	margin: 0;
	padding: 0;
	position: relative;
}
#product-tabs > dt {
	text-transform: none;
	font-size: 14px;
	padding: 8px 0px;
	cursor: pointer;
	color: #102343;
	text-transform: none;
}
#product-tabs > dt.open {
	position: relative;
	background-color: #fff;
	z-index: 10;
	color: #2CA2E3;
}
#product-tabs > dd {
	display: none;
	right: 0;
	top: 0;
	background-color: #fff;
	margin: 0;
	padding: 6px 0;
	line-height: 1.8;
	border-bottom-width: 0 !important;
	font-size: 14px;
}
#product-tabs > dd h2 {
	display: none;
}
.product-tabs {
	margin: 5px 0px 0;
	padding: 8px 0px 0px;
	text-align: left;
	width: 100%;
}
#productTabContent {
	padding: 10px 25px;
	background: none repeat scroll 0 0 #fff;
	border: 1px solid #e5e5e5;
	border-top: none;
}
.product-tabs li a {
	color: #666;
	margin-right: 0px;
	text-decoration: none;
	font-size: 14px;
	font-weight: 600;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
	padding: 0px;
	letter-spacing: 1px;
	border: none;
}
.product-tabs li.active a {
	background: none repeat scroll 0 0 #fff;
	color: #333;
	padding: 12px;
	border: 2px solid #333;
}
.nav-tabs.product-tabs > li {
	display: inline-block;
	float: none;
	margin-right: 5px;
}
.nav-tabs.product-tabs > li > a {
	margin-right: 0px;
	line-height: 1.42857143;
	border-radius: 0px;
	background: none repeat scroll 0 0 #fff;
	padding: 12px 15px 12px;
	border: 1px #e5e5e5 solid;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
	border: 1px #e5e5e5 solid;
	border-bottom: 1px solid #fff;
	color: #8ac42f;
}
.nav-tabs > li > a:hover {
	border: 1px #e5e5e5 solid;
	border-bottom: 1px #fff solid;
	background: #fff;
	color: #8ac42f;
	transition: all 0.3s cubic-bezier(0.8, 0, 0, 1) 0s;
}
.tab-content {
	background-color: inherit;
	padding: 5px 0px 20px 0px;
	overflow: hidden;
	font-size: 13px;
	line-height: 21px;
	margin-top: 0px;
	letter-spacing: 0.5px;
}
.tab-content > .active {
	margin-top: 10px;
}
.tags .input-box input {
	border: 1px solid #ddd;
	margin-top: 0;
	padding: 6px;
	width: 35%;
}
.form-add-tags button.button {
	display: inline;
	background: #fff;
	border: 1px solid #ccc;
	background: #fff;
	padding: 8px 14px;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
}
.form-add-tags button.button:hover {
	display: inline;
	background: #8ac42f;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	border: 1px #8ac42f solid;
	color: #fff;
}
.form-add-tags label {
	letter-spacing: 0.5px;
	text-transform: uppercase;
}
#customer-reviews {
	margin-top: 15px;
}
#customer-reviews .detail {
	font-size: 14px;
	line-height: 18px;
}
#customer-reviews h3 {
	font-size: 14px;
	margin-bottom: 15px;
	letter-spacing: 1px;
	text-transform: uppercase;
}
#customer-reviews h4 {
	display: inline;
	font-size: 14px;
}
#customer-reviews h5 {
	display: inline;
	font-size: 14px;
}
.box-reviews dl {
	margin-bottom: 0;
}
.box-reviews dt {
	font-size: 18px;
	padding: 20px 0 10px;
}
#customer-reviews .page-title {
	border: none;
}
#customer-reviews .page-title h2 {
	color: #000;
	font-size: 20px;
	font-weight: normal;
	margin-top: 0;
	padding: 2px 0px;
	position: relative;
	display: block;
	text-transform: uppercase;
	letter-spacing: 1px;
	border-bottom: 1px solid #ccc;
	padding-bottom: 10px
}
.box-reviews1 {
	margin-top: 8px;
	margin-bottom: 20px;
}
.box-reviews2 {
	margin-top: 8px;
	margin-bottom: 35px;
	overflow: hidden;
}
.review-title {
	margin-top: 0;
	line-height: 1.4;
	padding-bottom: 15px;
	margin-bottom: 0;
	font-size: 13px;
	font-weight: 400;
}
.review-title span {
	font-size: 14px;
	font-weight: 400;
	margin-top: 5px;
}
.box-reviews > .rating-box {
	position: absolute;
	top: 30px;
	right: 30px;
}
.box-reviews dd {
	margin-left: 0;
	padding-bottom: 20px;
}
.box-reviews .author {
	font-style: italic;
	font-size: 14px;
	margin-right: 5px;
}
.box-reviews .date {
	color: #bdbdbd;
	font-size: 14px;
	font-style: italic;
}
.box-reviews .ratings-table {
	float: left;
	margin: 5px 15px 15px 0;
	width: 12%;
}
.box-reviews .review {
	border-left: 1px solid #DFDFDF;
	float: left;
	padding-left: 2.5em;
	width: 84%;
	margin-bottom: 10px;
}
.box-reviews .review .review-txt.last {
	border-bottom: none;
}
.box-reviews .review .review-txt {
	border-bottom: 1px solid #DFDFDF;
	padding-bottom: 12px;
	margin-bottom: 10px;
}
.box-reviews ul li h6 {
	font-size: 15px;
	margin-bottom: 3px;
	margin-top: 0px;
	padding: 0;
}
.box-reviews ul li h6 a {
	color: #333;
	font-weight: 500;
	letter-spacing: 0.5px;
}
.box-reviews2 ul li {
	margin-bottom: 0px;
	clear: both;
}
.box-reviews .actions {
	padding-top: 1.5em;
	text-align: right;
	float: right;
}
#customer-reviews .form-list li label {
	color: #333;
	font-size: 14px;
}
#customer-reviews .buttons-set {
	margin-top: 10px;
}
/*detail page view link*/
a.button.view-all:before {
	content: "\f06e";
	font-family: FontAwesome;
	font-size: 13px;
	padding-right: 8px;
}
a.button.view-all {
	display: inline-block;
	border: 0;
	background: #fff;
	font-size: 12px;
	text-align: center;
	white-space: nowrap;
	color: #333;
	font-weight: 600;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	vertical-align: top;
	border: 1px solid #ccc;
	padding: 5px 10px;
	text-transform: uppercase;
}
.button.view-all:hover {
	background: #8ac42f;
	transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-webkit-transition: all 0.3s linear;
	border: 1px solid #8ac42f;
	color: #fff;
	text-decoration: none;
}
.box-reviews .form-add h3 {
	margin-bottom: 5px;
	font-size: 14px;
	font-weight: 400;
}
.box-reviews .form-add h4 {
	font-size: 15px;
}
.box-reviews textarea {
	width: 95%;
	margin-top: 4px;
	height: 8em;
	background: #fff;
}
.box-reviews .form-list input.input-text {
	width: 80%;
	padding: 10px;
	margin-bottom: 4px;
	line-height: 20px;
	background: #fff;
	border: 1px solid #ddd;
	box-shadow: 0 0 0px rgba(0, 0, 0, 0.1) inset;
}
.box-reviews .form-list input.input-text:focus {
	width: 80%;
	padding: 10px;
	margin-bottom: 4px;
	line-height: 20px;
	background: #fff;
	border: 1px solid #ddd;
	box-shadow: 0 0 0px rgba(0, 0, 0, 0.1) inset;
}
.box-reviews .form-list select {
	padding: 12px 15px 12px 165px;
	margin: 0;
	width: 100%;
	line-height: 20px;
	height: 48px;
}
.review1 {
	display: inline-block;
	width: 45%;
	float: left;
}
.review2 {
	display: inline-block;
	width: 55%;
}
#product-review-table {
	margin: 10px 0;
	border: medium none;
	width: 100%;
}
#product-review-table th, #product-review-table td {
	text-align: left;
	font-size: 12px;
	line-height: 20px;
	padding: 8px;
	letter-spacing: 0.5px;
}
#customer-reviews ul {
	margin: auto;
	padding: 0px;
}
#customer-reviews li {
	margin-bottom: 0px;
	list-style: none;
}
#customer-reviews .form-list li label {
	color: #333;
	font-size: 13px;
	letter-spacing: 1px;
}
.review2 label {
	color: #333;
	font-size: 13px;
	letter-spacing: 1px;
}
label {
	font-weight: normal;
}
.box-reviews .form-list input.input-text {
	width: 80%;
	padding: 10px;
	margin-bottom: 4px;
	line-height: 20px;
	background: #fff;
	border: 1px solid #ddd;
	box-shadow: 0 0 0px rgba(0, 0, 0, 0.1) inset;
}
.box-reviews textarea {
	border: 1px #f1f1f1 solid;
	padding: 10px;
	outline: none;
	color: #aaa;
}
.nobr {
	white-space: nowrap;
}
button.button.submit:before {
	content: "\f04b";
	font-family: FontAwesome;
	font-size: 11px;
	padding-right: 8px;
}
.upsell-pro {
	margin-bottom: 50px;
}
#product-tabs > dd h2.product-name {
	display: block;
}
#product-tabs .crosssell .flex-direction-nav a {
	width: 50px;
	height: 40px;
	top: 112px;
}
#product-tabs .crosssell .flex-direction-nav .flex-prev {
	right: auto;
	left: -10px;
}
#product-tabs .crosssell .flex-direction-nav .flex-next {
	right: -10px;
}
.box-up-sell .flex-direction-nav a, .box-cross-sell .flex-direction-nav a {
	width: 54px;
	height: 44px;
	top: 0;
	margin-top: -76px;
	margin-right: -1px;
}
.product-view .box-tags .form-add input.input-text {
	width: 350px;
	background-color: #FFFFFF;
	border: 1px solid #CCCCCC;
	border-radius: 2px;
	box-shadow: 0 1px 5px #eee inset;
	margin-right: 10px;
	padding: 8px;
}
.up-sell-box {
	border: 1px #ddd solid;
	border-top: none;
}
.up-sell-box p.desc {
	margin: 0;
	padding: 10px 10px 0 10px
}
.product-view .box-tags .form-add label {
	line-height: 42px;
	font-size: 16px;
}
.product-view .box-tags .form-add .button {
	height: 35px;
}
#addTagForm {
	margin-bottom: 5px;
	margin-top: 10px
}
.product-view .box-up-sell {
	margin-top: 10px;
	float: left;
}
.product-view .box-up-sell .subtitle {
	margin-right: 0;
}
.mini-products-list .price-box * {
	display: inline;
}
.mini-products-list .price-box .price {
	margin-right: 10px;
}
.product-view .product-next-prev .product-next {
	font-size: 18px;
	line-height: 22px;
	display: inline-block;
	width: 26px;
	height: 26px;
	color: #666;
	text-align: center;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
	position: absolute;
	right: 15px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	position: absolute;
	z-index: 10000;
	top: 4px;
	border: 1px #ddd solid;
}
.product-view .product-next-prev .product-next:before {
	content: "\f105";
	font-family: FontAwesome;
}
.product-view .product-next-prev .product-next:hover, .product-shop .product-next-prev .product-prev:hover {
	color: #fff;
	background: #8ac42f;
	text-decoration: none;
	border: 1px #8ac42f solid;
}
.product-view .product-next-prev .product-prev {
	font-size: 18px;
	line-height: 22px;
	display: inline-block;
	width: 26px;
	height: 26px;
	color: #666;
	text-align: center;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	position: absolute;
	right: 45px;
	z-index: 10000;
	top: 4px;
	border: 1px #ddd solid;
}
.product-view .product-next-prev .product-prev:before {
	content: "\f104";
	font-family: FontAwesome;
}
.product-view .product-next-prev a:hover {
	background: #8ac42f;
	color: #fff;
}
.side-banner {
	margin-bottom: 20px
}
.product-view .product-shop .ratings-table {
	padding: 10px;
	margin-top: 10px;
}
.review-product-list .product-view .product-shop .buttons-set {
	clear: both;
	overflow: hidden;
}
.product-view .product-shop .product-options .options-list label {
	color: #333;
}
.product-view .product-shop .product-options-bottom .btn-cart {
	font-size: 18px;
	text-shadow: none;
	padding: 7px 20px;
	float: left;
	margin-top: 0px;
	font-weight: normal;
	transition: color 300ms ease-in-out 0s, background-color 300ms ease-in-out 0s, background-position 300ms ease-in-out 0s;
	margin-left: 10px;
	border: none;
}
.product-view .product-shop .product-options-bottom .btn-cart:hover {
	text-shadow: none;
}
.product-view .product-shop .product-options-bottom .btn-cart {
	background: #34d1c6;
	color: #fff;
}
.product-view .product-shop .product-options-bottom .btn-cart:hover {
	background: #202726;
	color: #fff;
}
.product-view .product-options-bottom button.button.btn-cart span {
	background: none;
	margin-left: 10px;
	font-size: 14px;
}
#product-options-wrapper .price-notice .price {
	font-size: 12px;
}
.product-view .product-shop .product-pricing .price {
	font-size: 12px;
}
.product-view .product-shop ul.product-pricing {
	margin-top: 5px;
}
#cloud-zoom-big {
	border: 1px #ddd solid;
	z-index: 10000 !important;
}
/* 23. related slider */
.related-section .slider-items-products {
	overflow: hidden;
}
.related-section {
	margin-bottom: 10px;
	position: relative;
	margin-top: 10px;
}
#related-products-slider.product-flexslider {
	margin: 0px -12px;
}
.related-section .block-title {
	color: #333;
	font-size: 20px;
	font-weight: 500;
	text-transform: uppercase;
	margin: 0px auto 15px;
	line-height: normal;
	letter-spacing: 0.5px;
	text-align: center;
}
.related-section .block-title h3 {
	margin: auto;
	font-size: 20px;
}
.related-section .owl-theme .owl-controls {
	margin-top: 10px;
	position: absolute;
	text-align: center;
	top: -10.8%;
	width: 100%;
}
.related-section .slider-items-products .owl-buttons a {
	background: #fff;
	border: 1px #e5e5e5 solid;
	color: #666;
	display: block;
	height: 35px;
	margin: 0px;
	position: absolute;
	top: 50%;
	z-index: 5;
	width: 35px;
}
.related-section .slider-items-products .owl-buttons a:hover {
	text-decoration: none;
	background: #8ac42f;
	border: 1px #8ac42f solid;
	color: #fff;
}
.related-section .slider-items-products .owl-buttons .owl-next {
	right: 47px;
	left: inherit;
}
.related-section .slider-items-products .owl-buttons .owl-prev a:before {
	line-height: 35px;
}
.related-section .slider-items-products .owl-buttons .owl-next a:before {
	line-height: 32px;
}
.related-section .slider-items-products .owl-buttons .owl-prev {
	left: 12px;
	right: inherit;
}
/* 24. upsell slider */
.upsell-section .slider-items-products {
	overflow: hidden;
}
.upsell-section {
	margin-bottom: 10px;
	position: relative;
	margin-top: 10px;
}
#upsell-products-slider.product-flexslider {
	margin: 0px -12px;
}
.upsell-section .block-title {
	color: #333;
	font-size: 20px;
	font-weight: 500;
	text-transform: uppercase;
	margin: 0px auto 15px;
	line-height: normal;
	letter-spacing: 0.5px;
	text-align: center;
}
.upsell-section .block-title h3 {
	margin: auto;
	font-size: 20px;
}
.upsell-section .owl-theme .owl-controls {
	margin-top: 10px;
	position: absolute;
	text-align: center;
	top: -10.8%;
	width: 100%;
}
.upsell-section .slider-items-products .owl-buttons a {
	background: #fff;
	border: 1px #e5e5e5 solid;
	color: #666;
	display: block;
	height: 35px;
	margin: 0px;
	position: absolute;
	top: 50%;
	z-index: 5;
	width: 35px;
}
.upsell-section .slider-items-products .owl-buttons a:hover {
	text-decoration: none;
	background: #8ac42f;
	border: 1px #8ac42f solid;
	color: #fff;
}
.upsell-section .slider-items-products .owl-buttons .owl-next {
	right: 47px;
	left: inherit;
}
.upsell-section .slider-items-products .owl-buttons .owl-prev a:before {
	line-height: 35px;
}
.upsell-section .slider-items-products .owl-buttons .owl-next a:before {
	line-height: 32px;
}
.upsell-section .slider-items-products .owl-buttons .owl-prev {
	left: 12px;
	right: inherit;
}
/*  25.  Global Messages   */

#toTop {
	color: #fff;
	text-align: center;
	bottom: 60px;
	display: none;
	height: 40px;
	line-height: 40px;
	overflow: hidden;
	position: fixed;
	right: 10px;
	text-decoration: none;
	width: 45px;
	background: url(../images/to-top-arrow.png) no-repeat center;
	background-color: #8ac42f;
	text-transform: uppercase;
	z-index: 100;
	border-radius: 2px;
}
#toTop:hover {
	color: #fff;
	background-color: #8ac42f;
}
/* bottom banner*/

.banner-overly {
	background-color: rgba(0, 0, 0, 0.4);
	left: 12%;
	position: absolute;
	right: 12%;
	top: 33%;
	z-index: 1;
	-moz-transition: 0.4s;
	-o-transition: 0.4s;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}
.bottom-banner-img {
	width: 100%;
	float: left;
	height: 240px;
	margin: 15px 0px;
	position: relative;
	display: inline-block;
}
.bottom-banner-img img {
	max-width: 100%;
}
.bottom-banner-img h3 {
	margin-top: 100px;
	margin-bottom: 5px;
	-moz-transition: 0.4s;
	-o-transition: 0.4s;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	text-align: center;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.bottom-banner-img h6 {
	display: table;
	margin: auto;
	font-size: 13px;
	color: #fff;
	margin-bottom: 20px;
	font-weight: normal;
}
.bottom-banner-img .btn {
	border-color: transparent;
	display: table;
	margin: auto;
}
.bottom-banner-img .shop-now-btn {
	opacity: 0;
	-moz-transition: 0.4s;
	-o-transition: 0.4s;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	background-color: #8ac42f;
	border-color: #8ac42f;
}
.bottom-banner-img .banner-overly {
	height: 100px;
}
.bottom-banner-img:hover h3 {
	margin-top: 60px;
	color: #fff;
}
.bottom-banner-img:hover .shop-now-btn {
	opacity: 1;
	background-color: #8ac42f;
	border-color: #8ac42f;
	padding: 8px 12px;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
}
.bottom-banner-img:hover .banner-overly {
	background-color: rgba(0, 0, 0, 0.5);
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
}
.bottom-img-info {
	width: 100%;
	z-index: 1;
	position: absolute;
	height: 100%;
	color: #fff;
	text-align: center;
	top: 0px;
}
/* 26. Shopping Cart Table */

.shopping-cart-inner .page-content {
	margin-top: 10px;
}
.shopping-cart-inner ul.step {
	width: 100%;
	clear: both;
	overflow: hidden;
	padding: 0px;
}
.shopping-cart-inner ul.step li {
	display: inline;
	line-height: 30px;
	width: 20%;
	float: left;
	text-align: center;
	border-bottom: 3px solid #ccc;
}
.shopping-cart-inner ul.step li.current-step {
	border-bottom: 3px solid #8ac42f;
}
.shopping-cart-inner .heading-counter {
	margin: 30px 0;
	padding: 15px;
	border: 1px solid #eaeaea;
	background-color: #fff;
}
.table.jtv-cart-summary {
	background-color: #fff;
}
.table-bordered>thead>tr>td, .table-bordered>thead>tr>th {
	border-bottom-width: 0px;
}
.shopping-cart-inner .cart_navigation a {
	padding: 10px 20px;
	border: 1px solid #eaeaea;
}
.shopping-cart-inner .cart_avail {
	text-align: center;
}
.jtv-cart-summary .cart_avail .label {
	white-space: normal;
	display: inline-block;
	padding: 6px 10px;
	font-size: 14px;
	border-radius: 0px;
}
.jtv-cart-summary .product-name {
	font-size: 16px;
	margin-bottom: 2px;
}
.jtv-cart-summary .product-name a {
	color: #333;
}
.jtv-cart-summary .cart_description {
	font-size: 14px;
}
.jtv-cart-summary small a {
	color: #999;
}
.jtv-cart-summary .cart_avail .label-success {
	background: #FFF;
	border: 1px solid #92B558;
	color: #92B558;
	font-weight: normal;
}
.shopping-cart-inner .cart_navigation .btn-proceed-checkout {
	float: right;
	background: #8ac42f;
	color: #fff;
	border: 0px solid #8ac42f;
	width: auto;
	padding: 8px 15px;
}
.shopping-cart-inner .cart_navigation a.next-btn:hover {
	opacity: 0.8;
}
.shopping-cart-inner .cart_navigation a:hover {
	background: #8ac42f;
	color: #fff;
}
.jtv-cart-summary > thead, .jtv-cart-summary > tfoot {
	background: #fff;
	font-size: 13px;
	font-weight: 600;
}
.jtv-cart-summary > thead>th {
	border-bottom-width: 1px;
	padding: 20px;
}
.jtv-cart-summary td {
	vertical-align: middle!important;
	padding: 20px;
}
.jtv-cart-summary .table>tbody>tr>td, .table>tbody>tr>th, .jtv-cart-summary .table>tfoot>tr>td, .table>tfoot>tr>th, .jtv-cart-summary .table>thead>tr>td, .table>thead>tr>th {
	padding: 15px;
	font-weight: 600;
	font-size: 13px;
}
.jtv-cart-summary img {
	max-width: 100px;
}
.jtv-cart-summary td.cart_product {
	width: 120px;
	padding: 15px;
}
.jtv-cart-summary .price {
	text-align: right;
	font-weight: 600;
	font-size: 13px;
}
.jtv-cart-summary .qty {
	text-align: center;
	width: 100px;
}
.jtv-cart-summary .qty input {
	text-align: center;
	max-width: 64px;
	margin: 0 auto;
	border-radius: 0px;
	border: 1px solid #eaeaea;
	box-shadow: none;
	padding: 2px 0px;
	font-weight: 600;
	font-size: 14px;
}
.jtv-cart-summary .qty a {
	padding: 8px 10px 5px 10px;
	border: 1px solid #eaeaea;
	display: inline-block;
	width: auto;
	margin-top: 5px;
	color: #999;
}
.jtv-cart-summary .qty a:hover {
	background: #8ac42f;
	color: #fff;
}
.jtv-cart-summary .action {
	text-align: center;
}
.jtv-cart-summary .action a {
	background: url("../images/delete_icon.png") no-repeat center center;
	font-size: 0;
	height: 9px;
	width: 9px;
	display: inline-block;
	line-height: 24px;
}
.jtv-cart-summary tfoot {
	text-align: right;
}
.cart_navigation {
	margin-top: 10px;
	float: left;
	width: 100%;
}
.cart .totals table th, .cart .totals table td {
	padding: 5px;
	border: none;
	background: none;
	border: medium none;
	box-shadow: none;
	color: #333;
}
.cart .totals table th, .cart .totals table td strong {
	font-size: 12px;
}
.cart-collaterals {
	margin-top: 20px;
	margin-bottom: 30px;
}
.cart-collaterals .col2-set {
	float: left;
	margin: 0;
	padding: 0;
}
.cart-collaterals .totals {
}
.totals h3 {
	border-bottom: 1px solid #ddd;
	color: #000;
	font-family: 'Roboto', sans-serif;
	font-size: 15px;
	margin-bottom: 5px;
	padding: 10px 0;
	text-transform: uppercase;
	letter-spacing: 1px;
}
.cart-collaterals .col2-set .col-2 {
	width: 320px;
}
.totals .inner {
	padding: 4px 0px 15px 0px;
	background-color: #fff;
	margin-top: 0px;
}
#shopping-cart-totals-table {
	float: right;
	width: 100%;
	padding-bottom: 8px;
}
#shopping-cart-totals-table .price {
	font-size: 14px;
	color: #f23534
}
#shipping-zip-form p {
	margin: 2px 0 4px;
}
#shipping-zip-form .form-list input.input-text {
	width: 90%;
}
.discount {
	margin: auto;
}
.discount label {
	font-weight: normal;
}
.shipping {
	margin: auto;
}
.shipping .form-list select {
	width: 90%;
	background-color: #F0F0F0;
	border: 1px #f0f0f0 solid;
	padding: 8px 10px;
}
.shipping .form-list select:focus {
	background-color: #fff;
	border: 1px #ddd solid;
	padding: 8px 10px;
}
.shipping .form-list input.input-text {
	width: 95%;
}
.cart-collaterals h3 {
	font-size: 14px;
	color: #000;
	margin-bottom: 15px;
	border-bottom: 2px solid #eaeaea;
	padding: 10px 0;
	text-transform: uppercase;
	margin-top: auto;
	letter-spacing: 0.5px;
	font-weight: 600;
}
.buttons-set11 {
	margin-top: 10px;
}
input.input-text, select, textarea {
	background-color: #f0f0f0;
	border: 1px #f1f1f1 solid;
	padding: 8px 10px;
	outline: none;
	color: #aaa;
}
.wishlist-table textarea {
	padding: 8px 10px;
}
input.input-text:focus, select:focus, textarea:focus {
	background-color: #fff;
	border: 1px solid #ddd;
	padding: 8px 10px;
	outline: none;
}
input#coupon_code {
	margin: 8px 0;
	width: 90%;
}
#shopping-cart-table {
	background-color: #FFFFFF;
	text-align: left;
	border: none;
}
#shopping-cart-table input.qty {
	background: none repeat scroll 0 0 #FFFFFF;
	border: 2px solid #eaeaea;
	font-size: 15px;
	font-weight: normal;
	margin: 0;
	outline: medium none;
	padding: 8px;
	width: 50px;
	text-align: center;
}
#shopping-cart-table a.remove-item {
	background-color: #fff;
	background-image: none;
	color: #333;
	cursor: pointer;
	padding: 0px;
	border-radius: 0px;
	cursor: pointer;
	text-decoration: none;
	float: left;
	transition: all 0.3s linear;
	border: none;
}
#shopping-cart-table a.remove-item span {
	display: none
}
#shopping-cart-table a.remove-item:before {
	content: "\f014";
	font-family: FontAwesome;
	font-size: 14px;
	font-weight: normal;
}
#shopping-cart-table a.remove-item:hover {
	padding: 0px;
	color: #f23534;
}
#shopping-cart-table .remove-item span span {
	display: none;
}
#shopping-cart-table dl {
	margin: 0;
	padding: 0;
}
#shopping-cart-table .item-options dd {
	color: #CCCCCC;
	margin: 0;
}
#shopping-cart-table h4 {
	font-size: 12px;
	font-family: 'Poppins', sans-serif;
	text-transform: uppercase
}
#shipping-zip-form .form-list label {
	display: block;
}
#shopping-cart-table .cart-price .price {
	font-size: 12px;
}
.cart-table .btn-continue {
	float: left;
}
.cart-table .btn-update, .cart-table .btn-empty {
	float: right;
	margin-left: 8px;
}
.cart .product-name a {
	font-size: 14px;
	color: #333;
}
#shopping-cart-table .data-table td {
	vertical-align: middle;
}
.checkout a {
	text-decoration: underline;
	color: #33c5fb;
}
.checkout {
	color: #666666;
	padding-top: 5px;
	text-align: center;
	list-style: none;
	padding: 0;
	margin: 0;
}
button.button.btn-proceed-checkout {
	background: #222;
	padding: 10px 5px;
	color: #fff;
	width: 100%;
	border: none;
}
button.button.btn-proceed-checkout:hover {
	background: #8ac42f;
	border: none;
}
button.button.btn-proceed-checkout span {
	font-size: 16px;
	font-weight: normal;
}
.crosssel {
	margin: 12px 0px;
}
.crosssel .jtv-title.center {
	margin-bottom: 10px;
}
.cart {
	margin: 0 0px 20px;
	overflow: hidden;
	padding: 0px;
}
.crosssel {
	margin: 10px 0px 0px;
	padding: 0px;
	display: inline-block;
}
.crosssel .category-products ul.products-grid li.item {
	margin-bottom: 15px!important;
}
.crosssel .jtv-title {
	color: #333;
	font-size: 20px;
	font-weight: 500;
	text-transform: uppercase;
	margin: 0px auto 15px;
	line-height: normal;
	letter-spacing: 0.5px;
	text-align: center;
}
/* 27. Wishlist Table */
.my-wishlist .wishlist-table.data-table {
	background-color: #fff;
}
.wishlist-table .product-name {
	font-size: 15px;
	line-height: normal;
	margin: 0;
	text-align: left;
}
.wishlist-table .product-name a {
	color: #333;
}
.wishlist-table input.qty {
	background: none repeat scroll 0 0 #FFFFFF;
	padding: 5px;
	text-align: center;
	border: 2px #efefef solid;
	width: 50px;
}
.wishlist-table input.qty:focus {
	padding: 5px;
	border: 2px #ddd solid;
}
#co-shipping-method-form {
	padding: 0 12px;
	margin-bottom: 15px;
}
.wishlist-table .cart-cell .btn-cart {
	display: block !important;
	margin: 10px 0 !important;
}
.wishlist-table a.remove-item {
	padding: 6px 11px;
	color: #333;
	background-color: #fff;
	border-radius: 0px;
	border: none;
	transition: all 0.3s linear;
	text-decoration: none;
}
.wishlist-table a.remove-item:before {
	content: "\f014";
	font-family: FontAwesome;
	font-size: 14px;
}
.wishlist-table a.remove-item:hover {
	padding: 6px 11px;
	color: #f23534;
	background-color: #fff;
	border: none;
	transition: all 0.3s linear;
}
.wishlist-table .remove-item span span {
	display: none;
}
.wishlist-table button.button.remove-item {
	text-decoration: none;
	float: none;
	background: none;
	font-family: 'FontAwesome';
	font-size: 16px;
	text-transform: uppercase;
	display: inline;
	margin-bottom: 8px;
	padding: 0;
	color: #fff;
	background: none repeat scroll 0 0 #333;
	border-radius: 2px;
	font-size: 16px;
	height: 30px;
	line-height: 20px;
	padding: 4px;
	text-align: center;
	width: 30px;
	margin-left: 5px;
}
.wishlist-table button.button.remove-item:hover {
	text-decoration: none;
	float: none;
	background: none;
	font-family: 'FontAwesome';
	font-size: 16px;
	text-transform: uppercase;
	display: inline;
	margin-bottom: 8px;
	padding: 0;
	color: #fff;
	background: none repeat scroll 0 0 #f23534;
	border-radius: 2px;
	font-size: 16px;
	height: 30px;
	line-height: 20px;
	padding: 4px;
	text-align: center;
	width: 30px;
}
.wishlist-table button.button.btn-cart {
	padding: 0px;
	color: #222;
	background-color: #fff;
	border: none;
	transition: all 0.3s linear;
	margin: 0 0 10px !important;
}
.wishlist-table button.button.btn-cart span {
	display: none;
}
.wishlist-table a.remove-item span {
	display: none;
}
.wishlist-table button.button.btn-cart:hover {
	padding: 0px;
	color: #f23534;
	background-color: #fff;
	border: none;
	transition: all 0.3s linear;
}
.wishlist-table button.button.btn-cart:before {
	content: "\f07a";
	font-family: 'FontAwesome';
	font-size: 16px
}
.my-wishlist .buttons-set2 {
	margin-top: 10px;
}
.my-wishlist .buttons-set2 .btn-share {
	float: right;
	margin-left: 8px;
}
.my-wishlist .buttons-set2 .btn-update {
	float: right;
	margin-right: 2px;
}
.my-wishlist .buttons-set2 .btn-add {
	float: right;
	margin-left: 8px;
}
/* 28. Dashboard */

.dashboard .welcome-msg {
	text-align: left;
	padding: 0px 0 5px;
	color: #333;
	margin: auto;
}
.dashboard .welcome-msg strong {
	font-size: 13px;
}
.box-account {
	margin-top: 25px;
}
.box-account .page-title {
	margin: 0 !important;
	padding: 0;
	background: none;
	border-bottom: none !important;
}
.box-account .page-title h2 {
	font-size: 16px;
}
.box-account address {
	color: #333;
	text-align: left;
	border-bottom: none;
}
.box-account .col2-set a {
	text-decoration: underline
}
.col2-set .col-1 {
	border: 0 solid #336600;
	padding-bottom: 0;
	padding-top: 0;
	text-align: left;
	width: 48%;
	display: inline-block;
}
.col2-set .col-2 {
	text-align: left;
	width: 48%;
	display: inline-block;
}
.recent-orders .title-buttons {
	margin-bottom: 8px;
	text-align: right;
}
.recent-orders .title-buttons strong {
	color: #333;
	float: left;
	font-size: 13px;
}
/* 29. checkout pages */
.checkout-page .checkout-sep {
	padding-bottom: 15px;
	margin-top: 0px;
}
.checkout-page .box-border {
	margin-bottom: 15px;
}
.checkout-page .box-border .button {
	margin-top: 15px;
}
.checkout-page .box-border label {
	margin-top: 2px;
	color: #666;
}
.checkout-page .box-border p, .checkout-page .box-border h4 {
	padding-bottom: 5px;
	margin-top: 5px;
	font-size: 14px;
}
.checkout-page .box-border .fa {
	line-height: inherit;
}
.checkout-page .form-control {
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #eaeaea;
	border-radius: 0px;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
	margin: auto auto 8px;
}
h3.checkout-sep {
	font-size: 16px;
	margin-bottom: 0px;
	text-transform: capitalize;
}
.checkout-page .box-border input[type="radio"] {
	margin-right: 10px;
}
.checkout-page .box-border {
	border: 1px solid #eaeaea;
	padding: 20px;
	overflow: hidden;
	background-color: #fff;
}
.checkout-page .box-border ul {
	padding: 0px;
	list-style: none;
}
.block-progress dl {
	border-top: none !important
}
.block-progress dt {
	font-weight: normal;
	text-transform: none;
	padding: 10px 0;
	font-size: 12px;
}
.block-progress dt.complete, .block-progress dd.complete {
	font-size: 12px;
	padding: 0;
	margin: 0;
}
.block-progress dt.complete a, .block-progress dd.complete a {
	font-size: 12px;
	font-weight: normal;
	text-decoration: underline;
	text-transform: none;
	color: #8ac42f;
}
.block-progress dt.complete a:hover, .block-progress dd.complete a:hover {
	font-size: 12px;
	font-weight: normal;
	text-decoration: underline;
	text-transform: none;
	color: #333;
}
.block-progress dt.complete {
	margin: 0 0 8px 0;
}
.block-progress dd {
	border: 0px solid #ddd;
	margin: 0 0 6px;
	padding: 0px 0px 8px !important;
}
.one-page-checkout ul {
	padding: 0px;
	list-style: none;
}
.one-page-checkout .active .step-title h3 {
	color: #8ac42f;
	font-size: 13px;
	font-weight: 600;
	letter-spacing: 1px;
}
.one-page-checkout .step-title h3 {
	border-bottom: 2px solid #eaeaea;
	margin: 0 0 0 55px;
	text-align: left;
	line-height: 35px;
	font-size: 13px;
	color: #333;
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 1px;
}
.one-page-checkout .active .step-title {
	cursor: default;
	font-size: 14px;
	padding-top: 0px;
	text-align: left;
	line-height: 32px;
	font-family: 'Roboto', sans-serif;
	border-top: none;
}
.one-page-checkout .step {
	padding: 10px 10px 10px 55px;
	background-color: #FFFFFF;
}
select#billing-address-select {
	width: 100%;
	padding: 10px;
	margin-top: 5px;
	background: #f0f0f0;
	border: 1px solid #f0f0f0;
	outline: none;
	color: #aaa;
	margin-bottom: 8px;
}
.one-page-checkout label {
	margin-right: 5px;
}
.one-page-checkout p.require {
	font-size: 11px;
}
select#billing-address-select:focus {
	background: none repeat scroll 0 0 #FFFFFF;
	border: 1px solid #DDDDDD;
	color: #333333;
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	outline: medium none;
	padding: 9px 7px 10px;
	vertical-align: middle;
}
select#shipping-address-select {
	width: 60%;
	padding: 10px;
	margin-top: 5px;
	background: #f0f0f0;
	border: 1px solid #f0f0f0;
	outline: none;
	color: #aaa;
}
select#shipping-address-select:focus {
	background: none repeat scroll 0 0 #FFFFFF;
	border: 1px solid #DDDDDD;
	color: #333333;
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	outline: medium none;
	padding: 9px 7px 10px;
	vertical-align: middle;
}
.one-page-checkout {
	position: relative;
	margin-bottom: 12px;
	list-style: none;
	padding: inherit;
	padding: 0;
	margin-top: 18px;
}
.one-page-checkout li.section {
	border-bottom: 0;
	margin-bottom: 8px;
	overflow: hidden;
}
.one-page-checkout .step-title {
	text-align: right;
	padding: 7px 0;
}
.one-page-checkout .step-title .number {
	float: left;
	line-height: 15px;
	font-size: 15px;
	color: #333;
	padding: 10px 13px;
	background-color: #fff;
	border: 2px solid #eaeaea;
	font-family: 'Roboto', sans-serif;
}
.one-page-checkout .active .step-title .number {
	background: none repeat scroll 0 0 #222;
	border: 2px solid #222;
	color: #fff;
	padding: 10px 12px;
}
.one-page-checkout .step .col2-set {
	padding: 0;
}
.one-page-checkout .order-review {
	overflow: hidden;
}
#checkout-review-table .data-table {
	border-bottom: none;
}
#checkout-step-login .col2-set .col-2 {
	background-color: #fff;
	float: left;
	padding: 0px;
	width: 45%;
	min-height: 270px;
}
#checkout-step-login .col2-set .col-1 {
	float: right;
	padding: 0 0 0 40px;
	width: 45%;
	min-height: 270px;
	border-left: 1px #e4e4e4 solid;
}
#opc-login h2 {
	border-bottom: 0px solid #E4E4E4;
	padding-bottom: 8px;
	margin-bottom: auto;
	margin-top: auto;
	font-size: 12px;
}
.one-page-checkout .buttons-set .f-right {
	margin-top: 13px;
	color: #626262;
	font-size: 12px;
}
#opc-login input {
	color: #626262;
	font-family: 'Poppins', sans-serif;
	font-size: 13px;
	vertical-align: middle;
}
#opc-login .col2-set .col-2 p {
	text-align: left;
}
#opc-login h4 {
	clear: left;
	float: none;
	font-size: 13px;
	margin-right: 5px;
}
#opc-login li.control label {
}
.form-list li.control input.radio, .form-list li.control input.checkbox {
	margin: 0 8px 0 0;
}
input.radio {
	display: inline-block;
	margin: 0 5px 0 0;
	vertical-align: middle;
}
.form-list {
	list-style: none outside none;
	margin: 0;
	padding: 0;
}
form-list label.required em {
	font-style: normal;
}
.required em {
	color: #ff0000;
}
.form-list label {
	color: #333;
	font-weight: normal;
}
.label {
	color: #333;
	display: inline-block;
	font-size: 12px;
	text-align: left;
	white-space: normal;
}
#product-options-wrapper .options-list .price {
	font-size: 12px;
}
.product-options-bottom .sharing-links {
	display: none;
}
.messages li {
	margin: 5px 0 10px !important;
}
.success-msg {
	background-color: #eff5ea;
	border-color: #446423;
	color: #3d6611;
}
.error-msg, .success-msg, .note-msg, .notice-msg {
	background-position: 10px 9px !important;
	background-repeat: no-repeat !important;
	border-style: solid !important;
	border-width: 1px !important;
	font-size: 11px !important;
	font-weight: 900 !important;
	padding: 8px !important;
}
.one-page-checkout h3 {
	font-size: 16px;
	margin: 0;
}
#checkout-review-submit ol.checkout-agreements {
	list-style: none outside none;
}
#checkout-review-submit .checkout-agreements {
	margin-bottom: 10px !important;
}
#checkout-review-submit .checkout-agreements {
	margin-bottom: 10px !important;
}
.checkout-agreements {
	border: 1px solid #eaeaea;
	margin-top: 20px;
	padding: 10px;
}
#review-buttons-container .btn-checkout {
	float: right;
	margin-top: 10px;
}
.multiple-checkout h3 {
	font-size: 14px;
	margin: auto;
}
.error-msg {
	background-color: #faebe7;
	border-color: #f16048;
	color: #df280a;
}
/* 30. Compare Table*/
.table.compare-table {
	background-color: #fff;
}
.compare-products .table>tbody+tbody {
	border: 1px solid #e5e5e5;
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
	padding: 8px;
	line-height: 1.42857143;
	vertical-align: top;
	border: 1px solid #e5e5e5;
}
.compare-table .price-box {
	padding-bottom 	: 15px;
}
.compare-table .product-shop-row td {
	position: relative;
	text-align: center;
}
.compare-table a.btn-cancel {
	position: absolute;
	right: 0;
	top: 5px;
	color: red;
}
.compare-table .product-name {
	color: #000;
	font-size: 14px;
	overflow: hidden;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding: 5px 0 10px;
}
.compare-table .product-name a {
	color: #000;
}
a.button.wishlist {
	text-transform: uppercase;
	font-size: 11px;
	margin-bottom: 15px;
	display: inline-block;
	border: 1px #ccc solid;
}
.simple-list li a {
	color: #333;
}
.styled-list {
	position: relative;
	margin: 0 0 20px;
	padding: 0;
	list-style: none;
	line-height: 18px
}
.styled-list li {
	margin-bottom: 6px;
	padding: 0
}
.styled-list li span {
	display: block;
	overflow: hidden
}
.styled-list li i {
	width: 16px;
	float: left;
	margin-right: 10px;
	text-align: center;
	color: #000;
	font-size: 14px
}
.styled-list li:before {
	color: #ee3b27;
	float: left
}
.styled-list .circle li, .styled-list.circle li {
	text-indent: 0;
	margin-left: 0;
	padding-left: 0;
	list-style: none
}
.styled-list .circle li:before, .styled-list.circle li:before {
	content: "\25CF";
	margin: -1px 10px 0 0;
	font-size: 12px
}
.styled-list.arrow>li {
	margin-left: -15px;
	padding-left: 35px
}
.styled-list.arrow> li:before {
	margin: -1px 8px 0 -20px;
	content: '\f00c';
	font-family: FontAwesome;
	font-size: 1em
}
.styled-list ul {
	margin-left: 0;
	padding: 5px 0 0
}
.styled-list ul.circle li:before {
	content: "\25CB";
	color: #ee3b27
}
.grey-container {
	background: #f2f2f2;
	padding-top: 35px;
	padding-bottom: 35px;
	color: #000
}
/* 31. Multiple Addresses Checkout */

/* State Bar */
.state_bar {
	overflow: hidden;
	background-color: #FFFFFF;
	padding: 10px 0 25px;
	margin-bottom: 15px;
}
.checkout-progress {
	padding-top: 0px;
	margin: 0px 0 0px;
	overflow: hidden;
	padding: 0;
	list-style: none;
}
.checkout-progress li {
	width: 19.9%;
	text-align: center;
	color: #999;
	margin-right: 0px;
	padding: 5px 7px;
	text-transform: uppercase;
	background-color: #fff;
	border-bottom: 1px solid #ddd;
	text-transform: uppercase;
	font-size: 12px;
	display: inline-block;
	margin-right: -3px;
	font-weight: 600;
}
.checkout-progress li.last {
	margin-right: 0;
}
.checkout-progress li.active {
	background-color: #fff;
	border-bottom: 2px solid #8ac42f;
	color: #8ac42f;
}
/* Multiple Addresses Checkout */
#multiship-addresses-table input.input-text {
	background-color: #FFF;
	padding: 5px;
	border: 2px solid #ddd;
	text-align: center;
	margin: auto;
	font-weight: 600;
	color: #333;
}
#multiship-addresses-table .btn-remove {
	background-color: #fff;
	background-image: none;
	color: #333;
	cursor: pointer;
	padding: 12px 15px;
	border-radius: 2px;
	cursor: pointer;
	text-decoration: none;
	border: 1px solid #fff;
	float: left;
	transition: all 0.3s linear;
	line-height: 12px;
}
#multiship-addresses-table .btn-remove:before {
	content: "\f014";
	font-family: FontAwesome;
	font-size: 14px;
}
#multiship-addresses-table .btn-remove:hover {
	background-image: none;
	padding: 12px 15px;
	color: #f23534;
}
.multiple_addresses .buttons-set {
	margin: 0px 0;
}
.multiple-checkout {
	position: relative;
}
.multiple-checkout .grand-total {
	font-size: 1.5em;
	text-align: left;
}
.multiple-checkout .grand-total big {
	font-size: 16px;
}
#multiship-addresses-table {
	margin-top: 5px;
	margin-bottom: 12px;
	width: 99.5%;
}
#multiship-addresses-table tfoot tr .last {
	border-top: none;
}
.addresses {
	margin: 15px 0;
}
#multiship-addresses-table h4 {
	font-size: 13px;
	margin: 0;
	line-height: 18px;
}
#multiship-addresses-table h4 a {
	color: #333;
}
.multiple_addresses {
	background: none repeat scroll 0 0 #fff;
	border: 1px solid #e5e5e5;
	margin: 0 0px 20px;
	overflow: hidden;
	padding: 15px 25px;
	position: relative;
	z-index: 0;
}
.multiple_addresses .title-buttons {
	text-align: right;
}
.checkout-multishipping-success .multiple-checkout .buttons-set .button {
	float: left;
}
.multiple-checkout .buttons-set .button {
	float: right;
}
.page-title_multi {
	display: inherit;
}
.addresses .title-buttons {
	text-align: right;
}
.page-title_multi h2 {
	font-size: 20px;
	float: left;
	color: #000;
	margin-top: auto;
	letter-spacing: 1px;
	text-transform: uppercase;
}
#multiship-addresses-table select {
	margin-top: 0px;
	background: #fff;
	border: 1px #ddd solid;
}
.multiple-checkout .grand-total {
	font-size: 1.5em;
	text-align: right;
}
.multiple-checkout .grand-total .price {
	font-size: 22px;
}
/* 32. Account Login  */
.account-login {
	background: none repeat scroll 0 0 #fff;
	border: 1px solid #e5e5e5;
	margin: 15px 0px 20px;
	overflow: hidden;
	padding: 25px 25px;
}
.account-login .page-title {
	border-bottom: 1px solid #e5e5e5;
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 1px;
	margin: -15px -25px 25px;
	padding: 15px 25px 20px;
	position: relative;
	text-transform: uppercase;
}
.account-login .page-title h2 {
	font-weight: 600;
}
.account-login .form-list input.input-text {
	background: #fff;
	border: 1px solid #f0f0f0;
	padding: 10px;
	width: 80%;
	margin-top: 5px;
	outline: none;
	margin-bottom: 10px;
}
.account-login .col2-set .col-1 {
	float: left;
	padding-bottom: 0;
	padding: 0px;
	text-align: left;
	width: 49%;
	min-height: 362px;
	background: #fff;
	border-right: 1px solid #eaeaea;
	padding: 25px 25px 0 0;
	margin-bottom: 15px;
}
.account-login .col2-set .col-2 {
	float: right;
	padding-bottom: 0;
	padding: 0 0 0 55px;
	text-align: left;
	width: 48%;
	background: #fff;
	padding: 25px 0;
	margin-bottom: 25px;
}
.account-login strong {
	font-size: 14px;
	color: #000;
	margin-bottom: 15px;
	font-family: 'Roboto', sans-serif;
	text-transform: uppercase;
	letter-spacing: 1px;
}
.account-login .content {
	margin-top: 8px;
	padding-top: 12px;
}
.account-login .content p {
	margin-bottom: 10px;
	font-size: 13px;
}
.account-login .content p.required {
	font-size: 12px;
}
.account-login .content .form-list label {
	font-size: 13px;
	color: #333;
	margin-bottom: 5px;
}
.required {
	color: #ff0000;
}
/* 33. 404 Page */

.content-wrapper {
	margin: auto;
	text-align: center;
	padding-top: 50px;
	padding-bottom: 80px;
}
.page-not-found h2 {
	color: #da2c2a;
	font-size: 160px;
	line-height: 1;
	margin-bottom: 15px;
	font-family: 'Poppins', sans-serif;
}
.page-not-found h3 {
	color: #333;
	font-size: 20px;
	font-weight: normal;
	margin-bottom: 4em;
	text-transform: none;
	font-family: 'Roboto', sans-serif;
}
.page-not-found h3 img {
	margin-right: 8px;
	vertical-align: text-bottom;
}
.page-not-found p {
	color: #666;
	font-size: 1.33em;
	line-height: 1.5em;
	margin-bottom: 0.75em;
}
.page-not-found .btn-home {
	background-color: #8ac42f;
	border: medium none;
	border-radius: 2px 2px 2px 2px;
	color: #fff;
	cursor: pointer;
	font-size: 15px;
	padding: 14px 35px;
	text-decoration: none;
	height: auto;
	font-weight: 600;
	letter-spacing: 0.5px;
}
.page-not-found .btn-home span {
	margin: 0;
	padding: 0px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	font-family: 'Poppins', sans-serif;
}
/* 34. Delivery page*/


.delivery h3 {
	margin-top: auto;
	font-size: 16px;
	text-transform: uppercase;
}
.grey-container h3 {
	margin-top: auto;
	font-size: 16px;
	text-transform: uppercase;
}
.grey-container .button.more-info {
	margin-top: 10px;
	display: inline-block;
	margin-bottom: 10px;
}
.accordion-faq {
	text-align: left;
	position: relative
}
.accordion-faq .panel {
	border-radius: 0;
	box-shadow: none;
	-webkit-box-shadow: none
}
.accordion-faq .panel .panel-heading {
	padding: 0;
	color: #fff;
	border-radius: 0!important;
	background: #333
}
.accordion-faq .panel-heading a {
	display: block;
	position: relative;
	background: #f8f8f8;
	color: #333;
	padding: 10px 5px 12px 15px;
	border-radius: 0;
	border-right: 48px solid #8ac42f;
	min-height: 50px;
	font-weight: 600;
}
.accordion-faq .panel-heading a:hover {
	text-decoration: none
}
.accordion-faq .panel:last-child {
	box-shadow: none;
	border-bottom: none
}
.accordion-faq .arrow-down, .accordion-faq .arrow-up {
	position: absolute;
	display: block;
	width: 20px;
	height: 20px;
	font-size: 20px;
	top: 50%;
	margin-top: -15px;
	right: -36px;
	color: #fff;
}
.accordion-faq .arrow-down, .accordion-faq .collapsed .arrow-up {
	display: none
}
.accordion-faq .collapsed .arrow-down {
	display: block
}
.accordion-faq .panel-body {
	border-top: 0!important;
	padding: 15px;
	background: #fff;
	border: 1px #eee solid;
	border-top: none;
}
.accordion-faq.panel-group .panel+.panel {
	margin-top: 10px
}
.simple-list {
	margin: 0;
	padding: 0;
	list-style-type: none
}
.bold-list>li>a {
	text-transform: uppercase
}
.simple-list ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
	position: relative;
	bottom: -.8em
}
.simple-list li {
	margin: 0;
	padding: 0 0 12px
}
.simple-list .icon {
	margin-right: 9px
}
.arrow-list li {
	padding-left: 12px;
	position: relative
}
.arrow-list li:before {
	content: "›";
	display: inline;
	font-size: 1.1em;
	position: absolute;
	left: 0;
	top: -1px
}
/* 35. Data Table */
.data-table {
	width: 100%;
}
.data-table th {
	line-height: 20px;
	padding: 6px;
	font-size: 12px;
	font-family: 'Poppins', sans-serif;
}
.data-table td {
	line-height: 20px;
	padding: 10px;
	vertical-align: top;
}
.data-table th .tax-flag {
	white-space: nowrap;
	font-weight: normal;
}
.data-table td.label, .data-table th.label {
	font-weight: normal;
	background: #f8f8f8
}
.data-table input, data-table select, data-table textarea {
	margin: 3px;
}
.data-table p {
	margin: 10px 0;
}
.data-table .description {
	margin: 5px 0;
}
.data-table thead th, .data-table thead td, .data-table tfoot th, .data-table tfoot td, .cart .totals table th, .cart .totals table td {
	color: #333;
	border: 1px solid #eaeaea;
	font-family: 'Poppins', sans-serif;
}
.data-table tbody th, .data-table tbody td {
	border: 1px solid #eaeaea;
	padding: 10px;
}
.cart-table tbody th, .cart-table tbody td {
	border: 1px solid #eaeaea;
	vertical-align: middle;
}
.cart-table tbody th h2, .cart-table tbody td h2 {
	margin: auto;
	padding: 0px;
	display: inline-block;
}
/*---------- 36. Footer ------------ */	
.footer-top {
	padding-top: 30px;
	background-color: #881f66;
	margin-bottom: 30px;
	border-top: 1px solid #ccc;
}
footer .footer-top a {
	color: #ffffff;
}
footer {
	padding: 0px 0 0;
	overflow: hidden;
	color: #ffffff;
	padding-top: 0px;
	background-color: #111;
}
footer ul {
	margin: 0px;
	padding: 0px;
}
footer ul li {
	list-style-type: none;
}
footer a {
	padding-top: 7px;
	padding-bottom: 7px;
	font-size: 13px
}
.com-add {
	border-bottom: 1px solid #f7f7f7;
	margin-bottom: 10px;
	padding-bottom: 6px;
}
footer .footer-inner {
	margin: auto;
	overflow: hidden;
	margin-bottom: 30px;
}
footer .footer-inner h3 {
	color: #3D4C4F;
	font-size: 15px;
	text-transform: uppercase;
	margin: 0 0 15px;
	padding: 0 0 10px;
}
footer .footer-links a:before {
	content: "\f105";
	font-family: FontAwesome;
	font-size: 11px;
	display: inline-block!important;
	cursor: pointer;
	line-height: 20px;
	color: #aaa;
	margin-right: 5px;
}
footer p {
	font-size: 14px;
	color: #aaa;
	padding-top: 0px;
	padding-bottom: 5px;
}
footer .footer-links a {
	display: inline-block;
	color: #717171
}
footer a {
	line-height: normal;
}
footer a:hover {
	color: #fdae03;
}
footer p {
	line-height: 20px;
}
footer a {
	color: #aaa
}
footer h5 {
	padding: 0 0 10px;
	font-size: 14px;
	margin: 0;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 1px
}
footer .input-text:focus {
	background: #fff;
	border-color: #464646;
}
footer a:hover {
	text-decoration: none;
}
footer .coppyright {
	color: #666;
	float: left;
	margin-top: 4px;
}
.footer-bottom {
	padding: 0px;
	overflow: hidden;
	width: 100%;
	margin: auto;
	padding: 15px 0 12px;
	background-color: #060606;
}
.footer-bottom a {
	color: #999;
}
.footer-bottom .company-links ul {
	padding: 0px;
}
.footer-bottom .company-links li {
	display: inline-block;
	margin-left: 20px;
	list-style: none;
	float: right;
	border-bottom: none
}
.add-icon:before {
	content: "\f041";
	font-family: FontAwesome;
	font-size: 15px;
	color: #fff;
	height: 35px;
	width: 35px;
	line-height: 35px;
	display: inline-block;
	float: left;
	font-style: normal;
	text-align: center;
	margin-right: 10px;
	border-radius: 30px;
	border: 2px #aaa solid;
	border-radius: 25px
}
.email-footer {
	overflow: hidden;
	margin-top: 12px;
	font-size: 14px;
}
.email-footer a {
	font-size: 14px;
	line-height: 35px;
	color: #aaa
}
.phone-footer {
	overflow: hidden;
	font-size: 14px;
	line-height: 35px;
	color: #aaa;
	margin-bottom: 10px;
	margin-top: 12px;
}
footer address {
	display: block;
	margin: auto;
	font-style: normal;
	line-height: 35px;
	color: #777;
	padding-top: 5px;
	margin-top: 20px;
	text-align: center;
	margin-bottom: 20px;
	font-size: 13px;
	border: none;
	letter-spacing: 0.5px
}
footer address p {
	display: inline-block;
	padding: 0px;
	color: #666;
}
footer address i {
	border: 1px #888 solid;
	width: 35px!important;
	line-height: 33px!important;
	display: inline-block!important;
	height: 35px!important;
	text-align: center;
	margin-left: 20px!important;
	border-radius: 2px;
	margin-right: 5px!important;
}
footer address .fa {
	font-size: 16px
}
footer address .fa-envelope {
	font-size: 14px
}
footer address .fa-mobile {
	font-size: 19px
}
footer address .icon-location-arrow:before {
	margin-left: 0px;
	margin-right: 0px;
	color: #333
}
footer address .icon-mobile-phone:before {
	margin-left: 0px;
	margin-right: 0px;
	color: #777
}
footer address .icon-envelope:before {
	margin-left: 0px;
	margin-right: 0px;
	color: #777
}
.email-icon:before {
	content: "\f0e0";
	font-family: FontAwesome;
	font-size: 14px;
	color: #777;
	height: 35px;
	width: 35px;
	margin-right: 5px;
	line-height: 35px;
	display: inline-block;
	float: left;
	font-style: normal;
	text-align: center;
	margin-right: 10px;
	border: 2px #aaa solid;
	border-radius: 25px
}
.phone-icon:before {
	content: "\f095";
	font-family: FontAwesome;
	font-size: 15px;
	color: #777;
	height: 35px;
	width: 35px;
	margin-right: 5px;
	line-height: 35px;
	display: inline-block;
	float: left;
	font-style: normal;
	text-align: center;
	margin-right: 10px;
	border: 2px #aaa solid;
	border-radius: 25px
}
.footer-bottom .bottom_links li {
	margin-left: 20px
}
.custom-footer-links li {
	margin: 7px 0
}
.footer-col-right {
	width: 58%;
	float: left
}
.contact-info {
	text-align: center;
}
ul.contact-info span {
	font-size: 13px !important;
	font-weight: 900 !important;
	color: #666;
	float: left
}
ul.contact-info li {
	margin-bottom: 8px;
	padding-bottom: 8px;
	display: inline-block;
}
ul.contact-info li.last {
	float: left;
	margin-bottom: 0px;
	border-bottom: 0px #2D3D4C solid
}
#container_newsletter {
	width: 100%;
	padding: 7px 0 7px 0;
	background: #f7f7f7;
	margin: 36px 0 22px 0
}
#text_container_news {
	width: 205px;
	height: 34px;
	float: left;
	margin-left: 30px;
	text-transform: uppercase;
	font-family: arial;
	font-size: 10px;
	color: #647177;
	padding-top: 7px
}
#text_container_news span {
	color: #959a9c;
	font-size: 9px
}
#container_form_news {
	position: relative;
	z-index: 0
}
#container_form_news2 input[type="submit"] {
	cursor: pointer;
	border: 0;
	background: #FFFFFF;
	width: 20px;
	height: 12px;
	display: block;
	position: relative;
	color: #a7a7a7;
	font-size: 10px;
	line-height: 20px;
	top: -18px;
	right: -160px
}
#container_form_news .validation-advice {
	top: 42px;
	left: 10px;
}
.footer-box {
	width: 1240px;
	margin: auto;
}
footer ul span {
	display: inline-block;
	font-size: 20px;
	font-weight: 300;
	line-height: 15px;
	padding-right: 3px;
}
address {
	display: block;
	margin: auto;
	font-style: normal;
	line-height: 21px;
	color: #999;
	padding-top: 10px;
	margin-top: 10px;
	text-align: left;
	padding-bottom: 15px;
	border-bottom: 1px #eaeaea solid;
	margin-bottom: 15px
}
.footer-logo {
	text-align: left;
	margin: 10px 0;
}
/* newsletter */
.newsletter-inner {
	display: inline-block;
}
.footer-newsletter form {
	display: inline-block;
	margin-top: 10px;
}
.footer-newsletter .newsletter-email {
	float: left;
	font-size: 12px;
	font-weight: 300;
	border: 0;
	background: #fff;
	color: #666;
	padding: 10px 12px;
	width: 230px;
	border-radius: 3px 0px 0px 3px;
}
.footer-newsletter .email {
	text-transform: none;
	float: left
}
button.subscribe:before {
	content: "\f0e0";
	font-family: FontAwesome;
	font-size: 12px;
	padding-right: 8px
}
.footer-newsletter .subscribe {
	text-transform: capitalize;
	font-size: 12px;
	font-weight: 600;
	float: right;
	color: #fff;
	text-decoration: none;
	background: #fdae03;
	padding: 8px 15px 8px 18px;
	border: 1px #fdae03 solid;
	border-radius: 0px 3px 3px 0px;
	text-transform: uppercase;
}
.footer-newsletter .subscribe:hover {
	border: 1px #000 solid;
	background: #000;
	padding: 8px 15px 8px 18px;
}
.social-section {
	padding: 0px;
	overflow: hidden;
	width: 100%;
	margin: auto;
	background: #191919
}
.social-section .inner {
	margin: auto;
	overflow: hidden;
	margin-bottom: 20px;
	padding-top: 20px;
}
.social {
	overflow: hidden
}
.social h5 {
	margin: 28px 0 0px 0px;
}
.social ul.inline-mode li a:before {
	content: "";
	margin-right: 0px;
}
.social ul.inline-mode li {
	display: inline-block;
	margin-right: 6px;
}
.social ul.inline-mode li a {
	border: 0px solid #666;
	color: #fff;
	font-size: 15px;
	height: 38px;
	width: 38px;
	display: inline-block;
	text-align: center;
	border-radius: 3px;
}
.social .fb a:hover, .social .tw a:hover, .social .googleplus a:hover, .social .rss a:hover, .social .instagram a:hover, .social .linkedin a:hover {
	background: #00b9f5
}
.social .fb a {
	background: #3C5B9B
}
.social .tw a {
	background: #359BED
}
.social .googleplus a {
	background: #E33729
}
.social .rss a {
	background: #FD9F13
}
.social .instagram a {
	background: #3f729b
}
.social .linkedin a {
	background: #027ba5
}
.payment-accept {
	color: #333333;
	font-size: 14px;
	margin: auto;
	overflow: hidden;
}
.payment-accept ul {
	padding: 0;
	margin: auto;
	text-align: right;
}
.payment-accept li {
	display: inline-block;
	margin-left: 5px;
}
.payment-accept li a img {
	width: 40px;
}
.payment-accept img {
	opacity: 0.7
}
.payment-accept img:hover {
	opacity: 1
}
.glyphicon-remove1:before {
	content: "\e014"
}
.glyphicon-remove1 {
	right: 8px;
}
.mm-toggle-wrap {
	display: none;
}
.product-shop .social {
	margin-top: 20px;
}
.product-shop .social ul {
	padding: 0px;
	margin: 0px
}
.product-shop .social ul li {
	display: inline-block;
	margin: 0 7px 0 0;
}
.product-shop .social ul li a {
	padding-top: 7px;
	padding-bottom: 7px;
}
.sendfriend-product-send .login_page .form-list .field {
	float: left;
	width: 47%;
	margin-right: 10px;
}
.sendfriend-product-send .login_page textarea {
	width: 95%;
}
.product-options-bottom .sharing-links {
	display: none;
}
.add-to-box .add-to-cart, .product-options-bottom .add-to-cart {
	margin: 0;
}
.product-view .product-shop .grouped-items-table .price {
	font-size: 18px;
}
#product-options-wrapper .options-list .price {
	font-size: 12px;
}
.rhs4-banner-block {
	display: none;
}
.rhs4-block {
	display: none;
}
.noitem {
	padding: 10px;
	text-align: center;
	font-size: 11px;
}
.noitem:before {
	content: '\f07a';
	font-family: FontAwesome;
	font-size: 36px;
	color: #ccc;
	width: 100%;
	display: block;
}
.validation-advice {
	color: #FF0000;
	position: absolute;
}
.form-list .validation-advice {
	color: #FF0000;
	position: relative;
}
.mgkosc-login-input-box .validation-advice {
	color: #FF0000;
	position: relative;
}
.footer-top .newsletter .validation-advice {
	left: 106px;
}
#offer-banner {
	margin-bottom: 0px;
	margin-top: 15px;
}
