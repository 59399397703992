/*
 Cloud Zoom CSS. (c)2012- 2017 Star Plugins.
*/

/* CSS for lens */
.cloudzoom-lens {
	border: 2px solid #888;
	background-color: #fff;
	width: 100px;
	height: 100px;
	box-shadow: 0 0 10px rgba(0, 0, 0, .4);
	cursor: crosshair;
	z-index: 10
}
/* CSS for zoom window. */
.cloudzoom-zoom {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background-color: #fff;
	-webkit-box-shadow: 0 0 4px rgba(0, 0, 0, .27);
	-moz-box-shadow: 0 0 4px rgba(0, 0, 0, .27);
	box-shadow: 0 0 4px rgba(0, 0, 0, .27);
	overflow: hidden;
	margin: 0 0 0 15px;
	padding: 0;
	border: 5px solid #fff;
	border-bottom: 5px solid #fff;
	z-index: 10000!important
}
/* CSS for zoom window in 'inside' mode. */
.cloudzoom-zoom-inside {
	border: none;
	box-shadow: none
}
/* CSS for captions */
.cloudzoom-caption {
	display: none;
	text-align: left;
	background-color: #000;
	color: #fff;
	font-weight: 700;
	padding: 10px;
	font-family: sans-serif;
	font-size: 11px
}
/* A blank image */
.cloudzoom-blank {
	background-image: url(/src/assets/images/blank.png);
}
/* The animated ajax loading image */
.cloudzoom-ajax-loader {
	background-image: url(/src/assets/images/ajax-loader.gif);
	width: 32px;
	height: 32px;
}
@media (max-width: 767px) {
.cloudzoom-lens, .cloudzoom-zoom {
	display: none !important;
}
.cloudzoom-blank, .cloudzoom-blank > div:first-child {
	background: transparent !important;
}
}
