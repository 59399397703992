/*-----------------------------------------------------------------------------

	-	Revolution Slider 
-----------------------------------------------------------------------------*//*************************
	-	CAPTIONS	-
**************************/

.jtv-slideshow{ margin-top:22px;}
.rev_slider_wrapper >ul {
	visibility: hidden;
}
.rev_slider {
	overflow: visible;
}
.rev_slider img {
	max-width: none !important;
	margin: 0px;
	padding: 0px;
	border-width: 0px;
	border: none;
	visibility: visible !important;
}
.rev_slider .no-slides-text {
	font-weight: bold;
	text-align: center;
	padding-top: 80px;
}
.rev_slider ul {
	margin: 0px;
	padding: 0px;
	list-style: none !important;
	list-style-type: none;
	background-position: 0px 0px;
}
.rev_slider ul li, .rev_slider >ul >li, .rev_slider >ul >li:before {
	list-style: none !important;
	position: absolute;
	visibility: hidden;
	margin: 0px !important;
	padding: 0px !important;
	overflow-x: visible;
	overflow-y: visible;
	list-style-type: none !important;
	background-image: none;
	background-position: 0px 0px;
	text-indent: 0em;
}
.rev_slider .tp-caption, .rev_slider .caption {
	visibility: hidden;
}
.tp-simpleresponsive img {
	max-width: none;
}
.tp-caption {
	z-index: 1;
}
.tp_inner_padding {
	box-sizing: border-box;
	max-height: none !important;
}
.tp-hide-revslider, .tp-caption.tp-hidden-caption {
	visibility: hidden !important;
	display: none !important;
}
.fullscreen-container {
	width: 100%;
	position: relative;
	padding: 0;
	margin: auto;
}
.fullwidthbanner-container {
	width: 100%;
	position: relative;
	padding: 0;
	overflow: hidden;
	margin: auto;
}
.fullwidthbanner-container .fullwidthabanner {
	width: 100%;
	margin: auto;
}
.tp-simpleresponsive .slotholder *, .tp-simpleresponsive img {
}
.tp-simpleresponsive .caption, .tp-simpleresponsive .tp-caption {
	text-align: center;
	margin: auto;
}
#jtv-rev_slider_wrapper .info {
	width: 100%;
	display: block;
	text-align: left;
	position: absolute;
	top: 25%;
	left: 7%
}
#jtv-rev_slider_wrapper .info1 {
	width: 100%;
	display: block;
	text-align: left;
	position: absolute;
	top: 25%;
	left: 7%;
}
#jtv-rev_slider_wrapper .info1 .tp-caption.Extrajtv-large-title {
	color: #fff!important
}
#jtv-rev_slider_wrapper .info1 .tp-caption.jtv-sub-title span {
	border-bottom: #fff double!important;
	border-top: #fff double!important;
}
#jtv-rev_slider_wrapper .info1 .tp-caption.jtv-large-title {
	color: #fff!important
}
#jtv-rev_slider_wrapper .info1 .tp-caption.Title {
	color: #fff!important
}
#jtv-rev_slider_wrapper .info1 .jtv-shop-now-btn {
	color: #fff;
	border: 1px #fdae03 solid;
	background: #fdae03; border-radius:3px;
}
#jtv-rev_slider_wrapper .info1 .jtv-shop-now-btn:hover {
	background: #fff;
	color: #333;
}
#jtv-rev_slider_wrapper .info1 .jtv-shop-now-btn:after {
	color: #fff;
}
#jtv-rev_slider_wrapper .info1 .jtv-shop-now-btn:hover:after {
	color: #333;
}
.tp-caption.jtv-sub-title {
	line-height: 60px;
	font-weight: normal;
	color: #FFF;
	text-decoration: none;
	background-color: transparent;
	padding: 0px 0px 0px 0px;
	border-radius: 0px 0px 0px 0px;
	font-size: 18px;
	text-align: left;
	letter-spacing: 0px;
}
.tp-caption.slide2 {
	color: #FFF;
}
.tp-caption.jtv-sub-title span {
	display: inline-block;
	margin: auto;
	border-bottom: #EEE double;
	line-height: 40px;
	text-transform: uppercase;
	letter-spacing: 5px;
	font-size: 12px
}
.tp-caption.jtv-large-title {
	font-weight: 900;
	color: #FFF;
	text-decoration: none;
	background-color: transparent;
	padding: 10px 0px 10px 0px;
	font-size: 60px;
	text-align: left;
	letter-spacing: normal;
	text-transform: uppercase;
	font-family: 'Raleway', sans-serif;
	line-height: 60px;
}
.tp-caption.jtv-large-title span {
	color: #EEE;
	line-height: 65px;
	padding-left: 0px;
	padding-right: 20px;
	border-radius: 5px;
	text-align: left;
	display: inline-block;
}
.view-more {
	padding: 10px 35px;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: bold;
	color: #EEE;
	border: 1px #EEE solid;
}
.jtv-shop-now-btn {
	padding: 8px 20px;
	font-size: 13px; font-weight:600;
	text-transform: uppercase;
	color: #fff;
	letter-spacing: 1.5px;
	display: inline-block;
	border: 1px #44a23d solid;
	margin-top: 45px;
	float: left; background-color:#44a23d;border-radius:3px;
}
.jtv-shop-now-btn:hover {
	background: #fdae03;
	color: #fff;border: 1px #fdae03 solid;
}
.jtv-shop-now-btn:hover:after {
	color: #fff;
}
.jtv-shop-now-btn:after {
	content: '\f0da';
	font-family: FontAwesome;
	display: inline-block !important;
	margin-left: 10px;
	color: #EEE;
	font-size: 14px;
}
.tp-caption.Title {
	font-size: 13px;
	line-height: 20px;
	font-weight: normal;
	text-transform: none;
	color: #EEE;
	text-decoration: none;
	background-color: transparent;
	padding: 0px 0px 0px 0px;
	text-align: left;
	margin: auto
}
.small-text {
	color: #fff!important;
	letter-spacing: 1px
}
.black-text .tp-caption.Title {
	color: #fff;
}
.black-text .tp-caption.jtv-large-title {
	color: #fff;
}
.black-text .tp-caption.jtv-sub-title {
	color: #fff;
}

.tp-button.green, .tp-button:hover.green, .purchase.green, .purchase:hover.green {
	background-color: #21a117;
	box-shadow: 0px 3px 0px 0px #104d0b;
}
.tp-button.blue, .tp-button:hover.blue, .purchase.blue, .purchase:hover.blue {
	background-color: #1d78cb;
	box-shadow: 0px 3px 0px 0px #0f3e68;
}
.tp-button.red, .tp-button:hover.red, .purchase.red, .purchase:hover.red {
	background-color: #cb1d1d;
	box-shadow: 0px 3px 0px 0px #7c1212;
}
.tp-button.orange, .tp-button:hover.orange, .purchase.orange, .purchase:hover.orange {
	background-color: #ff7700;
	box-shadow: 0px 3px 0px 0px #a34c00;
}
.tp-button.darkgrey, .tp-button.grey, .tp-button:hover.darkgrey, .tp-button:hover.grey, .purchase.darkgrey, .purchase:hover.darkgrey {
	background-color: #555;
	box-shadow: 0px 3px 0px 0px #222;
}
.tp-button.lightgrey, .tp-button:hover.lightgrey, .purchase.lightgrey, .purchase:hover.lightgrey {
	background-color: #888;
	box-shadow: 0px 3px 0px 0px #555;
}
/************************
	-	NAVIGATION	-
*************************/

/** BULLETS **/

.tpclear {
	clear: both;
}
.tp-bullets {
	z-index: 1000;
	position: absolute;
	opacity: 1;
}
.tp-bullets.hidebullets {
	opacity: 0;
}
.tp-bullets.simplebullets.round .bullet.last {
	margin-right: 3px;
}
.tp-bullets.simplebullets.round-old .bullet.last {
	margin-right: 0px;
}
/**	SQUARE BULLETS **/

.tp-bullets.simplebullets.square .bullet.last {
	margin-right: 0px;
}
/**	SQUARE BULLETS **/

.tp-bullets.simplebullets.square-old .bullet.last {
	margin-right: 0px;
}
/** navbar NAVIGATION VERSION **/

.tp-bullets.simplebullets.navbar .bullet.first {
	margin-left: 0px !important;
}
.tp-bullets.simplebullets.navbar .bullet.last {
	margin-right: 0px !important;
}
/** navbar NAVIGATION VERSION **/

.tp-bullets.simplebullets.navbar-old .bullet.first {
	margin-left: 0px !important;
}
.tp-bullets.simplebullets.navbar-old .bullet.last {
	margin-right: 0px !important;
}
.tp-bullets.simplebullets .bullet:hover, .tp-bullets.simplebullets .bullet.selected {
	background-position: top left;
}
.tp-bullets.simplebullets.round .bullet:hover, .tp-bullets.simplebullets.round .bullet.selected, .tp-bullets.simplebullets.navbar .bullet:hover, .tp-bullets.simplebullets.navbar .bullet.selected {
	background-position: bottom left;
}
/*************************************
	-	TP ARROWS 	-
**************************************/
.tparrows {
	opacity: 1;
}
.tparrows.hidearrows {
/*-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-moz-opacity: 0;
	-khtml-opacity: 0;
	opacity: 0;*/
}
.tp-leftarrow {
	z-index: 100;
	cursor: pointer;
	position: relative;
	background: url(../images/left-a.png) no-repeat top left;
	width: 40px;
	height: 40px;
}
.tp-rightarrow {
	z-index: 100;
	cursor: pointer;
	position: relative;
	background: url(../images/right-a.png) no-repeat top left;
	width: 40px;
	height: 40px;
}
.tp-leftarrow.navbar-old.thumbswitharrow {
	margin-right: 10px;
}
.tp-rightarrow.navbar-old.thumbswitharrow {
	margin-left: 0px;
}
.tp-leftarrow.default {
	z-index: 100;
	cursor: pointer;
	position: relative;
	background: #666 url(../images/left-a.png) no-repeat 13px 8px;
	width: 40px;
	height: 45px;
	left: 0px !important;
	opacity: 0;
	background-size: 10px
}
.tp-rightarrow.default {
	z-index: 100;
	cursor: pointer;
	position: relative;
	background: #666 url(../images/right-a.png) no-repeat 13px 8px;
	width: 40px;
	height: 45px;
	right: 0 !important;
	opacity: 0;
	background-size: 10px
}
.tp-leftarrow.default:hover{background: #fdae03 url(../images/left-a.png) no-repeat 13px 8px;background-size: 10px}
.tp-rightarrow.default:hover{background: #fdae03 url(../images/right-a.png) no-repeat 13px 8px;background-size: 10px}
#jtv-rev_slider_wrapper:hover .tp-rightarrow.default {
	opacity: 1
}
#jtv-rev_slider_wrapper:hover .tp-leftarrow.default {
	opacity: 1
}
.tp-bullets.tp-thumbs {
	/*z-index: 1000; */
	position: absolute;
	padding: 3px;
	background-color: #fff;
	width: 500px;
	height: 50px; 			/* THE DIMENSIONS OF THE THUMB CONTAINER */
	margin-top: -50px;
	vertical-align: top;
	display: none;
}
.tp-bullets.tp-thumbs img {
	vertical-align: top;
}
.fullwidthbanner-container .tp-thumbs {
	padding: 3px;
	margin: auto -160px !important;
}
.tp-bullets.tp-thumbs .tp-mask {
	width: 500px;
	height: 50px;  			/* THE DIMENSIONS OF THE THUMB CONTAINER */
	overflow: hidden;
	position: relative;
}
.tp-bullets.tp-thumbs .tp-mask .tp-thumbcontainer {
	width: 500px;
	position: absolute;
}
.tp-bullets.tp-thumbs .bullet {
	width: 100px;
	height: 50px; 			/* THE DIMENSION OF A SINGLE THUMB */
	cursor: pointer;
	overflow: hidden;
	background: none;
	margin: 0;
	float: left;
}
.tp-bullets.tp-thumbs .bullet:hover, .tp-bullets.tp-thumbs .bullet.selected {
	opacity: 1;
}
.tp-thumbs img {
	width: 100%;
}
/************************************
		-	TP BANNER TIMER		-
*************************************/

.tp-bannertimer.tp-bottom {
	top: auto;
	bottom: 0px !important;
	height: 5px;
}
/******************************
	-	HTML5 VIDEO SETTINGS	-
********************************/

.vjs-tech {
	margin-top: 1px
}
.tp-caption .vjs-default-skin .vjs-big-play-button {
	left: 50%;
	top: 50%;
	margin-left: -20px;
	margin-top: -20px;
	width: 40px;
	height: 40px;
	border-radius: 5px;
	border: none;
	box-shadow: none;
	text-shadow: none;
	line-height: 30px;
	vertical-align: top;
	padding: 0px;
}
.tp-caption .vjs-default-skin .vjs-big-play-button:before {
	position: absolute;
	top: 0px;
	left: 0px;
	line-height: 40px;
	text-shadow: none !important;
	color: #fff;
	font-size: 18px;
}
.tp-caption .vjs-default-skin:hover .vjs-big-play-button, .vjs-default-skin .vjs-big-play-button:focus {
	box-shadow: none;
}
.tp-simpleresponsive img {
}
.tp-caption img {
	background: transparent;
}
.tp-simpleresponsive ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.tp-simpleresponsive >ul li {
	list-style: none;
	position: absolute;
	visibility: hidden;
}
/*  CAPTION SLIDELINK   **/
.tp-leftarrow.default, .tp-rightarrow.default {
	font-family: FontAwesome;
	font-size: 11px;
	font-weight: normal;
	height: 45px;
	width: 40px;
	text-align: center;
	line-height: 44px;
}
.forcefullwidth_wrapper_tp_banner, .fullwidthbanner-container {
	max-width: 100%;
	left: 1px !important;
	margin: auto !important;
}
#jtv-slideshow .col-lg-8 {
	padding: 0px;
}
#jtv-slideshow .col-lg-4 {
	padding: 0px;
}
 @media only screen and (min-width: 0px) and (max-width: 479px) {
.tp-button {
	padding: 2px 5px 2px;
	line-height: 20px !important;
	font-size: 10px !important;
}

.tp-leftarrow.default, .tp-rightarrow.default {
	background-size: 6px;
}
.tp-leftarrow.default:hover{background: #fdae03 url(../images/left-a.png) no-repeat 13px 8px;background-size: 8px}
.tp-rightarrow.default:hover{background: #fdae03 url(../images/right-a.png) no-repeat 13px 8px;background-size: 8px}
.tp-caption.Title{ font-size:8px !important; margin-top:8px !important;} 
.tp-caption.jtv-sub-title span{ font-size:8px !important;}
.tp-caption.jtv-large-title,
.tp-caption.jtv-large-title span{ font-size:20px !important;}
.jtv-slideshow {
    margin-top: 15px; margin-bottom:15px;
}
.jtv-shop-now-btn{font-size:10px !important; padding:10px 12px !important;}
}
 @media only screen and (min-width: 480px) and (max-width: 767px) {
.tp-button {
	padding: 2px 5px 2px;
	line-height: 20px !important;
	font-size: 12px !important;
}

.tp-leftarrow.default, .tp-rightarrow.default {
	background-size: 8px;height: 42px;
}
.tp-leftarrow.default:hover{background: #fdae03 url(../images/left-a.png) no-repeat 13px 8px;background-size: 8px}
.tp-rightarrow.default:hover{background: #fdae03 url(../images/right-a.png) no-repeat 13px 8px;background-size: 8px}
.tp-caption.Title{ font-size:10px !important; margin-top:8px !important;} 
.tp-caption.jtv-sub-title span{ font-size:10px !important;}
.tp-caption.jtv-large-title,
.tp-caption.jtv-large-title span{ font-size:28px !important;}
.jtv-slideshow {
    margin-top: 15px;
}
.jtv-shop-now-btn{font-size:12px !important; padding:12px 12px !important;}
.forcefullwidth_wrapper_tp_banner, .fullwidthbanner-container {
	margin-bottom: 15px !important;
}

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
#jtv-slideshow .container {
	width: 100%;
}
.tp-leftarrow.default {
	margin-left: 0px;
}
.tp-rightarrow.default {
	margin-right: 0px;
}
.forcefullwidth_wrapper_tp_banner, .fullwidthbanner-container {
	margin-bottom: 22px !important;
}}
 @media only screen and (min-width: 992px) and (max-width: 1200px) {
#jtv-slideshow .container {
 width: 100%;
}
.tp-leftarrow.default {
 margin-left: 0px;
}
.tp-rightarrow.default {
 margin-right: 0px;
}
}
