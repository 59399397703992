@import url('../src/assets/css/animate.css');
@import url('../src/assets/css/font-awesome.min.css');
@import url('../src/assets/css/simple-line-icons.css');
@import url('../src/assets/css/owl.carousel.css');
@import url('../src/assets/css/owl.transitions.css');
@import url('../src/assets/css/flexslider.css');
@import url('../src/assets/css/cloud-zoom.css');
@import url('../src/assets/css/revolution-slider.css');
@import url('../src/assets/css/jquery-ui.css');
@import url('../src/assets/css/jquery.fancybox.css');
@import url('../src/assets/css/quick_view_popup.css');
@import url('../src/assets/css/blogmate.css');
@import url('../src/assets/css/jtv-mobile-menu.css');
@import url('../src/assets/css/style.css');

.termclass {
  overflow-y: auto;
}

.my-class .mat-dialog-container {
  border-radius: 5px;
  background: rgb(189, 189, 189);
  color: #039be5;
}

.printclss .mat-dialog-container {
  border-radius: 10px;
  overflow: hidden;
  position: relative;


}

html,
body {
  height: 100%;
  font-size: 12px;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.wrapper {

  width: 100%;

  height: 100%;

  position: relative;

}

.my-class .mat-dialog-container {
  border-radius: 5px;
  background: rgb(245, 244, 244);
  color: #039be5;
}

.ms-content-wrapper {
  padding: 30px;
}

.ms-panel {
  background: #fff;
  -webkit-box-shadow: 0 1px 6px 1px rgba(69, 65, 78, 0.1);
  -moz-box-shadow: 0 1px 6px 1px rgba(69, 65, 78, 0.1);
  box-shadow: 0 1px 6px 1px rgba(69, 65, 78, 0.1);
  margin-bottom: 30px;
}

.ms-panel.has-border {
  border: 1px solid rgba(0, 0, 0, .1);
}

.ms-panel.ms-panel-hoverable:hover {
  background-color: #f5f5f5;
}

.ms-panel.bg-dark {
  background-color: #34334a;
}

.ms-panel.ms-panel-fh {
  height: calc(100% - 2rem);
}

.ms-panel-header {
  position: relative;
  padding: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ms-panel-header.header-mini {
  border-bottom: 0;
  padding-bottom: 0;
}

.ms-panel-header p {
  font-size: 13px;
  margin: 0.25rem 0 0 0;
}

.ms-panel-header h6 {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 700;
}

.ms-panel-header .btn {
  margin-top: 0;
}

.new .grid-btn {
  min-width: 0px;
}

.ms-panel-body,
.ms-panel-footer {
  position: relative;
  padding: 1.5rem;
}

.ms-img-small {
  width: 40px;
}

.ms-img-medium {
  width: 65px;
}

.ms-img-large {
  width: 100px;
}

.ms-panel-body .description {
  padding-bottom: 30px;
}

/* Badges */
[class*='badge-'],
a.badge[class*='badge-']:hover {
  color: #fff;
}

.badge.badge-primary {
  background-color: #ff8306;
}

.badge.badge-secondary {
  background-color: #445cc8;
}

.badge.badge-success {
  background-color: #28a745;
}

.badge.badge-warning {
  background-color: #fda600;
}

.badge.badge-danger {
  background-color: #f9423c;
}

.badge.badge-info {
  background-color: #ff0018;
}

.badge.badge-light {
  background-color: #878793;
}

.badge.badge-dark {
  background-color: #34334a;
}

a.badge.badge-primary:hover {
  background-color: #bc2634;
}

a.badge.badge-secondary:hover {
  background-color: #374eae;
}

a.badge.badge-success:hover {
  background-color: #09a561;
}

a.badge.badge-warning:hover {
  background-color: #e89800;
}

a.badge.badge-danger:hover {
  background-color: #db3c36;
}

a.badge.badge-info:hover {
  background-color: #437eb7;
}

a.badge.badge-light:hover {
  background-color: #75757c;
}

a.badge.badge-dark:hover {
  background-color: #262538;
}

.badge[class*='badge-outline-'] {
  background-color: transparent;
  border: 1px solid;
}

button .badge[class*='badge-outline-'],
a .badge[class*='badge-outline-'] {
  background-color: #fff;
}

.badge.badge-outline-primary {
  border-color: #ff8306;
  color: #ff8306;
}

.badge.badge-outline-secondary {
  border-color: #374eae;
  color: #374eae;
}

.badge.badge-outline-success {
  border-color: #07be6e;
  color: #07be6e;
}

.badge.badge-outline-warning {
  border-color: #fda600;
  color: #fda600;
}

.badge.badge-outline-danger {
  border-color: #f9423c;
  color: #f9423c;
}

.badge.badge-outline-info {
  border-color: #4f8dca;
  color: #4f8dca;
}

.badge.badge-outline-light {
  border-color: #878793;
  color: #878793;
}

.badge.badge-outline-dark {
  border-color: #262538;
  color: #262538;
}

.badge-gradient-primary {
  background-color: #ff8306;
  background-image: linear-gradient(to right, #ff8306, #bc2634);
}

.badge-gradient-secondary {
  background-color: #926afe;
  background-image: linear-gradient(to right, #926afe, #bd9aff);
}

.badge-gradient-success {
  background-color: #12b252;
  background-image: linear-gradient(to right, #12b252, #31e97a);
}

.badge-gradient-info {
  background-color: #3e60ff;
  background-image: linear-gradient(to right, #3e60ff, #5ba0fd);
}

.badge-gradient-warning {
  background-color: #fea837;
  background-image: linear-gradient(to right, #fea837, #fed257);
}

.badge-gradient-danger {
  background-color: #eb025d;
  background-image: linear-gradient(to right, #eb025d, #fe4749);
}

.badge-gradient-dark {
  background-color: #252434;
  background-image: linear-gradient(to right, #252434, #3a3850);
}

.badge-gradient-light {
  background-color: #5f5f67;
  background-image: linear-gradient(to right, #5f5f67, #858591);
}

.badge-square {
  border-radius: 0;
}

/* Progress Bars */

.progress {
  margin-bottom: 1.5rem;
  border-radius: 3rem;
  height: 0.9rem;
}

.progress.progress-tiny {
  height: 0.2rem;
}

.progress-status {
  float: right;
}

.progress-label,
.progress-status {
  font-size: 12px;
  display: inline-block;
  margin-bottom: 10px;
}

.progress-rounded {
  position: relative;
  margin: 0 auto;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 16px solid #f1f1f1;
  margin-bottom: 2rem;
}

.progress-rounded * {
  transition: all .3s ease;
}

.progress-rounded .progress-value {
  font-size: 1rem;
  font-weight: 600;
  color: #34334a;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.progress-rounded svg {
  height: 130px;
  width: 130px;
  position: absolute;
  top: -16px;
  left: -16px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}

.progress-rounded svg circle {
  stroke-dasharray: 358.141563 358.141563;
  stroke-dashoffset: 360;
  stroke: #ff8306;
  stroke-linecap: square;
  -webkit-transition: all .6s ease;
  -o-transition: all .6s ease;
  transition: all .6s ease;
}

.progress-rounded.progress-round-tiny {
  width: 120px;
  height: 120px;
  border-width: 4px;
}

.progress-rounded.progress-round-tiny svg {
  top: -9px;
  left: -9px;
}

.progress-rounded svg circle.bg-primary {
  stroke: #ff8306;
}

.progress-rounded svg circle.bg-secondary {
  stroke: #445cc8;
}

.progress-rounded svg circle.bg-success {
  stroke: #5cb85c;
}

.progress-rounded svg circle.bg-info {
  stroke: #5bc0de;
}

.progress-rounded svg circle.bg-warning {
  stroke: #f0ad4e;
}

.progress-rounded svg circle.bg-danger {
  stroke: #d9534f;
}

.progress-rounded svg circle.bg-dark {
  stroke: #34334a;
}

.progress-rounded svg circle.bg-light {
  stroke: #878793;
}

.panel-box {
  padding: 15px;
  background: #fff;
  border: 1px solid #e4e5e7;
  margin-bottom: 20px;
}

.panel-box h4 {
  /** zona de dashboard ej total products sales **/
  font-size: 12px;
  text-transform: uppercase;
  padding-bottom: 10px;
  margin-bottom: 25px;
  padding: 10px 15px;
}

.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 24px;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.breadcrumb>li {
  display: inline-block;
}

.breadcrumb>li+li:before {
  content: "/ ";
  padding: 0 5px;
  color: #ccc;
}

.breadcrumb>.active {
  color: #777777;
}

.card {

  box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);

  border: 1px solid rgba(0, 0, 0, 0);

  margin-bottom: 30px;

}

.card-header {

  background-color: transparent;

  font-size: 16px;

  text-transform: capitalize;

}

.card-group {

  margin-bottom: 30px;

}

.card-group {

  box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, .10);

}

.card-group .card {

  box-shadow: none;

}

.metismenu-card .card-header {

  cursor: pointer;

}

.card-deck .card {

  margin-bottom: 30px;

}